import axios from 'axios'

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,

});

axiosClient.interceptors.request.use(
  function (config) {
    // Decode CSRF token from cookies
    const xsrfCookie = document.cookie
      .split('; ')
      .find(cookie => cookie.startsWith('XSRF-TOKEN'));

    // Extract token value if the cookie is found
    if (xsrfCookie) {
      const decodedCsrfToken = decodeURIComponent(xsrfCookie.split('=')[1]);
      // Add CSRF token to headers
      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      config.headers['X-XSRF-TOKEN'] = decodedCsrfToken;
      
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosClient;