import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
// import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

// Define the type of props for Rtl component
interface RtlProps {
    children: React.ReactNode;
}

// Define Rtl component with FC type
const Rtl = ({ children }: RtlProps) => {
    const cacheRtl = createCache({
        key: 'muirtl',
        // stylisPlugins: [prefixer, rtlPlugin],
        stylisPlugins: [rtlPlugin],
    });

    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
};

export default Rtl; // Export the Rtl component