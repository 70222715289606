import styled from '@emotion/styled'

export const StyledSettings = styled.div`

    display: flex;
    flex-direction: column;
    gap: 15px;
    
    .colors{
        padding: 15px;
        background-color: ${({theme})=> theme.colors.section_color};
        border-radius: 15px;
    }
    
    /* .muirtl-ezc8m5-MuiSlider-root .MuiSlider-rail {
        opacity: 1;
        background: linear-gradient(to right, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 0) 0%, rgba(var(--rgb-r), var(--rgb-g), var(--rgb-b), 1) 100%);
    } */

    /* .muirtl-ezc8m5-MuiSlider-root .MuiSlider-rail {
        margin-top: 500px;
    } */
`