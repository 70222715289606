import styled from '@emotion/styled'

export const StyledDocumentSettings = styled.div`

    display: flex;
    flex-direction: column;
    gap: 25px;

    .title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        
        height: 80px;
        background-color: ${({theme})=> theme.colors.section_color};
        border-radius: 15px;
    }

    .settings{
        padding: 10px;
        min-height: 100px;
        background-color: ${({theme})=> theme.colors.section_color};
        border-radius: 15px;
    }

    .row{
        padding: 10px;
    }

    table{
        border-collapse: separate;
        border-spacing: 10px;
    }
    

`