// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
//import axiosClient from './axios-client'

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// import { useEffect } from 'react';
// import axiosClient from './axios-client';
import PrivateRoutes from './utils/ProtectedRoutes';

import Layout from './layout/layout';
import ErrorPage from './pages/error-page/error-page';
import Home from './pages/home/home';
import Tickets from './pages/tickets/tickets';
import Customers from './pages/tickets/customers/customers';
import Works from './pages/tickets/works/works';
import Items from './pages/tickets/items/items';
import Cars from './pages/tickets/cars/cars';
import Incomes from './pages/tickets/incomes/incomes';
import Outcomes from './pages/tickets/outcomes/outcomes';
import Banks from './pages/tickets/banks/Banks';
import CashBoxes from './pages/tickets/cash-boxes/cash-boxes';
import Login from './pages/login/login';
import Register from './pages/register/register';
import NotFound from './pages/not-found/not-found';
import Documents from './pages/documents/Documents';
import Customer from './pages/tickets/customers/Customer';
import AddTicket from './pages/tickets/AddTicket';
import { useAuth } from './utils/AuthContext';
import GuestRoutes from './utils/GuestRoutes';
import EditCustomer from './pages/tickets/customers/edit-customer/EditCustomer';
import Entries from './pages/tickets/entries/Entries';
import History from './pages/tickets/history/History';
import Item from './pages/tickets/items/Item';
import EditItem from './pages/tickets/items/edit-item/EditItem';
import Income from './pages/tickets/incomes/Income';
import EditIncome from './pages/tickets/incomes/edit-income/EditIncome';
import Outcome from './pages/tickets/outcomes/Outcome';
import EditOutcome from './pages/tickets/outcomes/edit-outcome/EditOutcome';
import Work from './pages/tickets/works/Work';
import Car from './pages/tickets/cars/Car';
import Bank from './pages/tickets/banks/Bank';
import EditBank from './pages/tickets/banks/edit-bank/EditBank';
import EditCashBox from './pages/tickets/cash-boxes/edit-cashBox/EditCashBox';
import EditWork from './pages/tickets/works/edit-work/EditWork';
import EditCar from './pages/tickets/cars/edit-car/EditCar';
import CashBox from './pages/tickets/cash-boxes/CashBox';
import EditSystem from './pages/tickets/systems/edit-system/EditSystem';
import Systems from './pages/tickets/systems/systems';
import System from './pages/tickets/systems/System';
import AddDocument from './pages/documents/add-document/AddDocument';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditDocument from './pages/documents/edit-document/EditDocument';
import Tables from './pages/tables/Tables';
import FieldsStructure from './pages/fields-structure/FieldsStructure';
import DocumentSettings from './pages/documents/document-settings/DocumentSettings';
import Reports from './pages/reports/Reports';
import Settings from './pages/settings/Settings';
import { useEffect, useRef, useState } from 'react';
import { ThemeProvider, Theme } from '@emotion/react';
import { getDesignSettings } from './services/designSettingsService';
import { StyledApp } from './App.styled';
import { createTheme, Theme as MuiTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";


// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Extend the existing Theme interface
declare module '@emotion/react' {
  export interface Theme {
    colors: {
      main_color: string,
      input_text_color: string,
      input_title_text_color: string,
      header_color: string,
      background_color: string,
      section_color: string,
      highlight_color: string,
    };
  }
}





function App() {

  const designSettings = useSelector((state: RootState) => state.settings.designSettings);

  const { user } = useAuth();

  const [themeColors, setThemeColors] = useState<Theme>();
  const [muiTheme, setMuiTheme] = useState<MuiTheme>();

  const abortControllerRef = useRef<AbortController | null>(null);



  useEffect(() => {
    const fetchUserThemeColors = async () => {
      try {


        if (user) {

          if (designSettings) {
            setThemeColors({ colors: designSettings });
          }

          else {
            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();

            const data = await getDesignSettings("id", "0", "ASC", { user_id: user?.id }, abortControllerRef.current.signal);

            if (data.length > 0) {
              setThemeColors({ colors: data[0] });
            }
          }
        }

        else {
          setThemeColors({
            colors: {
              main_color: 'aliceblue',
              input_text_color: 'rgb(255, 255, 255)',
              input_title_text_color: 'rgb(155, 155, 155)',
              header_color: 'rgb(23, 29, 39)',
              background_color: 'rgb(27, 38, 53)',
              section_color: 'rgb(35, 48, 68)',
              highlight_color: 'rgb(64, 122, 214)',
            }
          });
        }

      } catch (error) {
        console.error('Failed to fetch theme colors:', error);
      }
    };

    fetchUserThemeColors();
  }, [user, designSettings]);


  useEffect(()=>{
    setMuiTheme(createTheme({
      palette: {
        primary: {
          main: themeColors?.colors.highlight_color || "rgb(64, 122, 214)",
        },
        // secondary: {
        //   main: "#4782da"
        // },
        background: {
          // main: "#1B2635"
        }


      },
      typography: {
        h1: {
          fontSize: "3rem",
          fontWeight: 600
        },
        h2: {
          fontSize: "1.75rem",
          fontWeight: 600
        },
        h3: {
          fontSize: "1.5rem",
          fontWeight: 600
        },
        //direction: 'rtl',
      },
    }))
  },[themeColors]);

  const router = createBrowserRouter([
    {
      element: <GuestRoutes />,
      children: [
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/register",
          element: <Register />,
        },
      ]
    },
    {
      element: <PrivateRoutes />,
      children: [
        {
          path: "/",
          element: <Layout />,
          errorElement: <ErrorPage />,
          children: [
            {
              path: "/",
              element: <Home />,
            },
            {
              path: "/",
              element: <Tickets />,
              children: [
                {
                  // /customers
                  path: "customers",
                  element: <Customers accountTypeName="לקוח-ספק" />,
                },
                {
                  // /works
                  path: "works",
                  element: <Works accountTypeName="עבודה" />,
                },
                {
                  // /items
                  path: "items",
                  element: <Items accountTypeName="פריט" />,
                },
                {
                  // /cars
                  path: "cars",
                  element: <Cars accountTypeName="רכב" />,
                },
                {
                  // /incomes
                  path: "incomes",
                  element: <Incomes accountTypeName="הכנסה" />,
                },
                {
                  // /outcomes
                  path: "outcomes",
                  element: <Outcomes accountTypeName="הוצאה" />,
                },
                {
                  // /banks
                  path: "banks",
                  element: <Banks accountTypeName="בנק" />,
                },
                {
                  // /cash-boxes
                  path: "cash-boxes",
                  element: <CashBoxes accountTypeName="קופה" />,
                },
                {
                  // /system
                  path: "system",
                  element: <Systems accountTypeName="מערכת" />,
                },
              ],
            },
            {
              // /customers/add
              path: "customers/add",
              element: <AddTicket />,
            },
            {
              // /works/add
              path: "works/add",
              element: <AddTicket />,
            },
            {
              // /items/add
              path: "items/add",
              element: <AddTicket />,
            },
            {
              // /cars/add
              path: "cars/add",
              element: <AddTicket />,
            },
            {
              // /incomes/add
              path: "incomes/add",
              element: <AddTicket />,
            },
            {
              // /outcomes/add
              path: "outcomes/add",
              element: <AddTicket />,
            },
            {
              // /banks/add
              path: "banks/add",
              element: <AddTicket />,
            },
            {
              // /cash-boxes/add
              path: "cash-boxes/add",
              element: <AddTicket />,
            },
            {
              // /system/add
              path: "system/add",
              element: <AddTicket />,
            },
            {
              path: "customers/:id",
              element: <Customer />,
              children: [
                {
                  // /customers/:id
                  path: "",
                  element: <EditCustomer />,
                },
                {
                  // /customers/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
                {
                  // /customers/:id/history
                  path: "history",
                  element: <History />,
                },
              ]
            },
            {
              path: "items/:id",
              element: <Item />,
              children: [
                {
                  // /items:/id
                  path: "",
                  element: <EditItem />,
                },
                {
                  // /items:/id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
                {
                  // /items:/id/history
                  path: "history",
                  element: <History />,
                },
              ]
            },
            {
              path: "works/:id",
              element: <Work />,
              children: [
                {
                  // /works:/id
                  path: "",
                  element: <EditWork />,
                },
                {
                  // /works:/id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
                {
                  // /works:/id/history
                  path: "history",
                  element: <History />,
                },
              ]
            },
            {
              path: "cars/:id",
              element: <Car />,
              children: [
                {
                  // /cars/:id
                  path: "",
                  element: <EditCar />,
                },
                {
                  // /cars/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
                {
                  // /cars/:id/history
                  path: "history",
                  element: <History />,
                },
              ]
            },
            {
              path: "incomes/:id",
              element: <Income />,
              children: [
                {
                  // /incomes/:id
                  path: "",
                  element: <EditIncome />,
                },
                {
                  // /incomes/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
              ]
            },
            {
              path: "outcomes/:id",
              element: <Outcome />,
              children: [
                {
                  // /outcomes/:id
                  path: "",
                  element: <EditOutcome />,
                },
                {
                  // /outcomes/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
              ]
            },
            {
              path: "banks/:id",
              element: <Bank />,
              children: [
                {
                  // /banks/:id

                  path: "",
                  element: <EditBank />,
                },
                {
                  // /banks/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
              ]
            },
            {
              path: "cash-boxes/:id",
              element: <CashBox />,
              children: [
                {
                  // /cash-boxes/:id
                  path: "",
                  element: <EditCashBox />,
                },
                {
                  // /cash-boxes/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
              ]
            },
            {
              path: "system/:id",
              element: <System />,
              children: [
                {
                  // /system/:id
                  path: "",
                  element: <EditSystem />,
                },
                {
                  // /system/:id/journal-entries
                  path: "journal-entries",
                  element: <Entries />,
                },
              ]
            },
            {
              // /documents
              path: "/documents",
              element: <Documents />,
            },
            {
              // /documents/add
              path: "/documents/add",
              element: <AddDocument />,
            },
            {
              // /documents/:id
              path: "/documents/:id",
              element: <EditDocument />,
            },
            {
              // /documents/settings/:number
              path: "/documents/settings/:id",
              element: <DocumentSettings />,
            },
            {
              // /tables
              path: "/tables",
              element: <Tables />,
            },
            {
              // /structure
              path: "/structure",
              element: <FieldsStructure />
            },
            {
              // /structure
              path: "/reports",
              element: <Reports />
            },
            {
              // /structure
              path: "/settings",
              element: <Settings />
            }

          ]
        },
      ],
    },

    {
      path: "/not-found",
      element: <NotFound />,
    },
  ]);

  // useEffect(() => {
  //   getCSRF();
  // }, []);

  // async function getCSRF() {
  //   try {
  //     await axiosClient.get('/');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }



  return (
    <>
      {themeColors && muiTheme && (
        <ThemeProvider theme={themeColors}>
          <MuiThemeProvider theme={muiTheme}>
            <StyledApp>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </StyledApp>
          </MuiThemeProvider>
        </ThemeProvider>
      )}
    </>


  )
}

export default App
