import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { getAllModels, getSystemTableByNumber } from '../../services/tablesService';
import { setBanksTable, setIncomesTable, setItemGroupsTable, setKupaTable, setManufacturersTable, setModelsTable, setOutcomesTable, setSortCodesTable, setTagsTable, setWarehousesTable } from '../../redux/features/tables';
import { useEffect, useState } from 'react';
import { AccountStructure } from '../../models/accounts-structure';
import { RootState } from '../../redux/store';
import { SelectTable } from '../../models/table';
import { DocumentStructure } from '../../models/document-structure';
import { Controller, FieldValues, UseFormReturn, Path, PathValue, FieldName, FieldErrors } from 'react-hook-form';
import { FormHelperText, InputAdornment } from '@mui/material';
import { ManufacturerModel } from '../../models/manufacturer-model';
import { getAccounts } from '../../services/accountsService';
import { ErrorMessage, FieldValuesFromFieldErrors } from "@hookform/error-message"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import { getDocumentsSettings } from '../../services/documentsSettingsService';


const CssField = styled(FormControl)({
    '& .MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
        color: '#9b9b9b',
    },
    '& .MuiInput-root': {
        color: 'white',
    },
    '& .MuiInput-root::before': {
        borderBottom: '1px solid #9b9b9b'
    },
    '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: '2px solid white',
    },
    // '& .muirtl-qtcxoq-MuiInputBase-root-MuiInput-root-MuiSelect-root::before': {
    //     borderBottom: '1px solid #9b9b9b',
    // },
    // '& .muirtl-qtcxoq-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before': {
    //     borderBottom: '2px solid #9b9b9b',
    // },
    '& .muirtl-qtcxoq-MuiInputBase-root-MuiInput-root-MuiSelect-root': {
        color: '#ffffff',
    },
    // '& .muirtl-14m612z-MuiFormLabel-root-MuiInputLabel-root': {
    //     color: '#9b9b9b',
    // },
    // '& .muirtl-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
    //     color: '#9b9b9b',
    // },
    // '& .muirtl-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon': {
    //     color: '#9b9b9b',
    // },
    // '& .muirtl-17pzuos-MuiFormLabel-root-MuiInputLabel-root': {
    //     color: '#9b9b9b',
    // },
    // '& .muirtl-10lj1ag-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
    //     color: '#9b9b9b',
    // },
    '& .MuiFormHelperText-root': {
        color: '#d32f2f',
    },

    '@media only screen and (max-width: 900px)': {
        width: '100%',
    }
});

const CssLTRField = styled(TextField)({
    '& .MuiInputLabel-root:not(.Mui-error, .Mui-focused)': {
        color: '#9b9b9b',
    },
    '& .MuiInput-root': {
        color: 'white',
    },
    '& .MuiInput-root::before': {
        borderBottom: '1px solid #9b9b9b'
    },
    '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: '2px solid white',
    },
    '& .MuiFormHelperText-root': {
        color: '#d32f2f',
    },
    '& .MuiInputLabel-root, & .MuiFormHelperText-root ,& .MuiFormHelperText-root.MuiInputLabel-root': {
        direction: 'ltr'
    },

    '@media only screen and (max-width: 900px)': {
        width: '100%',
    }
});


const priceFieldsNames = ["price", "total", "net"];
const percentageFieldsNames = ["discount"];

interface MuiInputProps<T extends FieldValues> {
    field: AccountStructure | DocumentStructure;
    variant: TextFieldVariants;
    size?: "small" | "medium";
    useForm: UseFormReturn<T>;
    prefix?: string | undefined;
    showRequired?: boolean;
    blockNotEditable?: boolean;
    disabled?: boolean;
    dateToNow?: boolean;
    handleBlur?: (name: Path<T>) => void;
    autoFocus?: boolean;

}

function MuiInput<T extends FieldValues>({ field, variant, size, useForm, prefix, showRequired = false, blockNotEditable = false, disabled=false, dateToNow = false, handleBlur, autoFocus = false }: MuiInputProps<T>) {

    const dispatch = useDispatch();


    const manufacturersTable = useSelector((state: RootState) => state.tables.manufacturersTable); //table 1
    const warehousesTable = useSelector((state: RootState) => state.tables.warehousesTable); //table 2
    const tagsTable = useSelector((state: RootState) => state.tables.tagsTable); //table 3
    const sortCodesTable = useSelector((state: RootState) => state.tables.sortCodesTable); //table 4
    const itemGroupsTable = useSelector((state: RootState) => state.tables.itemGroupsTable); //table 5
    const banksTable = useSelector((state: RootState) => state.tables.banksTable); //table 6


    

    const incomesTable = useSelector((state: RootState) => state.tables.incomesTable);
    const outcomesTable = useSelector((state: RootState) => state.tables.outcomesTable);
    const kupaTable = useSelector((state: RootState) => state.tables.kupaTable);

    const modelsTable = useSelector((state: RootState) => state.tables.modelsTable);


    const [table, setTable] = useState<SelectTable[] | ManufacturerModel[] | undefined>();

    const fieldName = prefix !== undefined ? `${prefix}${field.field_name}` : field.field_name;


    // get data if it's select field.
    useEffect(() => {

        if ((field.field_type === 4 || field.field_type === 5) && !table) {
            setTableByNumber(field.field_table);
        }

        //if this is model field
        if (field.field_type === 6 && !modelsTable) {
            //get models off all manufacturers
            getModels();
        }

    }, []);


    // when we get models off all manufacturers
    useEffect(() => {

        if (field.field_type === 6 && modelsTable) {
            setManufacturerModelsInSelectTable();
        }

    }, [modelsTable]);


    // if user changed manufacturer, get models of the selected manufacturer and reset model input value to ""
    useEffect(() => {

        const subscription = useForm.watch((_, { name }) => {


            // if ((name === "manufacturer" || name === "car.manufacturer") && field.field_type === 6 && modelsTable) {
            if (field.field_type === 6 && (name === "manufacturer" || name === "car.manufacturer") && modelsTable) {
                console.log(`field ${name} changed`);

                setManufacturerModelsInSelectTable();
                useForm.setValue(field.field_name as Path<T>, "" as PathValue<T, Path<T>>);
            }
        });

        return () => subscription.unsubscribe();

    }, [useForm.watch()]);


    useEffect(() => {


        if ((dateToNow && (field.field_type === 3 || field.field_type === 10) && !useForm.formState.defaultValues?.[fieldName])) {
            useForm.setValue(fieldName as Path<T>, dayjs().toDate() as PathValue<T, Path<T>>);

            const interval = setInterval(() => {
                if (!isFieldTouched(useForm.formState.touchedFields as Partial<T>, fieldName) && !isFieldDirty(useForm.formState.dirtyFields as Partial<T>, fieldName)) {
                    useForm.setValue(fieldName as Path<T>, dayjs().toDate() as PathValue<T, Path<T>>);
                }

            }, 1000); // Update every minute

            return () => clearInterval(interval);
        }

    }, []);


    function isFieldDirty<T extends Record<string, any>>(dirtyFields: Partial<T>, fieldName: keyof T): boolean {
        // console.log("isFieldDirty: ", fieldName, dirtyFields);
        return !!dirtyFields[fieldName];
    }

    function isFieldTouched<T extends Record<string, any>>(touchedFields: Partial<T>, fieldName: keyof T): boolean {
        return !!touchedFields[fieldName];
    }

    // get models of all manufacturers
    async function getModels() {

        if (!modelsTable) {
            const data = await getAllModels();
            dispatch(setModelsTable(data));
        }

    }

    // find the models of the chosen manufacturer
    function setManufacturerModelsInSelectTable() {

        const manufacturerIndex = useForm.getValues("manufacturer" as Path<T>) || useForm.getValues("car.manufacturer" as Path<T>);
        const manufacturer = manufacturersTable?.find(manufacturer => manufacturer.index === manufacturerIndex);
        const models = modelsTable?.filter(model => model.manufacturer_id === manufacturer?.id);

        setTable(models);
    }


    function checkInputValue(value: string | number | boolean | null | undefined, expected: string = 'string'): string | number | null {
        if (value === null || value === undefined) {

            // if (expected === 'number') {
            //     return null;
            // }

            return "";
        }

        if (typeof value === 'boolean') {
            return value ? 1 : 0;
        }

        if (typeof value === 'string' && expected === 'number') {
            const num = parseFloat(value);
            return isNaN(num) ? value : num;
        }


        return value;

    }



    async function setTableByNumber(number: number): Promise<void> {

        let data: SelectTable[] | undefined = [];

        switch (number) {
            case 1:
                if (!manufacturersTable) {
                    const responseData = await getSystemTableByNumber(1);
                    dispatch(setManufacturersTable(responseData));
                    data = responseData;
                }
                else {
                    data = manufacturersTable;
                }
                break;

            case 2:
                if (!warehousesTable) {
                    const responseData = await getSystemTableByNumber(2);
                    dispatch(setWarehousesTable(responseData));
                    data = responseData;
                }
                else {
                    data = warehousesTable;
                }
                break;

            case 3:
                if (!tagsTable) {
                    const responseData = await getSystemTableByNumber(3);
                    dispatch(setTagsTable(responseData));
                    data = responseData;
                }
                else {
                    data = tagsTable;
                }
                break;

            case 4:
                if (!sortCodesTable) {
                    const responseData = await getSystemTableByNumber(4);
                    dispatch(setSortCodesTable(responseData));
                    data = responseData;
                }
                else {
                    data = sortCodesTable;
                }
                break;

            case 5:

                if (!itemGroupsTable) {
                    const responseData = await getSystemTableByNumber(5);
                    dispatch(setItemGroupsTable(responseData));
                    data = responseData;
                }
                else {
                    data = itemGroupsTable;
                }
                break;

            case 6:
                if (!banksTable) {
                    const responseData = await getSystemTableByNumber(6);
                    dispatch(setBanksTable(responseData));
                    data = responseData;
                }
                else {
                    data = banksTable;
                }
                break;


            case 12:
                if (!incomesTable) {
                    const incomes = await getAccounts({ "account_type": "הכנסה" }, 'id', "0", 'ASC', []);
                    const incomesTable: SelectTable[] = incomes?.map((obj) => {
                        return {
                            "index": obj.card_number,
                            "name": obj.account_name
                        }
                    })
                    data = incomesTable;
                    dispatch(setIncomesTable(data));

                }
                else {
                    data = incomesTable;
                }
                break;

            case 13:
                if (!outcomesTable) {
                    const outcomes = await getAccounts({ "account_type": "הוצאה" }, 'id', "0", 'ASC', []);
                    const outcomesTable: SelectTable[] = outcomes?.map((obj) => {
                        return {
                            "index": obj.card_number,
                            "name": obj.account_name
                        }
                    })
                    data = outcomesTable;
                    dispatch(setOutcomesTable(data));

                }
                else {
                    data = outcomesTable;
                }
                break;

            case 15:
                if (!kupaTable) {
                    const kupa = await getAccounts({ "account_type": "קופה" }, 'id', "0", 'ASC', []);
                    const kupaTable: SelectTable[] = kupa?.map((obj) => {
                        return {
                            "index": obj.card_number,
                            "name": obj.account_name
                        }
                    })
                    data = kupaTable;
                    dispatch(setKupaTable(data));

                }
                else {
                    data = outcomesTable;
                }
                break;

            case 18:
                // if (!outcomesTable) {
                const documentsSettings = await getDocumentsSettings();
                const documentsNamesTable: SelectTable[] = documentsSettings?.map((obj) => {
                    return {
                        "index": obj.type_number,
                        "name": obj.name
                    }
                }) || [];
                data = documentsNamesTable;
                // dispatch(setOutcomesTable(data));

                // }
                // else {
                //     data = outcomesTable;
                // }
                break;

            case 19:
                // if (!outcomesTable) {


                data = [
                    {
                        "index": 0,
                        "name": "פתוח"
                    },
                    {
                        "index": 1,
                        "name": "סגור"
                    },
                    {
                        "index": 2,
                        "name": "סגור חלקית"
                    },
                ];
                // dispatch(setOutcomesTable(data));

                // }
                // else {
                //     data = outcomesTable;
                // }
                break;

            default:
                break;
        }

        setTable(data);

    }



    switch (field.field_type) {

        case 1: //hebrew

            return <CssField sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                <Controller

                    name={fieldName as Path<T>}
                    control={useForm?.control}
                    defaultValue={"" as PathValue<T, Path<T>>}

                    render={({ field: { onChange, onBlur, value, name } }) => (
                        <TextField

                            id={field?.field_name}
                            value={checkInputValue(value)}
                            onChange={onChange}
                            onBlur={() => { onBlur(); handleBlur?.(name); }}

                            variant={variant}
                            size={size}
                            label={field?.field_display_name}
                            disabled={disabled || (blockNotEditable && !field.editable)}
                            required={field.required && showRequired ? true : undefined}
                            autoComplete="off"

                            error={useForm?.formState?.errors?.[fieldName] ? true : false}
                            helperText={<ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} />}
                        />
                    )}
                />
            </CssField>


        case 2: //english

            return <CssField sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                {/* <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel> */}
                <Controller

                    name={fieldName as Path<T>}
                    control={useForm?.control}
                    defaultValue={"" as PathValue<T, Path<T>>}

                    render={({ field: { onChange, onBlur, value, name } }) => (
                        <TextField

                            id={field?.field_name}
                            value={checkInputValue(value)} //  ( null => "") || ( "" => "") || ( 0 => "" ) || (number => number)
                            onChange={onChange}
                            onBlur={() => { onBlur(); handleBlur?.(name); }}

                            variant={variant}
                            size={size}
                            label={field?.field_display_name}
                            disabled={disabled || (blockNotEditable && !field.editable)}
                            required={field.required && showRequired ? true : undefined}
                            autoComplete="off"
                            sx={{ direction: 'rtl' }}

                            error={useForm?.formState?.errors?.[fieldName] ? true : false}
                            helperText={<ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} />}
                        />
                    )}
                />
            </CssField>


        case 3:
            // return;
            return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="he">
                <Controller
                    name={fieldName as Path<T>}
                    control={useForm?.control}

                    render={({ field: { onChange, onBlur, value } }) => (

                        <DatePicker
                            label={field.field_display_name}
                            // sx={{ direction: "rtl" }}
                            value={
                                value ? dayjs(value) : null
                                // dayjs(value).isValid()? dayjs(value) : null
                                // getDateTimeValue(value)
                            }
                            // onBlur={onBlur}
                            // onSelectedSectionsChange={onBlur}
                            onOpen={onBlur}
                            onChange={onChange}
                            format="DD/MM/YYYY"

                            slotProps={{
                                textField: { variant: variant },
                                actionBar: { actions: ['today', 'cancel', 'clear'] },


                            }}

                            localeText={{
                                todayButtonLabel: 'היום',
                                cancelButtonLabel: 'ביטול',
                                clearButtonLabel: 'איפוס'
                            }}


                        />
                    )} />
            </LocalizationProvider >




        case 4: case 6: // select or degem (index)
            {
                return table ?

                    <CssField variant={variant} sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                        <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel>
                        <Controller

                            name={fieldName as Path<T>}
                            control={useForm?.control}
                            defaultValue={"" as PathValue<T, Path<T>>}

                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <Select
                                    // defaultValue={""}
                                    value={checkInputValue(value)} //  ( null => "") || ( "" => "") || ( 0 => "" ) || (number => number)
                                    onChange={onChange}
                                    onBlur={() => { onBlur(); handleBlur?.(name); }}
                                    // onChange={(e) => {
                                    //     if (!isNaN(parseInt(e.target.value))) {
                                    //         onChange(parseInt(e.target.value))
                                    //     }
                                    //     if (e.target.value === "") {
                                    //         onChange("")
                                    //     }
                                    // }} // Convert value to number on change
                                    id={field?.field_name}
                                    size={size}
                                    label={field?.field_display_name}
                                    disabled={disabled || (blockNotEditable && !field.editable)}
                                    >
                                    <MenuItem value={""}></MenuItem>
                                    {
                                        table?.map((tableItem, index) => (
                                            <MenuItem key={index} value={tableItem.index}>{index + 1}. {tableItem.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            )}
                        />
                        {/* <FormHelperText>{useForm?.formState?.errors?.[fieldName]?.message as string}</FormHelperText> */}
                        <FormHelperText><ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} /></FormHelperText>
                    </CssField>

                    :

                    <CssField variant={variant} sx={{ minWidth: 120 }}>
                        <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel>
                        <Select
                            value={""}
                            size={size}
                            label={field?.field_display_name}
                        ></Select>
                    </CssField>

            }

        case 5: // select (value)
            {
                return table ?

                    <CssField variant={variant} sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                        <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel>
                        <Controller

                            name={fieldName as Path<T>}
                            control={useForm?.control}
                            defaultValue={"" as PathValue<T, Path<T>>}

                            render={({ field: { onChange, onBlur, value, name } }) => (
                                <Select
                                    // defaultValue={""}
                                    value={checkInputValue(value)} //  ( null => "") || ( "" => "") || ( 0 => "" ) || (number => number)
                                    onChange={onChange}
                                    onBlur={() => { onBlur(); handleBlur?.(name); }}
                                    // onChange={(e) => {
                                    //     if (!isNaN(parseInt(e.target.value))) {
                                    //         onChange(parseInt(e.target.value))
                                    //     }
                                    //     if (e.target.value === "") {
                                    //         onChange("")
                                    //     }
                                    // }} // Convert value to number on change
                                    id={field?.field_name}
                                    size={size}
                                    label={field?.field_display_name}
                                    disabled={disabled || (blockNotEditable && !field.editable)}
                                    >
                                    <MenuItem value={""}></MenuItem>
                                    {
                                        table?.map((tableItem, index) => (
                                            <MenuItem key={index} value={tableItem.name || ""}>{tableItem.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            )}
                        />
                        {/* <FormHelperText>{useForm?.formState?.errors?.[fieldName]?.message as string}</FormHelperText> */}
                        <FormHelperText><ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} /></FormHelperText>
                    </CssField>

                    :

                    <CssField variant={variant} sx={{ minWidth: 120 }}>
                        <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel>
                        <Select
                            value={""}
                            size={size}
                            label={field?.field_display_name}
                        ></Select>
                    </CssField>

            }



        case 7: //number

            return <CssField sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                <Controller

                    name={fieldName as Path<T>}
                    control={useForm?.control}
                    // defaultValue={"" as PathValue<T, Path<T>>}

                    render={({ field: { onChange, onBlur, value, name } }) => (
                        <CssLTRField

                            id={field?.field_name}
                            // value={value} //  ( null => "") || ( "" => "") || ( 0 => "" ) || (number => number)
                            value={checkInputValue(value, 'number')} //  ( null => "") || ( "" => "") || ( 0 => "" ) || (number => number)
                            // onChange={onChange}
                            onChange={(e) => {
                                onChange(checkInputValue(e.target.value, 'number'))
                            }}
                            onBlur={() => { onBlur(); handleBlur?.(name); }}
                            type="number"

                            variant={variant}
                            size={size}
                            autoFocus={autoFocus}
                            label={field?.field_display_name}
                            disabled={disabled || (blockNotEditable && !field.editable)}
                            required={field.required && showRequired ? true : undefined}
                            autoComplete="off"
                            sx={{ direction: 'rtl' }}
                            InputProps={priceFieldsNames.includes(field.field_name) ? {
                                startAdornment: <InputAdornment position="start">₪</InputAdornment>,
                            } : percentageFieldsNames.includes(field.field_name) ? {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            } : {}}


                            error={useForm?.formState?.errors?.[fieldName] ? true : false}
                            helperText={<ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} />}
                        />
                    )}
                />
            </CssField>


        case 10: //time
            return <LocalizationProvider dateAdapter={AdapterDayjs} >
                <Controller
                    name={fieldName as Path<T>}
                    control={useForm?.control}

                    render={({ field: { onChange, onBlur, value } }) => (

                        <TimePicker
                            label={field.field_display_name}
                            // sx={{ direction: "rtl" }}
                            value={
                                dayjs(value).isValid() ? dayjs(value) : null
                                // getDateTimeValue(value)
                            }
                            onChange={onChange}
                            // onSelectedSectionsChange={onBlur}
                            onOpen={onBlur}
                            // onSelectedSectionsChange={onBlur}
                            onViewChange={onBlur}
                            format="HH:mm"
                            slotProps={{
                                textField: { variant: variant },
                                actionBar: {
                                    actions: ['cancel', 'accept'],
                                },
                            }}
                            ampm={false}
                            localeText={{
                                cancelButtonLabel: 'ביטול',
                                okButtonLabel: 'אישור'
                            }}

                        />

                    )} />
            </LocalizationProvider >

        case 21: // true/false select
            return <CssField variant={variant} sx={{ minWidth: 120 }} error={useForm?.formState?.errors?.[fieldName] ? true : false}>
                <InputLabel>{field?.field_display_name}{field.required && showRequired ? "*" : ""}</InputLabel>
                <Controller

                    name={fieldName as Path<T>}
                    control={useForm?.control}
                    defaultValue={"" as PathValue<T, Path<T>>}

                    render={({ field: { onChange, onBlur, value, name } }) => (
                        <Select
                            // defaultValue={""}
                            value={checkInputValue(value, 'number')} // (null || ""  => "" )  ||  ( boolean => number: 0 || 1 ) || ( number => number: 0 || 1 )
                            // onChange={onChange}
                            onChange={(e) => onChange(e.target.value === "" ? "" : !!e.target.value)} // ( "" => "")  ||  ( 0 || 1 => boolean: true || false )
                            onBlur={() => { onBlur(); handleBlur?.(name); }}
                            id={field?.field_name}
                            size={size}
                            label={field?.field_display_name}
                            disabled={disabled || (blockNotEditable && !field.editable)}
                        >
                            <MenuItem value={""}></MenuItem>
                            <MenuItem value={0}>לא</MenuItem>
                            <MenuItem value={1}>כן</MenuItem>
                        </Select>
                    )}
                />
                {/* <FormHelperText>{useForm?.formState?.errors?.[field.field_name]?.message as string}</FormHelperText> */}
                <FormHelperText><ErrorMessage errors={useForm.formState.errors} name={fieldName as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>} /></FormHelperText>

            </CssField>

        default:
            break;
    }


}


export default MuiInput