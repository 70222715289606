import { z } from "zod";
import { DocumentSettingsSchema, DocumentSettingsSearchSchema } from "./document-settings";
import { AccountDocumentFormSchema, AccountSchema, AccountSearchSchema } from "./account";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentItemSchema } from "./document-item";
import { DocumentWorkSchema } from "./document-work";
import { CarDocumentFormSchema, CarSchema, CarSearchSchema } from "./car";
import dayjs from "dayjs";

z.setErrorMap(customErrorMap);

// const OptionalCarSchema = CarSchema.nullable();


export const DocumentSchema = z.object({

    
    id: z.number(),
    business_id: z.number(),
    account_id: z.number(),
    document_settings_id: z.number(),

    
    document_number: z.number(),
    type_number: z.number(),
    // date: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string()),
    time: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    total: z.number(),
    vat: z.number(),
    to_pay: z.number(),
    balance: z.number(),
    works_total: z.number().nullable(),
    items_total: z.number().nullable(),
    discount: z.number().nullable(),
    reference: z.string().nullable(),

    
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    // loads
    document_settings: DocumentSettingsSchema.optional(),
    account: AccountSchema.optional(),
    customer: AccountSchema.optional(),
    car: CarSchema.optional(),
    // custom_data: z.preprocess((val) => myPreProcess(val), z.array(DocumentCustomDataSchema).optional()),
    items: z.array(DocumentItemSchema).optional(),
    works: z.array(DocumentWorkSchema).optional(),

});

export const DocumentFormSchema = z.object({

    //from server (optional)
    id: z.number().nullish(),
    business_id: z.number().nullish(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    
    account: AccountSchema.optional(),


    //client && server
    account_id: z.number(),
    document_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    type_number: z.number(),
    date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string()),
    time: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date()),
    status: z.enum(["פתוח", "סגור", "סגור חלקית"]),
    total: z.number().positive(),
    vat: z.number(),
    to_pay: z.number(),
    balance: z.number(),
    works_total: z.number().nullish(),
    items_total: z.number().nullish(),
    discount: z.number().nullish(),
    reference: z.string().nullish(),

    //inputs required
    // account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    // account_name: z.string(),
    document_settings_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),

    //inputs optional
    // identity_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    // license_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    // manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    // model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    // info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),


    document_settings: DocumentSettingsSchema.optional(),
    // custom_data: z.array(DocumentCustomDataSchema).optional(),
    customer: AccountDocumentFormSchema.optional(),
    car: CarDocumentFormSchema.optional(),
    items: z.array(DocumentItemSchema).optional(),
    works: z.array(DocumentWorkSchema).optional(),


});


// export const PartialDocumentSchema = DocumentSchema.extend({
//     document_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullable()),
//     // account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullable()),
//     date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().optional().nullable()),
//     time: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('HH:mm')), z.string().optional().nullable()),
//     status: z.enum(["פתוח", "סגור", "סגור חלקית", ""]),
//     document_settings: (DocumentSettingsSchema.partial()).optional(),
//     customer: (AccountSchema.partial().nullable().nullish()).optional(),
//     car: NullableCarSchema.optional(),
// }).partial();

// export type PartialDocument = z.infer<typeof PartialDocumentSchema>


// export const DocumentFormSchema = DocumentSchema.extend({});
// export type DocumentForm = z.infer<typeof DocumentFormSchema>



export const DocumentSearchSchema = z.object({


    account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    
    document_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    type_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullish()),
    time: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().nullish()),
    status: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    vat: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    to_pay: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    balance: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    works_total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    items_total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    reference: z.preprocess((val) => myPreProcess(val), z.string().nullish()),


    // loads
    document_settings: DocumentSettingsSearchSchema.optional(),
    account: AccountSearchSchema.optional(),
    customer: AccountSearchSchema.optional(),
    car: CarSearchSchema.optional(),
    // custom_data: z.array(DocumentCustomDataSearchSchema).optional(),

});

export type Document = z.infer<typeof DocumentSchema>
export type DocumentForm = z.infer<typeof DocumentFormSchema>
export type DocumentSearch = z.infer<typeof DocumentSearchSchema>
