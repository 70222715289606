import axiosClient from "../axios-client";
import { Account, AccountForm } from "../models/account";


export const getAccounts = async ( body: object = {}, sortKey: string = 'id', maxRecords: string = '0', sortMethod: string = 'asc', load: string[] = [], signal?: AbortSignal): Promise<Account[]> => {
    const loadString = `?load=${load.join(',')}`;
    const response = await axiosClient.post(`/api/accounts/search/${maxRecords}/${sortKey}/${sortMethod}${loadString}`, body, {
        signal: signal // Pass the signal to the request options
    });

    return response.data;

}

export const getAccountById = async (id: string, signal?: AbortSignal): Promise<Account> => {
    const response = await axiosClient.get(`/api/accounts/${id}`, {
        signal: signal // Pass the signal to the request options
    });

    return response.data;
}

export const getNextAccountNumber = async (accountType: string): Promise<number> => {
    const response = await axiosClient.post(`/api/accounts/next-number`,
        {
            account_type: accountType,
        });
    return response.data.next_account_index;
}

export const addAccount = async (accountToAdd: AccountForm): Promise<Account> => {
    const response = await axiosClient.post(`/api/accounts`, accountToAdd);
    return response.data;
}

export const saveAccount = async (accountId: number, accountToSave: AccountForm): Promise<Account> => {
    const response = await axiosClient.patch(`/api/accounts/${accountId}`, accountToSave);
    return response.data;
}

export const deleteAccount = async (accountId: number): Promise<void> => {
    await axiosClient.delete(`/api/accounts/${accountId}`);
}

