import { z } from "zod";
import { AccountCustomDataFormSchema, AccountCustomDataSchema, AccountCustomDataSearchSchema } from "./account-custom-data";
import { EntrySchema } from "./entry";
import { customErrorMap, myPreProcess } from "../services/zodService";
import dayjs from "dayjs";


z.setErrorMap(customErrorMap);


export const AccountSchema = z.object({
    
    id: z.number(),  
    business_id: z.number(),
    account_type: z.enum(["לקוח-ספק","הכנסה","הוצאה","בנק","קופה","מערכת"]),
    card_number: z.number(),
    account_number: z.number(),
    account_name: z.string(),
    sort_code: z.number(),
    
    //optional
    info: z.string().nullable(),
    identity_number: z.string().nullable(),
    type: z.number().nullable(),
    address: z.string().nullable(),
    phone: z.string().nullable(),
    mobile: z.string().nullable(),
    city: z.string().nullable(),
    email: z.string().nullable(),
    site: z.string().nullable(),
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullable()),
    payment_type: z.string().nullable(),

    //loads
    custom_data: z.array(AccountCustomDataSchema).optional(),
    entries: z.array(EntrySchema).optional(),
    

    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
});



export const AccountFormSchema = z.object({

    //from server (optional)
    id: z.number().optional(),  
    business_id: z.number().optional(),
    
    //client && server
    account_type: z.enum(["לקוח-ספק","הכנסה","הוצאה","בנק","קופה","מערכת"]),
    card_number: z.number(),
    custom_data: z.array(AccountCustomDataFormSchema).optional(),

    //inputs required
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    account_name: z.preprocess((val) => myPreProcess(val), z.string()),
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    //inputs optional
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    identity_number: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[0-9]*$/,"השדה יכול להכיל ספרות בלבד.").nullish()),
    type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    address: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    phone: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()),
    mobile: z.preprocess((val) => myPreProcess(val), z.string().regex(/^[-+0-9]*$/, "השדה מכיל תווים לא חוקיים.").nullish()),
    city: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    email: z.preprocess((val) => myPreProcess(val), z.string().email("אימייל לא תקין.").nullish()),
    site: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean({message: "יש לבחור ערך מהרשימה."}).nullish()),
    payment_type: z.preprocess((val) => myPreProcess(val), z.string().nullish()),


});


export const AccountDocumentFormSchema = AccountFormSchema.extend({
    document_id: z.number(),
    customer_id: z.number().optional(),
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()), 
});


export const AccountSearchSchema = z.object({
    id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_type: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    card_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    account_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    sort_code: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
    //optional
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    identity_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    type: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    address: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    phone: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    mobile: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    city: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    email: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    site: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    vat_include: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
    payment_type: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

    //loads
    custom_data: z.array(AccountCustomDataSearchSchema).optional(),
    

    created_at: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullish()),
    updated_at: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('HH:mm')), z.string().nullish()),

});



export type Account = z.infer<typeof AccountSchema>
export type AccountForm = z.infer<typeof AccountFormSchema>
export type AccountSearch = z.infer<typeof AccountSearchSchema>
