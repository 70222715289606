import styled from '@emotion/styled'

export const StyledSearchTable = styled.div`

    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 30px;
    background-color: ${({theme})=> theme.colors.section_color};
    padding: 20px 50px;

`