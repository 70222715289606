
import { useEffect, useRef, useState } from 'react';
import DataTable from '../../../components/data-table/DataTable';
import { getAccounts } from '../../../services/accountsService';
import { getAccountsStructure } from '../../../services/accountsStructureService';
import SearchTable from '../../../components/search-table/SearchTable';
import { StyledOutcomes } from './outcomes.styled';
import { deleteEmptyFieldsFromObject, setAllValuesToNull } from '../../../helpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { setOutcomesStructure } from '../../../redux/features/structure';
import { AccountStructure } from '../../../models/accounts-structure';
import { Account, AccountSchema, AccountSearch } from '../../../models/account';
import { RootState } from '../../../redux/store';
import { Skeleton } from '@mui/material';
import { CanceledError } from 'axios';
import ListItemIcon from '@mui/material/ListItemIcon';
import MovingIcon from '@mui/icons-material/Moving';
import EditIcon from '@mui/icons-material/Edit';
import { setOutcomesSearch } from '../../../redux/features/browse';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useLocation, useNavigate } from 'react-router-dom';



const options = [
    {
        label: "עריכה",
        icon: <ListItemIcon sx={{ color: 'white' }}><EditIcon fontSize="small" /></ListItemIcon>,
        href: ""
    },
    {
        label: "תנועות",
        icon: <ListItemIcon sx={{ color: 'white' }}><MovingIcon fontSize="small" /></ListItemIcon>,
        href: "journal-entries"
    },
];


interface OutcomesProps {
    accountTypeName: string;
    searchFormData?: AccountSearch;
}

function Outcomes({ accountTypeName, searchFormData }: OutcomesProps) {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const outcomesStructure = useSelector((state: RootState) => state.structure.outcomes)
    const outcomesSearch = useSelector((state: RootState) => state.browse.outcomesSearch)

    const [data, setData] = useState<Account[]>([]);
    const [searchStructure, setSearchStructure] = useState<AccountStructure[]>();
    const [tableStructure, setTableStructure] = useState<AccountStructure[]>();
    const [sortKey, setSortKey] = useState<string>('id');
    const [sortMethod, setSortMethod] = useState<string>('DESC');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);


    const outcomeSearchForm = useForm<AccountSearch>({defaultValues:{
        "account_type": "הוצאה",
        ...outcomesSearch,
        ...searchFormData
    }});



    useEffect(() => {
        if (!outcomesStructure) {
            getStructureAndSaveAtStore();
        }
    }, []);

    useEffect(() => {
        setSearchStructure(outcomesStructure?.filter(field => field.show_search))
        setTableStructure(outcomesStructure?.filter(field => field.show_browse))
    }, [outcomesStructure]);

    useEffect(() => {
        if (sortKey && sortMethod) {
            outcomeSearchForm.handleSubmit(onSearch)();
        }
    }, [sortKey, sortMethod]);


    useEffect(() => {
        outcomeSearchForm.watch(() => {
            outcomeSearchForm.handleSubmit(onSearch)();
        })
    }, [outcomeSearchForm.watch]);



    function handleSortKeyChanged(value: string) {
        setSortKey(value);
    }

    function handleSortMethodChanged(value: string) {
        setSortMethod(value);
    }



    const onSearch: SubmitHandler<AccountSearch> = (data) => {
        dispatch(setOutcomesSearch(data));
        getData(data);
    }


    async function getData(data: AccountSearch) {

        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();
        setIsLoading(true);

        try {
            const responseData = await getAccounts(deleteEmptyFieldsFromObject(data), sortKey || 'id', "10", sortMethod || 'ASC', ["customData", "entries"], abortControllerRef.current.signal);
            const outcomesParse = z.array(AccountSchema).parse(responseData);
            setData(outcomesParse || []);
            setIsLoading(false);
        } catch (error: unknown) {
            if (error instanceof CanceledError) {
                return;
            }
            console.error(error);
        }

    }

    async function getStructureAndSaveAtStore() {
        try {

            const structure = await getAccountsStructure({
                account_type: accountTypeName
            });

            dispatch(setOutcomesStructure(structure || []));

        } catch (error) {
            console.error(error);
        }
    }

    function editSearchStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'search',
            structureName: "הוצאה",
            structureType: 'account',
            accountType: 'outcomes'
        } });
    }

    function editBrowseStructure(){
        navigate('/structure', { replace: true, state: {
            from: location.pathname,
            fromType: 'browse',
            structureName: "הוצאה",
            structureType: 'account',
            accountType: 'outcomes'
        } });
    }

    function reset() {

        const values = setAllValuesToNull(outcomeSearchForm.getValues());

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                outcomeSearchForm.setValue(key as keyof AccountSearch, values[key as keyof AccountSearch]);
            }
        }

        outcomeSearchForm.setValue("account_type", "הוצאה")
    }

    return (
        <StyledOutcomes>
            {searchStructure ?
                <SearchTable structure={searchStructure} setStructure={editSearchStructure} reset={reset} useForm={outcomeSearchForm} style={{ backgroundColor: "rgb(35, 48, 68)", padding: 5, marginButton: 20 }} /> :
                <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />}
            {tableStructure ?
                <DataTable structure={tableStructure} setStructure={editBrowseStructure} sortKey={sortKey} setSortKey={handleSortKeyChanged} sortMethod={sortMethod} setSortMethod={handleSortMethodChanged} data={data} isLoading={isLoading} options={options} /> :
                <Skeleton variant="rectangular" height={150} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />}
        </StyledOutcomes>
    );
}

export default Outcomes;