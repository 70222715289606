import { Typography } from "@mui/material"
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { groupBy } from "../../../../helpers/helpers";
import { useEffect } from "react";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setBanksStructure } from "../../../../redux/features/structure";
import { UseFormReturn } from "react-hook-form";
import { AccountForm } from "../../../../models/account";
import { StyledAddBank } from "./AddBank.styled";


interface AddBankProps {
  setStructureIsLoading: (isLoading: boolean) => void;
  useForm: UseFormReturn<AccountForm>
}

function AddBank({ setStructureIsLoading, useForm }: AddBankProps) {

  const bankStructure = useSelector((state: RootState) => state.structure.banks);
  const structureSortedByTitle = groupBy(bankStructure, "account_title")
  const dispatch = useDispatch()
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure=> !!structure.show_form === true);


  useEffect(() => {
    if (!bankStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);


  async function getStructureAndSaveAtStore() {
    try {
      setStructureIsLoading(true);

      const structure = await getAccountsStructure({
        account_type: "בנק"
      });

      dispatch(setBanksStructure(structure || []));

    } catch (error) {
      console.error(error);
    }
    setStructureIsLoading(false);
  }



  return (
    <StyledAddBank>
      {bankStructure &&
        <>
          <div>
            <Typography variant="body1">פרטי עבודה</Typography>
            <div className="fields">
              {structureSortedByTitle?.["פרטים"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
            <div className="fields">
              { structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

        </>}

    </StyledAddBank>
  )
}

export default AddBank