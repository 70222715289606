import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentStructureShowFormSchema, DocumentStructureShowSchema } from "./document-structure-show";

z.setErrorMap(customErrorMap);

export const DocumentStructureSchema = z.object({

    //from server (optional)
    id: z.number(),
    // document_settings_id: z.number(),
    business_id: z.number(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    show_form_array: z.array(DocumentStructureShowSchema),

    //client && server
    // document_type_number: z.number(),
    type: z.string().min(1),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string(),
    field_heb_name: z.string().min(1),
    field_type: z.number(),
    custom_field: z.number().optional().nullish(),
	to_print: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullable()),

    //inputs required
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.string().min(1),
    field_length: z.number(),
    field_table: z.number(),


    //inputs optional
    location: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
});

export const DocumentStructureFormSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    show_form_array: z.array(DocumentStructureShowFormSchema).optional(),

    //client && server
    // document_settings_id: z.number(),
    // document_type_number: z.number(),
    type: z.string().min(1),
    required: z.boolean(),
    editable: z.boolean(),
    field_name: z.string(),
    field_heb_name: z.string().min(1),
    field_type: z.number(),
    custom_field: z.number().optional().nullish(),
	to_print: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

    //inputs required
    show_search: z.boolean(),
    show_browse: z.boolean(),
    show_form: z.boolean(),
    field_display_name: z.string().min(1),
    field_length: z.number(),
    field_table: z.number(),


    //inputs optional
    location: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
});

export type DocumentStructure = z.infer<typeof DocumentStructureSchema>
export type DocumentStructureForm = z.infer<typeof DocumentStructureFormSchema>
