import { useEffect, useState } from "react";
import { StyledTable } from "../Table.styled"
import { Table } from "../../../models/table";
import { CircularProgress, Typography } from "@mui/material";
import { addCustomTable, addCustomTableItem, editCustomTable, editCustomTableItem, getCustomTableItems, getCustomTables } from "../../../services/tablesService";

type selectedTableDetails = {
  customTable: Table | null,
  items: Table[] | null
}


function CustomTables() {

  const [customTables, setCustomTables] = useState<Table[]>();

  const [selectedTable, setSelectedTable] = useState<selectedTableDetails>({
    customTable: null,
    items: null
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [action, setAction] = useState<string>("browse")


  //use effects

  useEffect(() => {
    getSystemTablesData();
  }, []);


  // get data functions

  const getSystemTablesData = async () => {
    const data = await getCustomTables();
    setCustomTables(data);
    setIsLoading(false);
  }

  const getCustomTableData = async (table: Table) => {
    setIsLoading(true);
    const data = await getCustomTableItems(table.id);

    setSelectedTable({
      ...selectedTable,
      customTable: table,
      items: data || null
    });

    setIsLoading(false);
  }

  const addNewCustomTable = async () => {
    try {
      const index = (customTables?.length || 0) + 1;
      const addedTable = await addCustomTable(index);
      setCustomTables([...(customTables || []), addedTable]);
    } catch (error) {
      console.error(error);
    }
  }

  const addNewCustomTableItem = async () => {

    //if not custom table chosen, exit.
    if (!selectedTable.customTable?.id || !selectedTable.items) {
      return;
    }

    try {
      const index = (selectedTable.items?.length || 0) + 1;
      const addedTable = await addCustomTableItem(selectedTable.customTable.id, index);
      setSelectedTable({ ...selectedTable, items: [...selectedTable.items, addedTable] });
    } catch (error) {
      console.error(error);
    }
  }

  const apdateState = (event: React.ChangeEvent<HTMLInputElement>, tableChanged: Table) => {

    if (selectedTable.customTable?.index) {
      const systemTablesToUpdate = selectedTable?.items?.map(item => item.id === tableChanged.id ? { ...tableChanged, name: event.target.value } : item);
      setSelectedTable({ ...selectedTable, items: systemTablesToUpdate || null });
      return;
    }

    if (customTables) {
      const customTablesToUpdate = customTables.map(table => table.id === tableChanged.id ? { ...tableChanged, name: event.target.value } : table);
      setCustomTables(customTablesToUpdate);
    }

  }

  const saveInput = async (table: Table) => {

    if (selectedTable.customTable?.index) {
      //toDo: edit custom table item
      const tableItemUpdated = await editCustomTableItem(table);
      const systemTablesToUpdate = selectedTable?.items?.map(item => item.id === tableItemUpdated.id ? tableItemUpdated : item);
      setSelectedTable({ ...selectedTable, items: systemTablesToUpdate || null });
      return;
    }

    if (customTables) {
      const tableUpdated = await editCustomTable(table);
      const customTablesToUpdate = customTables.map(table => table.id === tableUpdated.id ? tableUpdated : table);
      setCustomTables(customTablesToUpdate);
    }
  }

  function returnBack() {

    if (selectedTable.customTable?.name) {
      setSelectedTable({
        customTable: null,
        items: null
      });
    }
  }

  return (
    <StyledTable>
      {isLoading && <CircularProgress color="inherit" sx={{ display: "block", margin: "auto", padding: 4 }} />}

      {!isLoading && customTables && !selectedTable.customTable?.name && (
        <>
          <Typography variant="h3" className="title">
            טבלאות פנימיות
          </Typography>
          <table>
            <tbody>
              {
                customTables.map((table, index) => (
                  <tr key={index}>
                    <td>{table.index}.</td>
                    <td className="expand">
                      {action === 'edit' ?
                        <input value={table.name || ""} onChange={(e) => { apdateState(e, table) }} onBlur={() => { saveInput(table) }} /> :
                        <a className="clickable" onClick={() => { getCustomTableData(table) }}>{table.name}</a>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttons">
            {action === 'browse' ? <button onClick={() => { setAction('edit') }}>עריכה</button> : <button onClick={() => { setAction('browse') }}>סיום עריכה</button>}
            <button onClick={addNewCustomTable}>הוספת טבלה</button>
          </div>
        </>
      )}

      {!isLoading && selectedTable.customTable?.name && (
        <>
          <Typography variant="h3" className="title">
            טבלת {selectedTable.customTable.name}
          </Typography>
          <table>
            <tbody>
              {
                selectedTable.items?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.index}.</td>
                    <td className="expand">
                      {action === 'edit' ?
                        <input value={item.name || ""} onChange={(e) => { apdateState(e, item) }} onBlur={() => { saveInput(item) }} /> :
                        <>{item.name}</>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttons">
            <button onClick={returnBack}>חזור</button>
            {action === 'browse' ? <button onClick={() => { setAction('edit') }}>עריכה</button> : <button onClick={() => { setAction('browse') }}>סיום עריכה</button>}
            <button onClick={addNewCustomTableItem}>הוספת שורה</button>
          </div>
        </>
      )}

    </StyledTable>
  )
}

export default CustomTables