import styled from '@emotion/styled'

export const StyledTables = styled.div`

  display: flex;
  flex-direction: column;
  gap: 25px;
  

  .title{
    text-align: center;
  }

  .tables{
    display: flex;
    gap: 5%;
  }

  @media only screen and (max-width: 900px) {
    /* CSS styles for mobile devices */

    .tables{
      flex-direction: column;
    }
  }
`