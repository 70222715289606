import { Typography } from "@mui/material";
import { StyledTables } from "./Tables.styled";
import SystemTable from "./system-tables/SystemTables";
import InnerTables from "./custom-tables/CustomTables";

function Tables() {
    return (
        <StyledTables>
            <Typography variant="body1" component="span">
                טבלאות:
            </Typography>
            <div className="tables">
                <SystemTable></SystemTable>
                <InnerTables></InnerTables>
            </div>
        </StyledTables>
    )
}

export default Tables