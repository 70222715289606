import { Button, MenuItem, Select, Skeleton, Typography } from "@mui/material"
import { StyledEditDocument } from "./EditDocument.styled"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { DocumentForm, DocumentFormSchema } from "../../../models/document";
import { useEffect, useState } from "react";
import { getDocumentsSettings } from "../../../services/documentsSettingsService";
import { DocumentSettings, DocumentSettingsSchema } from "../../../models/document-settings";
import { DocumentStructure } from "../../../models/document-structure";
import { getDocumentsStructure } from "../../../services/documentsStructureService";
import MuiInput from "../../../components/mui-input/MuiInput";
import { getDocumentById, getNextDocumentNumber } from "../../../services/documentsService";
import { DocumentItem, DocumentItemSchema } from "../../../models/document-item";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DocumentWork, DocumentWorkSchema } from "../../../models/document-work";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { DevTool } from '@hookform/devtools';
import { fixedNumber } from "../../../helpers/helpers";
// import { Account } from "../../../models/account";
// import { Car } from "../../../models/car";


//create an array schema for works
const WorksFormSchema = z.object({
    works: z.array(DocumentWorkSchema),
})

//create an array schema for items
const ItemsFormSchema = z.object({
    items: z.array(DocumentItemSchema),
})


function EditDocument() {

    const [documentsSettings, setDocumentsSettings] = useState<DocumentSettings[]>();
    const [documentsStructure, setDocumentsStructure] = useState<DocumentStructure[]>();
    const [settings, setSettings] = useState<DocumentSettings>();
    const [structure, setStructure] = useState<DocumentStructure[]>();
    const [documentNumber, setDocumentNumber] = useState<number>();
    const [workRowIndex, setWorkRowIndex] = useState<number>(0);
    const [itemRowIndex, setItemRowIndex] = useState<number>(0);
    const [focusedFieldName, setFocusedFieldName] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [documentData, setDocumentData] = useState(location.state);
    // console.log("location.state", location.state);
    // console.log("location.state?.documentTypeNumber", location.state?.documentTypeNumber);

    // ------------------- create forms -------------------------- //

    const documentForm = useForm<DocumentForm>({
        resolver: zodResolver(DocumentFormSchema),
        defaultValues: {
            total: 0,
            discount: 0,
            vat: 0,
            to_pay: 0,
            balance: 0,
            ...location.state
        }
    });



    const worksForm = useForm<{ works: DocumentWork[] }>({
        resolver: zodResolver(WorksFormSchema),
        defaultValues: {
            works: [
                {
                    hours: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });

    const itemsForm = useForm<{ items: DocumentItem[] }>({
        resolver: zodResolver(ItemsFormSchema),
        defaultValues: {
            items: [
                {
                    amount: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });


    const itemsFieldArray = useFieldArray({
        name: 'items',
        control: itemsForm.control
    });


    const worksFieldArray = useFieldArray({
        name: 'works',
        control: worksForm.control
    });


    // ------------------- use effects -------------------------- //


    //actions to run when component finished rendering
    useEffect(() => {

        //get documents settings
        if (!documentsSettings) {
            getDocumentsSettingsData();
        }

        //get documents fields structure
        if (!documentsStructure) {
            getDocumentsStructureData();
        }

        console.log("location.state ", location.state);

        if (!location.state) {
            getDocumentData(Number(id));
        }

        //get document number
        getDocumentNumber(1);

        // setDocumentCustomerAndCarData(location.state?.customer, location.state?.car);

        //set items rows data
        setItemRow();

        //set customer_id
        if (location.state?.customer) {
            console.log("set customer_id customer", location.state?.customer);
            documentForm.setValue('account_id', location.state?.customer.id);
        }

    }, []);


    //when user change document type: update document settings, fields structure, and document number
    useEffect(() => {
        const documentSetting = setDocumentSettings(documentsSettings, documentForm.getValues("document_settings_id"));
        setDocumentStructure(documentsStructure, documentSetting?.type_number);
        getDocumentNumber(documentSetting?.type_number);
    }, [documentForm.watch("document_settings_id")]);



    //update item line when one filed in the line changes.
    useEffect(() => {

        const subscription = itemsForm.watch((data, { name }) => {

            console.log("-----------------------------------------------");
            console.log("field changed: ", name);
            console.log("data: ", data);


            if (name) {

                setFocusedFieldName(name);

                const firstDotIndex = name.indexOf('.');
                const secondDotIndex = name.indexOf('.', firstDotIndex + 1);

                const rowIndex = Number(name.substring(firstDotIndex + 1, secondDotIndex));
                const fieldName = name?.slice(secondDotIndex + 1);

                console.log("fieldName", fieldName);

                const amount = itemsForm.getValues(`items.${rowIndex}.amount`) || 1;
                const price = itemsForm.getValues(`items.${rowIndex}.price`);
                const discount = itemsForm.getValues(`items.${rowIndex}.discount`);
                const net = itemsForm.getValues(`items.${rowIndex}.net`);
                const total = itemsForm.getValues(`items.${rowIndex}.total`);

                let updatedNet;
                let updatedPrice;

                console.log('itemsFieldArray before update', itemsFieldArray);

                switch (fieldName) {

                    case 'price': case 'discount':

                        updatedNet = price * (1 - ((discount || 0) / 100));

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            amount: amount,
                            net: fixedNumber(updatedNet),
                            total: fixedNumber(updatedNet * (amount || 1))
                        });
                        console.log("set net and total");

                        break;

                    case 'amount':

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            price: fixedNumber((net || 0) / (1 - ((discount || 0) / 100))),
                            total: fixedNumber((net || 0) * (amount || 1))
                        });
                        console.log("set total and price");

                        break;

                    case 'net':
                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            discount: fixedNumber((1 - ((net || 0) / price)) * 100),
                            total: fixedNumber((net || 0) * amount)
                        });
                        break;

                    case 'total':

                        updatedPrice = (total || 0) / ((amount || 1) * ((1 - ((discount || 0) / 100)) || 1));

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            price: fixedNumber(updatedPrice),
                            net: fixedNumber(updatedPrice * (1 - ((discount || 0) / 100)))
                        });
                        console.log("set price");

                        break;

                    default:
                        break;
                }

                itemsForm.setFocus(name);
                console.log('itemsFieldArray after update', itemsFieldArray);

            }
        });

        //calc the new payment section
        calcDocument();

        return () => subscription.unsubscribe();

    }, [itemsForm.watch()]);


    //update row line when one filed in the line changes.
    // useEffect(() => {

    //     const subscription = worksForm.watch((data, { name, type }) => {
    //         console.log("worksForm values changes", worksForm.getValues());

    //     });

    //     return () => subscription.unsubscribe();


    //     calcDocument();
    // }, [worksForm.watch()]);




    // ------------------- functions -------------------------- //


    // ------------------- data functions -------------------------- //


    //get documents settings and set the chosen document settings
    const getDocumentsSettingsData = async () => {
        try {
            const data = await getDocumentsSettings();
            setDocumentsSettings(data);
            setDocumentSettings(data, data?.[(location.state?.documentTypeNumber || 1) - 1].id);
        } catch (error) {
            console.error(error)
        }
    };

    //get documents fields structure and set the chosen document fields structure
    const getDocumentsStructureData = async () => {
        try {
            const data = await getDocumentsStructure();
            setDocumentsStructure(data);
            setDocumentStructure(data, location.state?.documentTypeNumber || 1);
        } catch (error) {
            console.error(error)
        }
    };

    //get document data for editing
    const getDocumentData = async (id: number) => {
        console.log("getDocumentData");
        try {
            const data = await getDocumentById(id, ["customer", "car", "works", "items"]);
            setDocumentData(data);
            console.log("getDocumentData data", data);

            documentForm.reset({
                ...data
            });

        } catch (error) {
            console.error(error)
        }
    }

    //find and set the settings of the current document type
    function setDocumentSettings(documentsSettings: DocumentSettings[] | undefined, documentSettingsId: number | undefined): DocumentSettings | undefined {

        if (!documentsSettings || !documentSettingsId) {
            return undefined;
        }

        const documentSettings = documentsSettings.find(documentsSetting => documentsSetting.id === documentSettingsId);
        const documentSettingsParse = DocumentSettingsSchema.parse(documentSettings);
        setSettings(documentSettingsParse);
        return documentSettingsParse;
    }

    //find and set the structure of the current document type
    function setDocumentStructure(documentsStructure: DocumentStructure[] | undefined, type_number: number | undefined): DocumentStructure[] | undefined {

        if (!documentsStructure || !type_number) {
            return undefined;
        }

        // const documentStructure = documentsStructure.filter(structure => structure.document_type_number === type_number);

        setStructure(documentsStructure);
        return documentsStructure;
    }

    //get the number of the new document by it's type.
    const getDocumentNumber = async (type_number: number | undefined) => {

        setDocumentNumber(undefined);

        if (!type_number) {
            return;
        }

        try {
            const data = await getNextDocumentNumber({
                document_settings: {
                    type_number: type_number
                }
            });

            setDocumentNumber(data);
        } catch (error) {
            console.error(error);
        }
    }

    // ------------------- actions functions -------------------------- //

    //set the customer and the car data in the forms.
    // function setDocumentCustomerAndCarData(customer?: Account, car?: Car) {


    //     if (customer) {
    //         const partialCustomer: Account = omit(customer, ['id', 'business_id', 'payment_type', 'custom_data', 'entries', 'created_at', 'updated_at']);
    //         documentForm.reset(
    //             // {
    //             //     total: 0,
    //             //     discount: 0,
    //             //     vat: 0,
    //             //     to_pay: 0,
    //             //     balance: 0,
    //             //     ...partialCustomer
    //             // }
    //             partialCustomer
    //         );
    //         console.log('setDocumentCustomerAndCarData partialCustomer: ', partialCustomer);
    //     }
    // }

    //add a new work line
    function addWorkLine() {

        worksFieldArray.append({
            hours: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setWorkRowIndex(itemsFieldArray.fields.length);

    }

    //add a new item line
    function addItemLine() {

        itemsFieldArray.append({
            amount: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setItemRowIndex(itemsFieldArray.fields.length);

    }

    //deleting a row by specify an index and row type
    function deleteRow(index: number, type: string): void {
        if (type === 'work') {
            worksFieldArray.remove(index);
        }
        else if (type === 'item') {
            itemsFieldArray.remove(index);
        }
    }


    //navigate to customer browsing
    function browseCustomer() {
        // navigate(`/customers`);
        navigate(`/customers`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null
            }
        });

    }

    //navigate to car browsing
    function browseCar() {
        // navigate(`/customers`);
        console.log("browseCar itemsForm.getValues().items", itemsForm.getValues().items);
        navigate(`/cars`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null
            }
        });

    }

    //navigate to work browsing
    function browseWork() {

        navigate(`/works`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,
                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex
            }
        });

    }

    //navigate to item browsing
    function browseItem() {

        navigate(`/items`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,
                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex
            }
        });

    }

    //set the items rows after selecting a new item
    function setItemRow() {

        const items: DocumentItem[] = location.state?.items;
        const chosenItem: DocumentItem = location.state?.chosenItem;
        const itemRowIndex: number = location.state?.itemRowIndex;


        items?.forEach((item, index) => {
            itemsFieldArray.update(index, item);
        });


        if (chosenItem) {
            itemsFieldArray.update(itemRowIndex, {
                ...chosenItem,
                amount: 1,
                discount: 0,
                net: chosenItem.price,
                total: chosenItem.price
            });
        }

    }



    //update the document payment section
    function calcDocument() {

        let totalPriceWorks = 0;
        let totalPriceItems = 0;

        worksFieldArray.fields.forEach(row => {
            totalPriceWorks += row.total;
        });

        itemsFieldArray.fields.forEach(row => {
            totalPriceItems += row.total;
        });

        const total = totalPriceWorks + totalPriceItems;
        const vat = (totalPriceWorks + totalPriceItems) * 0.17;

        documentForm.setValue('total', total);
        documentForm.setValue('vat', fixedNumber(vat));
        documentForm.setValue('to_pay', fixedNumber(vat + total));
    }


    //return the focus to the field that was changed
    function isFocused(baseName: string, inputName: string) {
        return `${baseName}${inputName}` === focusedFieldName;
    }


    //add a new document with all the forms data
    const saveDocument = async () => {
        // console.log("documentForm.getValues() ", documentForm.getValues());
        // console.log("itemsForm.getValues() ", itemsForm.getValues());
        // console.log("itemsForm.formState.errors ", itemsForm.formState.errors);
        // console.log("itemsForm.formState.errors?.items.0.price ", itemsForm.formState.errors?.items?0?.price);


        documentForm.setValue("document_number", documentNumber!);
        documentForm.setValue("type_number", settings!.type_number);
        documentForm.setValue("status", "פתוח");

        itemsForm.handleSubmit(onSubmitItems)();
        worksForm.handleSubmit(onSubmitWorks)();
        documentForm.handleSubmit(onSubmitDocument)();

        console.log("documentForm.getValues()", documentForm.getValues());

    };


    const onSubmitItems: SubmitHandler<{ items: DocumentItem[] }> = (data) => {
        console.log("onSubmitItems data: ", data);
    }

    const onSubmitWorks: SubmitHandler<{ works: DocumentWork[] }> = (data) => {
        console.log("onSubmitWorks data: ", data);
    }

    const onSubmitDocument: SubmitHandler<DocumentForm> = (data) => {
        console.log("onSubmitDocument data: ", data);
    }


    return (
        <StyledEditDocument>

            {documentsSettings && settings && documentsStructure && documentNumber && documentData ? <>
                <div className="title">
                    <div>
                        <Typography variant="h5" component={'span'} >
                            עריכת מסמך
                        </Typography>
                        <button>A</button>
                        <button>@</button>
                    </div>
                    <div>
                        אסמכתא: כרטיס עבודה 1
                    </div>

                </div>
                <div className="head">
                    <div className="select-document">
                        <Typography variant="body1" component="span">
                            בחירת מסמך:
                        </Typography>
                        <Controller

                            name={"document_settings_id"}
                            control={documentForm.control}
                            defaultValue={documentsSettings[(location.state?.documentTypeNumber || 1) - 1].id}

                            render={({ field: { onChange, value } }) => (
                                <Select
                                    value={value}
                                    onChange={onChange}
                                    id={"document_settings_id"}
                                    disabled={true}
                                >
                                    {
                                        documentsSettings.map((documentSettings, index) => (
                                            <MenuItem key={index} value={documentSettings.id}>{documentSettings.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            )}
                        />
                    </div>
                    <div className="document-number">
                        <Typography variant="body1" component="div">
                            מספר {documentNumber}
                        </Typography>
                    </div>
                    <div className="inputs">
                        {structure?.map(field => {
                            return field.type === "תאריך ושעה" ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired dateToNow /> : null
                        })}
                    </div>
                </div>
                <div className="details">
                    <div className="customer">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    פרטי לקוח:
                                </Typography>
                                <button>+</button>
                                <button onClick={browseCustomer}>@</button>
                                <button>e</button>
                            </div>
                            <div>
                                הגדרת שדות
                            </div>
                        </div>
                        <div className="inputs">
                            {structure?.map(field => {
                                return field.type === "לקוח-ספק" ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired /> : null
                            })}
                        </div>
                    </div>
                    <div className="car">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    פרטי רכב:
                                </Typography>
                                <button>+</button>
                                <button onClick={browseCar}>@</button>
                                <button>e</button>
                            </div>
                            <div>
                                הגדרת שדות
                            </div>
                        </div>
                        <div className="inputs">
                            {structure?.map(field => {
                                return field.type === "רכב" ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired /> : null
                            })}
                        </div>
                    </div>
                </div>
                {settings?.display_works &&
                    <div className="works">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    עבודות:
                                </Typography>
                                <button>+</button>
                                <button onClick={browseWork}>@</button>
                                <button>e</button>
                            </div>
                            <div>
                                הגדרת שדות
                            </div>
                        </div>
                        <table>
                            <tbody>
                                {
                                    worksFieldArray.fields.map((field, rowIndex) => (

                                        <tr key={field.id} onClick={() => { setWorkRowIndex(rowIndex) }}>
                                            <td className="row-number">{rowIndex + 1}.</td>
                                            {
                                                structure?.map(field => {
                                                    return field.type === "עבודה" ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={worksForm} showRequired /></td> : null
                                                })
                                            }
                                            <td><button onClick={() => { deleteRow(rowIndex, 'work') }}>X</button></td>
                                        </tr>

                                    ))
                                }
                            </tbody>
                        </table>
                        <button onClick={addWorkLine}>הוספת שורה</button>
                    </div>
                }

                <div className="items">
                    <div className="title">
                        <div>
                            <Typography variant="body1" component="span">
                                פריטים:
                            </Typography>
                            <button>+</button>
                            <button onClick={browseItem}>@</button>
                            <button>e</button>
                        </div>
                        <div>
                            הגדרת שדות
                        </div>
                    </div>
                    <table>
                        <tbody>
                            {
                                itemsFieldArray.fields.map((field, rowIndex) => (
                                    <tr key={field.id} onClick={() => { setItemRowIndex(rowIndex) }}>
                                        <td className="row-number">{rowIndex + 1}.</td>
                                        {
                                            structure?.map((field, fieldIndex) => {
                                                return field.type === "פריט" ? <td key={fieldIndex}><MuiInput field={field} variant='outlined' useForm={itemsForm} prefix={`items.${rowIndex}.`} showRequired autoFocus={isFocused(`items.${rowIndex}.`, field.field_name)} /></td> : null
                                            })
                                        }
                                        <td className="delete-row"><button onClick={() => { deleteRow(rowIndex, 'item') }}>X</button></td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <button onClick={addItemLine}>הוספת שורה</button>
                </div>
                <div className="payment">
                    <Typography variant="body1" component="div">
                        סה"כ כללי: {documentForm.getValues("total")}
                    </Typography>
                    <Typography variant="body1" component="div">
                        מע"מ: {documentForm.getValues("vat")}
                    </Typography>
                    <Typography variant="body1" component="div">
                        לתשלום: {documentForm.getValues("to_pay")}
                    </Typography>
                </div>
                <div className="buttons">
                    <Button onClick={saveDocument} variant='contained' color="primary">שמירה</Button>
                    <Button variant='outlined' color="primary">חזור</Button>
                </div>
            </>
                :
                <div className="loading">
                    <div className="head">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>
                    <div className="details">
                        <div className="customer">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                        </div>
                        <div className="car">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                        </div>
                    </div>
                    <div className="items">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>
                    <div className="payment">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>

                </div>
            }

            {/* <DevTool control={documentForm.control} /> */}

        </StyledEditDocument >
    )
}

export default EditDocument