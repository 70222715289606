import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groupBy } from '../../helpers/helpers';
import { AccountStructure } from '../../models/accounts-structure';
import { DocumentStructure } from '../../models/document-structure';

interface InitialState {
  customers: AccountStructure[] | undefined;
  items: AccountStructure[] | undefined;
  works: AccountStructure[] | undefined;
  cars: AccountStructure[] | undefined;
  incomes: AccountStructure[] | undefined;
  outcomes: AccountStructure[] | undefined;
  banks: AccountStructure[] | undefined;
  cashBoxes: AccountStructure[] | undefined;
  system: AccountStructure[] | undefined;
  document: DocumentStructure[] | undefined;
}

const initialState: InitialState = {
  customers: undefined,
  items: undefined,
  works: undefined,
  cars: undefined,
  incomes: undefined,
  outcomes: undefined,
  banks: undefined,
  cashBoxes: undefined,
  system: undefined,
  document: undefined,
}

export const accountsStructure = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    setAllAccountsStructureData: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      const groupsObject = groupBy(action.payload, 'account_type');
      setGroupsInState(groupsObject, state);
    },
    setCustomersStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.customers = action.payload
    },
    setItemsStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.items = action.payload
    },
    setWorksStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.works = action.payload
    },
    setCarsStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.cars = action.payload
    },
    setIncomesStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.incomes = action.payload
    },
    setOutcomesStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.outcomes = action.payload
    },
    setBanksStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.banks = action.payload
    },
    setCashBoxesStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.cashBoxes = action.payload
    },
    setSystemStructure: (state, action: PayloadAction<AccountStructure[] | undefined>) => {
      state.system = action.payload
    },
    setDocumentsStructure: (state, action: PayloadAction<DocumentStructure[] | undefined>) => {
      state.document = action.payload
    },
  },
})

interface GroupsObject{
  'לקוח-ספק'? : AccountStructure[];
  'פריט'? : AccountStructure[];
  'עבודה'? : AccountStructure[];
  'רכב'? : AccountStructure[];
  'הכנסה'? : AccountStructure[];
  'הוצאה'? : AccountStructure[];
  'קופה'? : AccountStructure[];
  'בנק'? : AccountStructure[];
  'מערכת'? : AccountStructure[];
}

function setGroupsInState(groupsObject: GroupsObject | undefined, state: InitialState) {

  state.customers = groupsObject?.["לקוח-ספק"];
  state.items = groupsObject?.["פריט"];
  state.works = groupsObject?.["עבודה"];
  state.cars = groupsObject?.["רכב"];
  state.incomes = groupsObject?.["הכנסה"];
  state.outcomes = groupsObject?.["הוצאה"];
  state.cashBoxes = groupsObject?.["קופה"];
  state.banks = groupsObject?.["בנק"];
  state.system = groupsObject?.["מערכת"];
}


// Action creators are generated for each case reducer function
export const {

  setAllAccountsStructureData,
  setCustomersStructure,
  setItemsStructure,
  setWorksStructure,
  setCarsStructure,
  setIncomesStructure,
  setOutcomesStructure,
  setBanksStructure,
  setCashBoxesStructure,
  setSystemStructure,
  setDocumentsStructure

} = accountsStructure.actions

export default accountsStructure.reducer