import styled from '@emotion/styled'

export const StyledAddSystem = styled.div`

    display: flex;
    flex-direction: column;
    gap: 15px;


    .fields{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: baseline;
    }

`