import styled from '@emotion/styled'

export const StyledLogin = styled.div`

    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

`