import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import dayjs from "dayjs";

z.setErrorMap(customErrorMap);

export const DocumentPaymentSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    document_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()).optional(),

    //inputs required
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    kupa_account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    account_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),

    //inputs optional
    bank_account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    bank_branch_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    bank_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    bank_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    check_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    to_date: z.preprocess((val) => myPreProcess(val, dayjs(val as string).format('YYYY-MM-DD')), z.string().nullish()),
    tax: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

});

export type DocumentPayment = z.infer<typeof DocumentPaymentSchema>