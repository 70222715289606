import { z } from "zod";


export const UserSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number(),
    role: z.string(),
    email_verified_at: z.date().optional(),
    remember_token: z.number().optional(),
    created_at: z.date().optional(),
    updated_at: z.date().optional(),


    //inputs required
    email: z.string().email("אימייל לא תקין"),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    password: z.string().min(8, "הסיסמה צריכה להכיל לפחות 8 תווים").optional(),


});

export type User = z.infer<typeof UserSchema>
