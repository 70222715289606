import { z } from "zod";
import { WorkCustomDataSchema } from "./work-custom-data";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);


export const WorkSchema = z.object({

        //from server (optional)
        id: z.number().optional(),
        business_id: z.number().optional(),
        created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
        updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


        //inputs required
        work_code: z.string().min(1),
        

        //inputs optional
        name: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
        info: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
        price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
        hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1).nullish()),
        manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        

        custom_data: z.preprocess((val) => myPreProcess(val), z.array(WorkCustomDataSchema).optional()),


});

export const WorkSearchSchema = z.object({


        //inputs required
        work_code: z.string().nullish(),
        

        //inputs optional
        name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
        info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
        price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
        

        custom_data: z.preprocess((val) => myPreProcess(val), z.array(WorkCustomDataSchema).optional()),


});

export type Work = z.infer<typeof WorkSchema>
export type WorkSearch = z.infer<typeof WorkSearchSchema>
