import { Button, MenuItem, Select, Skeleton, Tooltip, Typography } from "@mui/material"
import { StyledAddDocument } from "./AddDocument.styled"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { DocumentForm, DocumentFormSchema } from "../../../models/document";
import { useEffect, useState } from "react";
import { getDocumentsSettings } from "../../../services/documentsSettingsService";
import { DocumentSettings, DocumentSettingsSchema } from "../../../models/document-settings";
import { DocumentStructure } from "../../../models/document-structure";
import { getDocumentsStructure } from "../../../services/documentsStructureService";
import MuiInput from "../../../components/mui-input/MuiInput";
import { getNextDocumentNumber } from "../../../services/documentsService";
import { DocumentItem, DocumentItemSchema } from "../../../models/document-item";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DocumentWork, DocumentWorkSchema } from "../../../models/document-work";
import { useLocation, useNavigate } from "react-router-dom";
import { fixedNumber } from "../../../helpers/helpers";
import IconButton from '@mui/material/IconButton';
import CustomerAddIcon from '@mui/icons-material/PersonAdd';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { addDocument as addDocumentService } from "../../../services/documentsService";
import { toast } from 'react-toastify';
import { errorHandling } from "../../../services/errorHandling";
import { DocumentPayment, DocumentPaymentSchema } from "../../../models/document-payment";
import { paymentFieldsStructure } from "../../../local-data/localData";

//create an array schema for works
const WorksFormSchema = z.object({
    works: z.array(DocumentWorkSchema),
})

//create an array schema for items
const ItemsFormSchema = z.object({
    items: z.array(DocumentItemSchema),
})


function AddDocument() {

    const [documentsSettings, setDocumentsSettings] = useState<DocumentSettings[]>();
    const [documentsStructure, setDocumentsStructure] = useState<DocumentStructure[]>();
    const [settings, setSettings] = useState<DocumentSettings>();
    const [structure, setStructure] = useState<DocumentStructure[]>();
    const [documentNumber, setDocumentNumber] = useState<number>();
    const [workRowIndex, setWorkRowIndex] = useState<number>(0);
    const [itemRowIndex, setItemRowIndex] = useState<number>(0);
    const [focusedFieldName, setFocusedFieldName] = useState<string>();
    const navigate = useNavigate();
    const location = useLocation();

    console.log("location.state", location.state);
    // console.log("location.state?.documentTypeNumber", location.state?.documentTypeNumber);

    // ------------------- create forms -------------------------- //

    const documentForm = useForm<DocumentForm>({
        resolver: zodResolver(DocumentFormSchema),
        defaultValues: {
            total: 0,
            discount: 0,
            vat: 0,
            to_pay: 0,
            balance: 0,
            document_settings_id: location.state?.document_settings_id,
            customer: location.state?.customer || { ...location.state?.document?.customer, id: location.state?.document?.customer?.customer_id },
            car: location.state?.car || { ...location.state?.document?.car, id: location.state?.document?.car?.car_id },
            reference: location.state?.reference,
        }
    });



    const worksForm = useForm<{ works: DocumentWork[] }>({
        resolver: zodResolver(WorksFormSchema),
        defaultValues: {
            works: [
                {
                    hours: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });

    const itemsForm = useForm<{ items: DocumentItem[] }>({
        resolver: zodResolver(ItemsFormSchema),
        defaultValues: {
            items: [
                {
                    amount: 1,
                    price: 0,
                    discount: 0,
                    net: 0,
                    total: 0,
                }
            ]
        }
    });

    const paymentsForm = useForm<{ payments: DocumentPayment[] }>({
        resolver: zodResolver(DocumentPaymentSchema),
        defaultValues: {
            payments: [
                {
                    // amount: 1,
                    // price: 0,
                    // discount: 0,
                    // net: 0,
                    // total: 0,
                }
            ]
        }
    });


    const itemsFieldArray = useFieldArray({
        name: 'items',
        control: itemsForm.control
    });


    const worksFieldArray = useFieldArray({
        name: 'works',
        control: worksForm.control
    });

    const paymentsFieldArray = useFieldArray({
        name: 'payments',
        control: paymentsForm.control
    });


    // ------------------- use effects -------------------------- //


    //actions to run when component finished rendering
    useEffect(() => {

        //get documents settings
        if (!documentsSettings) {
            getDocumentsSettingsData();
        }

        //get documents fields structure
        if (!documentsStructure) {
            getDocumentsStructureData();
        }

        //get document number
        getDocumentNumber(1);

        // setDocumentCustomerAndCarData(location.state?.customer, location.state?.car);

        //set works rows data
        setWorkRow();

        //set items rows data
        setItemRow();



        //set customer_id
        if (location.state?.customer) {
            console.log("set customer_id customer", location.state?.customer);
            documentForm.setValue('account_id', location.state?.customer.id);
        }

        calcDocument();

    }, []);


    //when user change document type: update document settings, fields structure, and document number
    useEffect(() => {
        console.log("useEffect document_settings_id");

        console.log("documentForm.getValues(document_settings_id)", documentForm.getValues("document_settings_id"));

        const documentSetting = setDocumentSettings(documentsSettings, documentForm.getValues("document_settings_id"));
        // setDocumentStructure(documentsStructure, documentSetting?.type_number);
        setStructure(setDocumentStructureShowForm(documentsStructure, documentForm.getValues("document_settings_id")));

        getDocumentNumber(documentSetting?.type_number);
    }, [documentForm.watch("document_settings_id")]);   



    //update item line when one field in the line changes.
    useEffect(() => {

        const subscription = itemsForm.watch((data, { name }) => {

            console.log("-----------------------------------------------");
            console.log("field changed: ", name);
            console.log("data: ", data);


            if (name) {

                setFocusedFieldName(name);

                const firstDotIndex = name.indexOf('.');
                const secondDotIndex = name.indexOf('.', firstDotIndex + 1);

                const rowIndex = Number(name.substring(firstDotIndex + 1, secondDotIndex));
                const fieldName = name?.slice(secondDotIndex + 1);

                console.log("fieldName", fieldName);

                const amount = itemsForm.getValues(`items.${rowIndex}.amount`) || 1;
                const price = itemsForm.getValues(`items.${rowIndex}.price`);
                const discount = itemsForm.getValues(`items.${rowIndex}.discount`);
                const net = itemsForm.getValues(`items.${rowIndex}.net`);
                const total = itemsForm.getValues(`items.${rowIndex}.total`);

                let updatedNet;
                let updatedPrice;

                console.log('itemsFieldArray before update', itemsFieldArray);

                switch (fieldName) {

                    case 'price': case 'discount':

                        updatedNet = price * (1 - ((discount || 0) / 100));

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            amount: amount,
                            net: fixedNumber(updatedNet),
                            total: fixedNumber(updatedNet * (amount || 1))
                        });
                        console.log("set net and total");

                        break;

                    case 'amount':

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            price: fixedNumber((net || 0) / (1 - ((discount || 0) / 100))),
                            total: fixedNumber((net || 0) * (amount || 1))
                        });
                        console.log("set total and price");

                        break;

                    case 'net':
                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            discount: fixedNumber((1 - ((net || 0) / price)) * 100),
                            total: fixedNumber((net || 0) * amount)
                        });
                        break;

                    case 'total':

                        updatedPrice = (total || 0) / ((amount || 1) * ((1 - ((discount || 0) / 100)) || 1));

                        itemsFieldArray.update(rowIndex, {
                            ...itemsForm.getValues().items[rowIndex],
                            price: fixedNumber(updatedPrice),
                            net: fixedNumber(updatedPrice * (1 - ((discount || 0) / 100)))
                        });
                        console.log("set price");

                        break;

                    default:
                        break;
                }

                itemsForm.setFocus(name);
                console.log('itemsFieldArray after update', itemsFieldArray);

                //calc the new payment section
                calcDocument();
            }
        });



        return () => subscription.unsubscribe();

    }, [itemsForm.watch()]);


    //update work line when one field in the line changes.
    useEffect(() => {

        const subscription = worksForm.watch((data, { name }) => {

            console.log("-----------------------------------------------");
            console.log("field changed: ", name);
            console.log("data: ", data);


            if (name) {

                setFocusedFieldName(name);

                const firstDotIndex = name.indexOf('.');
                const secondDotIndex = name.indexOf('.', firstDotIndex + 1);

                const rowIndex = Number(name.substring(firstDotIndex + 1, secondDotIndex));
                const fieldName = name?.slice(secondDotIndex + 1);

                console.log("fieldName", fieldName);

                const hours = worksForm.getValues(`works.${rowIndex}.hours`) || 1;
                const price = worksForm.getValues(`works.${rowIndex}.price`);
                const discount = worksForm.getValues(`works.${rowIndex}.discount`);
                const net = worksForm.getValues(`works.${rowIndex}.net`);
                const total = worksForm.getValues(`works.${rowIndex}.total`);

                let updatedNet;
                let updatedPrice;

                console.log('itemsFieldArray before update', itemsFieldArray);

                switch (fieldName) {

                    case 'price': case 'discount':

                        updatedNet = price * (1 - ((discount || 0) / 100));

                        worksFieldArray.update(rowIndex, {
                            ...worksForm.getValues().works[rowIndex],
                            hours: hours,
                            net: fixedNumber(updatedNet),
                            total: fixedNumber(updatedNet * (hours || 1))
                        });
                        console.log("set net and total");

                        break;

                    case 'amount':

                        worksFieldArray.update(rowIndex, {
                            ...worksForm.getValues().works[rowIndex],
                            price: fixedNumber((net || 0) / (1 - ((discount || 0) / 100))),
                            total: fixedNumber((net || 0) * (hours || 1))
                        });
                        console.log("set total and price");

                        break;

                    case 'net':
                        worksFieldArray.update(rowIndex, {
                            ...worksForm.getValues().works[rowIndex],
                            discount: fixedNumber((1 - ((net || 0) / price)) * 100),
                            total: fixedNumber((net || 0) * hours)
                        });
                        break;

                    case 'total':

                        updatedPrice = (total || 0) / ((hours || 1) * ((1 - ((discount || 0) / 100)) || 1));

                        worksFieldArray.update(rowIndex, {
                            ...worksForm.getValues().works[rowIndex],
                            price: fixedNumber(updatedPrice),
                            net: fixedNumber(updatedPrice * (1 - ((discount || 0) / 100)))
                        });
                        console.log("set price");

                        break;

                    default:
                        break;
                }

                worksForm.setFocus(name);
                console.log('worksFieldArray after update', worksFieldArray);

                //calc the new payment section
                calcDocument();
            }
        });



        return () => subscription.unsubscribe();

    }, [worksForm.watch()]);



    // ------------------- functions -------------------------- //


    // ------------------- data functions -------------------------- //


    //get documents settings and set the chosen document settings
    const getDocumentsSettingsData = async () => {
        try {
            const data = await getDocumentsSettings();
            setDocumentsSettings(data);
            setDocumentSettings(data, location.state?.document_settings_id || data?.[(location.state?.documentTypeNumber || 1) - 1].id);
        } catch (error) {
            console.error(error)
        }
    };

    //get documents fields structure and set the chosen document fields structure
    const getDocumentsStructureData = async () => {
        try {
            let data = await getDocumentsStructure();
            setDocumentsStructure(data);

            // setStructure(data);
            setStructure(setDocumentStructureShowForm(data, documentForm.getValues("document_settings_id")));

            // setDocumentStructure(data, location.state?.documentTypeNumber || 1);
        } catch (error) {
            console.error(error)
        }
    };

    function setDocumentStructureShowForm(documentStructure: DocumentStructure[] | undefined, documentSettingsId: number | undefined) {

        if (!documentStructure) {
            return;
        }

        if (!documentSettingsId) {
            return documentStructure;
        }

        const documentStructureCopy = documentStructure.map(structure => ({
            ...structure,
            show_form_array: structure.show_form_array.map(showForm => ({ ...showForm }))
        }));

        documentStructureCopy.forEach(structure => {
            console.log('structure: ', structure);

            const showFormObj = structure.show_form_array.find(showForm => showForm.document_settings_id === documentSettingsId);
            structure.show_form = showFormObj ? showFormObj.show_form : false;
        });

        return documentStructureCopy;
    }

    //find and set the settings of the current document type
    function setDocumentSettings(documentsSettings: DocumentSettings[] | undefined, documentSettingsId: number | undefined): DocumentSettings | undefined {

        console.log("setDocumentSettings documentSettingsId: ", documentSettingsId);

        if (!documentsSettings || !documentSettingsId) {
            return undefined;
        }

        const documentSettings = documentsSettings.find(documentsSetting => documentsSetting.id === documentSettingsId);
        const documentSettingsParse = DocumentSettingsSchema.parse(documentSettings);
        setSettings(documentSettingsParse);
        return documentSettingsParse;
    }


    //get the number of the new document by it's type.
    const getDocumentNumber = async (type_number: number | undefined) => {

        setDocumentNumber(undefined);

        if (!type_number) {
            return;
        }

        try {
            const data = await getNextDocumentNumber({
                document_settings: {
                    type_number: type_number
                }
            });

            setDocumentNumber(data);
        } catch (error) {
            console.error(error);
        }
    }

    // ------------------- actions functions -------------------------- //



    //add a new work line
    function addWorkLine() {

        worksFieldArray.append({
            hours: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setWorkRowIndex(worksFieldArray.fields.length);

    }

    //add a new item line
    function addItemLine() {

        itemsFieldArray.append({
            amount: 1,
            price: 0,
            discount: 0,
            net: 0,
            total: 0,
        });

        setItemRowIndex(itemsFieldArray.fields.length);

    }

    //add a new payment line
    function addPaymentLine() {

        paymentsFieldArray.append({
            total: 0,
            kupa_account_id: 0,
            account_id: 0,

        });

        // setItemRowIndex(itemsFieldArray.fields.length);

    }

    //deleting a row by specify an index and row type
    function deleteRow(index: number, type: string): void {
        if (type === 'work') {
            worksFieldArray.remove(index);
        }
        else if (type === 'item') {
            setItemRowIndex(0);
            itemsFieldArray.remove(index);
        }
        else if (type === 'payment') {
            paymentsFieldArray.remove(index);
        }
    }


    //navigate to document browsing
    function browseDocument() {

        navigate(`/documents`, {
            replace: true,
            state: {
                // ...location.state,
                from: location.pathname,
                method: "browse",
                document_settings_id: documentForm.getValues("document_settings_id"),


                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });

    }

    //navigate to document (reference) browsing
    function browseReference() {

        navigate(`/documents`, {
            replace: true,
            state: {
                // ...location.state,
                from: location.pathname,
                method: "reference",
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });

    }

    function editDocumentSettings() {
        navigate(`/documents/settings/${documentForm.getValues("document_settings_id")}`, {
            replace: true, state: {

                from: location.pathname,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,
                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }

    //navigate to customer browsing
    function browseCustomer() {
        // navigate(`/customers`);
        navigate(`/customers`, {
            replace: true,
            state: {
                // ...location.state,
                from: location.pathname,
                // documentTypeNumber: settings?.type_number,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null,

                reference: documentForm.getValues().reference,
            }
        });

    }

    //navigate to car browsing
    function browseCar() {
        // navigate(`/customers`);
        console.log("browseCar itemsForm.getValues().items", itemsForm.getValues().items);
        navigate(`/cars`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,
                chosenWork: null,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,
                chosenItem: null,

                reference: documentForm.getValues().reference,
            }
        });

    }

    //navigate to work browsing
    function browseWork() {

        navigate(`/works`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,
                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,

                reference: documentForm.getValues().reference,
            }
        });

    }

    //navigate to item browsing
    function browseItem() {

        navigate(`/items`, {
            replace: true,
            state: {
                ...location.state,
                from: location.pathname,
                documentTypeNumber: settings?.type_number,
                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                workRowIndex: workRowIndex,

                items: itemsForm.getValues().items,
                itemRowIndex: itemRowIndex,

                reference: documentForm.getValues().reference,
            }
        });

    }

    //navigate to add customer
    function addCustomer() {
        // navigate(`/customers`);
        navigate(`/customers/add`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }

    //navigate to add car
    function addCar() {
        // navigate(`/customers`);
        navigate(`/cars/add`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,

            }
        });
    }

    //navigate to add work
    function addWork() {
        // navigate(`/customers`);
        navigate(`/works/add`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                work: worksForm.getValues().works[workRowIndex],
                workRowIndex: workRowIndex,

                reference: documentForm.getValues().reference,
            }
        });
    }

    //navigate to add item
    function addItem() {
        // navigate(`/customers`);
        navigate(`/items/add`, {
            replace: true,
            state: {
                // ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                document: location.state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                item: itemsForm.getValues().items[itemRowIndex],
                itemRowIndex: itemRowIndex,

                reference: documentForm.getValues().reference,
            }
        });
    }

    //navigate to add customer
    function editCustomer() {
        // navigate(`/customers`);
        // navigate(`/customers/${documentForm.getValues().customer?.id}`);
        navigate(`/customers/${documentForm.getValues().customer?.customer_id || documentForm.getValues().customer?.id}`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,

            }
        });
    }




    //navigate to add customer
    function editCar() {
        // navigate(`/customers`);
        navigate(`/cars/${documentForm.getValues().car?.car_id || documentForm.getValues().car?.id}`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,

            }
        });
    }





    //navigate to edit work
    function editWork() {
        // navigate(`/customers`);
        navigate(`/works/${worksForm.getValues().works[workRowIndex]?.work_id || worksForm.getValues().works[workRowIndex]?.id}`, {
            replace: true,
            state: {
                ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                work: worksForm.getValues().works[workRowIndex],
                workRowIndex: workRowIndex,

                reference: documentForm.getValues().reference,
            }
        });
    }







    //navigate to edit item
    function editItem() {
        // navigate(`/customers`);
        navigate(`/items/${itemsForm.getValues().items[itemRowIndex]?.item_id || itemsForm.getValues().items[itemRowIndex]?.id}`, {
            replace: true,
            state: {
                // ...location.state,
                from: `/documents/add`,
                document_settings_id: documentForm.getValues("document_settings_id"),

                document: location.state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                item: itemsForm.getValues().items[itemRowIndex],
                itemRowIndex: itemRowIndex,

                reference: documentForm.getValues().reference,
            }
        });
    }

    function editCustomerStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: location.pathname,
                fromType: 'form',
                structureName: "לקוח-ספק במסמך",
                structureType: 'document',
                documentType: 'לקוח-ספק',
                documntEnType: 'customer',
                // documentSettingsID: settings?.id,
                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: location.state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }

    function editCarStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: location.pathname,
                fromType: 'form',
                structureName: "רכב במסמך",
                structureType: 'document',
                documentType: 'רכב',
                documntEnType: 'car',
                // documentSettingsID: settings?.id,
                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: location.state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }

    function editWorkStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: location.pathname,
                fromType: 'form',
                structureName: "עבודה במסמך",
                structureType: 'document',
                documentType: 'עבודה',
                // documentSettingsID: settings?.id,
                documentName: settings?.name,

                document_settings_id: documentForm.getValues("document_settings_id"),

                document: location.state?.document,

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }

    function editItemStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: location.pathname,
                fromType: 'form',
                structureName: "פריט במסמך",
                structureType: 'document',
                documentType: 'פריט',
                // documentSettingsID: settings?.id,
                documentName: settings?.name,


                document_settings_id: documentForm.getValues("document_settings_id"),

                customer: documentForm.getValues().customer,
                car: documentForm.getValues().car,

                works: worksForm.getValues().works,
                items: itemsForm.getValues().items,

                reference: documentForm.getValues().reference,
            }
        });
    }



    //set the items rows after selecting a new item
    function setItemRow() {

        console.log("setItemRow");

        const items: DocumentItem[] = location.state?.items || (location.state?.document?.items as DocumentItem[])?.map(item => ({ ...item, id: item.item_id }));
        console.log("setItemRow location.state?.items: ", location.state?.items);
        console.log("setItemRow location.state?.document: ", location.state?.document);
        console.log("setItemRow location.state?.document?.items: ", location.state?.document?.items);
        console.log("setItemRow items: ", items);

        const chosenItem: DocumentItem = location.state?.chosenItem;
        const itemRowIndex: number = location.state?.itemRowIndex;


        items?.forEach((item, index) => {
            itemsFieldArray.update(index, item);
        });


        if (chosenItem) {
            itemsFieldArray.update(itemRowIndex, {
                ...chosenItem,
                amount: 1,
                discount: 0,
                net: chosenItem.price,
                total: chosenItem.price
            });
        }

    }
    //set the items rows after selecting a new item
    function setWorkRow() {

        if (!settings?.display_works) {
            return;
        }

        console.log("setWorkRow");

        const works: DocumentWork[] = location.state?.works || (location.state?.document?.works as DocumentWork[])?.map(work => ({ ...work, id: work.work_id }));
        console.log("works: ", works);
        const chosenWork: DocumentWork = location.state?.chosenWork;
        const workRowIndex: number = location.state?.workRowIndex;

        console.log("chosenWork", chosenWork);
        console.log("workRowIndex", workRowIndex);

        works?.forEach((work, index) => {
            worksFieldArray.update(index, work);
        });


        if (chosenWork) {
            console.log("inside if (chosenWork)");
            worksFieldArray.update(workRowIndex, {
                ...chosenWork,
                hours: chosenWork.hours || 1,
                discount: 0,
                net: chosenWork.price,
                total: chosenWork.price
            });
        }

    }



    //update the document payment section
    function calcDocument() {

        console.log("calcDocument");

        let totalPriceWorks = 0;
        let totalPriceItems = 0;

        worksForm.getValues().works.forEach(row => {
            totalPriceWorks += row.total;
        });

        itemsForm.getValues().items.forEach(row => {
            totalPriceItems += row.total;
        });

        const total = totalPriceWorks + totalPriceItems;
        const vat = (totalPriceWorks + totalPriceItems) * 0.17;

        documentForm.setValue('total', total);
        documentForm.setValue('vat', fixedNumber(vat));
        documentForm.setValue('to_pay', fixedNumber(vat + total));
    }


    //return the focus to the field that was changed
    function isFocused(baseName: string, inputName: string) {
        return `${baseName}${inputName}` === focusedFieldName;
    }


    //add a new document with all the forms data
    const addDocument = async () => {
        // console.log("documentForm.getValues() ", documentForm.getValues());
        // console.log("itemsForm.getValues() ", itemsForm.getValues());
        // console.log("itemsForm.formState.errors ", itemsForm.formState.errors);
        // console.log("itemsForm.formState.errors?.items.0.price ", itemsForm.formState.errors?.items?0?.price);

        documentForm.setValue("document_number", documentNumber!);
        documentForm.setValue("type_number", settings!.type_number);
        documentForm.setValue("status", "פתוח");

        itemsForm.handleSubmit(onSubmitItems)();
        worksForm.handleSubmit(onSubmitWorks)();
        documentForm.handleSubmit(onSubmitDocument)();

        console.log("documentForm.getValues()", documentForm.getValues());

    };


    const onSubmitItems: SubmitHandler<{ items: DocumentItem[] }> = (data) => {
        console.log("onSubmitItems data: ", data);
    }

    const onSubmitWorks: SubmitHandler<{ works: DocumentWork[] }> = (data) => {
        console.log("onSubmitWorks data: ", data);
    }

    const onSubmitDocument: SubmitHandler<DocumentForm> = (data) => {
        console.log("onSubmitDocument data: ", data);

        // const dataToSend = deleteEmptyFieldsFromObject(data);

        const addPromise = async () => {
            const response = await addDocumentService(data);
            console.log("added document", response);

            documentForm.reset();
            itemsForm.reset();
            worksForm.reset();
        }


        toast.promise(
            addPromise,
            {
                pending: 'מוסיף מסמך...',
                success: 'המסמך נוסף בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return errorHandling(data)
                    }
                }
            }
        )
    }




    return (
        <StyledAddDocument>

            {documentsSettings && settings && documentsStructure && documentNumber ? <>
                <div className="title">
                    <div>
                        <Typography variant="h5" component={'span'} >
                            הוספת מסמך
                        </Typography>
                        {/* <button>A</button> */}
                        <Tooltip title="טעינה של מסמך קיים מתוך דפדוף">
                            <IconButton onClick={browseDocument} sx={{ color: 'white' }}>
                                <AutoStoriesIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="צירוף אסמכתא">
                            <IconButton onClick={browseReference} sx={{ color: 'white' }}>
                                <FilePresentIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="הגדרות מסמך">
                            <IconButton onClick={editDocumentSettings} sx={{ color: 'white' }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        {/* <button onClick={documentSettings}>הגדרות מסמך</button> */}
                    </div>
                    <div>
                        {/* אסמכתא: כרטיס עבודה 1 */}
                        {documentForm.getValues("reference") ? "אסמכתא: " + documentForm.getValues("reference") : ""}
                    </div>

                </div>
                <div className="head">
                    <div className="select-document">
                        <Typography variant="body1" component="span">
                            בחירת מסמך:
                        </Typography>
                        <Controller

                            name={"document_settings_id"}
                            control={documentForm.control}
                            defaultValue={documentsSettings[(location.state?.documentTypeNumber || 1) - 1].id}

                            render={({ field: { onChange, value } }) => (
                                <Select
                                    value={value}
                                    onChange={onChange}
                                    id={"document_settings_id"}
                                >
                                    {
                                        documentsSettings.map((documentSettings, index) => (
                                            <MenuItem key={index} value={documentSettings.id}>{documentSettings.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            )}
                        />
                    </div>
                    <div className="document-number">
                        <Typography variant="body1" component="div">
                            מספר {documentNumber}
                        </Typography>
                    </div>
                    <div className="inputs">
                        {structure?.map(field => {
                            return field.type === "תאריך ושעה" ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired dateToNow /> : null
                        })}
                    </div>
                </div>
                <div className="details">
                    <div className="customer">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    פרטי לקוח:
                                </Typography>

                                <Tooltip title="בחירת כרטיס לקוח-ספק מתוך דפדוף" placement="top">
                                    <IconButton onClick={browseCustomer} sx={{ color: 'white' }}>
                                        <AutoStoriesIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="הוספת כרטיס לקוח-ספק" placement="top">
                                    <IconButton onClick={addCustomer} disabled={location.state?.customer?.id || location.state?.document?.customer?.customer_id} sx={{ color: 'white' }}>
                                        <CustomerAddIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="עריכת כרטיס לקוח-ספק" placement="top">
                                    <IconButton onClick={editCustomer} disabled={!location.state?.customer?.id && !location.state?.document?.customer?.customer_id} sx={{ color: 'white' }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                {/* <button onClick={addCustomer} disabled={!!documentForm.getValues().customer?.id}>+</button> */}
                                {/* <button onClick={browseCustomer}>@</button> */}
                                {/* <button onClick={editCustomer} disabled={!documentForm.getValues().customer?.id}>e</button> */}
                            </div>
                            <div>
                                <Tooltip title="הגדרת שדות לקוח-ספק" placement="top">
                                    <IconButton onClick={editCustomerStructure} sx={{ color: 'white' }}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <button onClick={editCustomerStructure}>הגדרת שדות</button> */}
                            </div>
                        </div>
                        <div className="inputs">
                            {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                return (field.field_name === "customer.account_number" && location.state?.reference) ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired disabled /> :
                                    (field.type === "לקוח-ספק" && field.show_form) ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired /> : null
                            })}

                        </div>
                    </div>
                    {settings?.display_items &&
                        <div className="car">
                            <div className="title">
                                <div>
                                    <Typography variant="body1" component="span">
                                        פרטי רכב:
                                    </Typography>
                                    <Tooltip title="בחירת כרטיס רכב מתוך דפדוף" placement="top">
                                        <IconButton onClick={browseCar} sx={{ color: 'white' }}>
                                            <AutoStoriesIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="הוספת כרטיס רכב" placement="top">
                                        <IconButton onClick={addCar} disabled={!!documentForm.getValues().car?.id} sx={{ color: 'white' }}>
                                            <BookmarkAddIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="עריכת כרטיס רכב" placement="top">
                                        <IconButton onClick={editCar} disabled={!documentForm.getValues().car?.id} sx={{ color: 'white' }}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>

                                    {/* <button onClick={addCar} disabled={!!documentForm.getValues().car?.id}>+</button> */}
                                    {/* <button onClick={browseCar}>@</button> */}
                                    {/* <button onClick={editCar} disabled={!documentForm.getValues().car?.id}>e</button> */}
                                </div>
                                <div>
                                    <Tooltip title="הגדרת שדות רכב" placement="top">
                                        <IconButton onClick={editCarStructure} sx={{ color: 'white' }}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <button onClick={editCarStructure}>הגדרת שדות</button> */}
                                </div>
                            </div>
                            <div className="inputs">
                                {structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                    return (field.type === "רכב" && field.show_form) ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={documentForm} showRequired /> : null
                                })}
                            </div>
                        </div>
                    }
                </div>

                {settings?.display_works &&
                    <div className="works">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    עבודות:
                                </Typography>
                                <Tooltip title="בחירת כרטיס עבודה מתוך דפדוף" placement="top">
                                    <IconButton onClick={browseWork} sx={{ color: 'white' }}>
                                        <AutoStoriesIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="הוספת כרטיס עבודה" placement="top">
                                    <IconButton onClick={addWork} disabled={!!worksForm.getValues().works?.[workRowIndex]?.id} sx={{ color: 'white' }}>
                                        <BookmarkAddIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="עריכת כרטיס עבודה" placement="top">
                                    <IconButton onClick={editWork} disabled={!worksForm.getValues().works?.[workRowIndex]?.id} sx={{ color: 'white' }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                {/* <button onClick={addWork} disabled={!!worksForm.getValues().works[workRowIndex]?.id}>+</button> */}
                                {/* <button onClick={browseWork}>@</button> */}
                                {/* <button onClick={editWork} disabled={!worksForm.getValues().works[workRowIndex]?.id}>e</button> */}
                            </div>
                            <div>
                                <Tooltip title="הגדרת שדות עבודה" placement="top">
                                    <IconButton onClick={editWorkStructure} sx={{ color: 'white' }}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <button onClick={editWorkStructure}>הגדרת שדות</button> */}
                            </div>
                        </div>
                        <table>
                            <tbody>
                                {
                                    worksFieldArray.fields.map((field, rowIndex) => (

                                        <tr key={field.id} onClick={() => { setWorkRowIndex(rowIndex) }}>
                                            <td className="row-number">{rowIndex + 1}.</td>
                                            {
                                                structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                                    return (field.type === "עבודה" && field.show_form) ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={worksForm} prefix={`works.${rowIndex}.`} showRequired autoFocus={isFocused(`works.${rowIndex}.`, field.field_name)} /></td> : null
                                                })
                                            }
                                            <td><button onClick={() => { deleteRow(rowIndex, 'work') }}>X</button></td>
                                        </tr>

                                    ))
                                }
                            </tbody>
                        </table>
                        <button onClick={addWorkLine}>הוספת שורה</button>
                    </div>
                }

                {settings?.display_items &&
                    <div className="items">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    פריטים:
                                </Typography>
                                <Tooltip title="בחירת כרטיס פריט מתוך דפדוף" placement="top">
                                    <IconButton onClick={browseItem} sx={{ color: 'white' }}>
                                        <AutoStoriesIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="הוספת כרטיס פריט" placement="top">
                                    <IconButton onClick={addItem} disabled={!!itemsForm.getValues().items?.[itemRowIndex]?.id} sx={{ color: 'white' }}>
                                        <BookmarkAddIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="עריכת כרטיס פריט" placement="top">
                                    <IconButton onClick={editItem} disabled={!itemsForm.getValues().items?.[itemRowIndex]?.id} sx={{ color: 'white' }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>

                                {/* <button onClick={addItem} disabled={!!itemsForm.getValues().items[itemRowIndex]?.id}>+</button>
                        <button onClick={browseItem}>@</button>
                        <button onClick={editItem} disabled={!itemsForm.getValues().items[itemRowIndex]?.id}>e</button> */}
                            </div>
                            <div>
                                <Tooltip title="הגדרת שדות פריט" placement="top">
                                    <IconButton onClick={editItemStructure} sx={{ color: 'white' }}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <button onClick={editItemStructure}>הגדרת שדות</button> */}
                            </div>
                        </div>
                        <table>
                            <tbody>
                                {
                                    itemsFieldArray.fields.map((field, rowIndex) => (
                                        <tr key={field.id} onClick={() => { setItemRowIndex(rowIndex) }}>
                                            <td className="row-number">{rowIndex + 1}.</td>
                                            {
                                                structure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map((field, fieldIndex) => {
                                                    return (field.type === "פריט" && field.show_form) ? <td key={fieldIndex}><MuiInput field={field} variant='outlined' useForm={itemsForm} prefix={`items.${rowIndex}.`} showRequired autoFocus={isFocused(`items.${rowIndex}.`, field.field_name)} /></td> : null
                                                })
                                            }
                                            <td className="delete-row"><button onClick={() => { deleteRow(rowIndex, 'item') }}>X</button></td>

                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <button onClick={addItemLine}>הוספת שורה</button>
                    </div>
                }



                {settings?.display_payments &&
                    <div className="payment">
                        <div className="title">
                            <div>
                                <Typography variant="body1" component="span">
                                    תשלום:
                                </Typography>
                            </div>
                            {/* <div>
                                <Tooltip title="הגדרת שדות עבודה" placement="top">
                                    <IconButton onClick={editWorkStructure} sx={{ color: 'white' }}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Tooltip>
                            </div> */}
                        </div>
                        <table>
                            <tbody>
                                {
                                    paymentsFieldArray.fields.map((field, rowIndex) => (

                                        <tr key={field.id}>
                                            <td className="row-number">{rowIndex + 1}.</td>
                                            {
                                                paymentFieldsStructure?.sort((a, b) => (a.location || 0) - (b.location || 0)).map(field => {
                                                    return (field.type === "תשלום") ? <td key={field.field_name}><MuiInput field={field} variant='outlined' useForm={paymentsForm} prefix={`payments.${rowIndex}.`} showRequired autoFocus={isFocused(`payments.${rowIndex}.`, field.field_name)} /></td> : null
                                                })
                                            }
                                            <td><button onClick={() => { deleteRow(rowIndex, 'payment') }}>X</button></td>
                                        </tr>

                                    ))
                                }
                            </tbody>
                        </table>
                        <button onClick={addPaymentLine}>הוספת שורה</button>
                    </div>
                }




                <div className="payment">
                    <Typography variant="body1" component="div">
                        סה"כ כללי: {documentForm.getValues("total").toFixed(2)}
                    </Typography>
                    <Typography variant="body1" component="div">
                        מע"מ: {documentForm.getValues("vat").toFixed(2)}
                    </Typography>
                    <Typography variant="body1" component="div">
                        לתשלום: {documentForm.getValues("to_pay").toFixed(2)}
                    </Typography>
                </div>
                <div className="buttons">
                    <Button onClick={addDocument} variant='contained' color="primary">שמירה</Button>
                    <Button variant='outlined' color="primary">חזור</Button>
                </div>
            </>
                :
                <div className="loading">
                    <div className="head">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>
                    <div className="details">
                        <div className="customer">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                        </div>
                        <div className="car">
                            <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                        </div>
                    </div>
                    <div className="items">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>
                    <div className="payment">
                        <Skeleton variant="rectangular" height={100} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} />
                    </div>

                </div>
            }

            {/* <DevTool control={documentForm.control} /> */}

        </StyledAddDocument >
    )
}

export default AddDocument