import { ReactNode, createContext, useState } from "react";
import { Item } from "../models/item";

// Define the AccountContextType interface
interface ItemContextType {
    item: Item | null;
    setItem: (item: Item) => void;
}

// Create the AccountContext with an initial value
export const ItemContext = createContext<ItemContextType>({
    item: null,
    setItem: () => {},
});

// Define the AccountProvider component
export const ItemProvider: React.FC<{ children: ReactNode; item: Item }> = ({ children, item }) => {
    const [currentItem, setCurrentItem] = useState<Item>(item);

    return (
        <ItemContext.Provider value={{ item: currentItem, setItem: setCurrentItem }}>
            {children}
        </ItemContext.Provider>
    );
};