import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentWorkCustomDataSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    document_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()).optional(),
    
    //inputs optional
    work_code: z.string().optional().nullish(),
    work_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    document_work_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    field_name: z.string().optional(),
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),


});

export type DocumentWork = z.infer<typeof DocumentWorkCustomDataSchema>