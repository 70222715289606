import styled from '@emotion/styled'

export const StyledHistory = styled.div`

    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: ${({theme})=> theme.colors.section_color};

    .container{
        padding: 15px;
    }

    .top{
        margin: 10px 0;
        div{
            display: flex;
            align-items: baseline;
            gap: 15px;
        }
    }


`