import styled from '@emotion/styled'

export const StyledFieldsStructure = styled.div`

    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    
    .head, .table{
        padding: 15px;
        background-color: ${({theme})=> theme.colors.section_color};
        border-radius: 15px;
        text-align: center;
    }
    

    table{
        width: 100%;
    }

    .buttons{
        display: flex;
        gap: 15px;
    }

`