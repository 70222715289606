import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';

z.setErrorMap(customErrorMap);

export const AccountStructureSchema = z.object({


    //from server (optional)
    id: z.number(),
    business_id: z.number(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    //client && server
    account_type: z.string(),
    account_title: z.string(),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string().min(1),
    field_heb_name: z.string(),
    custom_field: z.number().nullable(),

    //inputs required

    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()),
    field_length: z.preprocess((val) => myPreProcess(val), z.number()),
    field_type: z.preprocess((val) => myPreProcess(val), z.number()),
    field_table: z.preprocess((val) => myPreProcess(val), z.number()),


    //inputs optional
    location: z.preprocess((val) => myPreProcess(val), z.number().nullable()),

});

export const AccountStructureFormSchema = z.object({


    //client && server
    account_type: z.string(),
    account_title: z.string(),
    required: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_name: z.string().min(1),
    field_heb_name: z.string(),
    custom_field: z.number().nullish(),

    //inputs required

    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_search: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    show_browse: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),
    field_display_name: z.preprocess((val) => myPreProcess(val), z.string()),
    field_length: z.preprocess((val) => myPreProcess(val), z.number()),
    field_type: z.preprocess((val) => myPreProcess(val), z.number()),
    field_table: z.preprocess((val) => myPreProcess(val), z.number()),


    //inputs optional
    location: z.preprocess((val) => myPreProcess(val), z.number().nullish()),


});

export type AccountStructure = z.infer<typeof AccountStructureSchema>
export type AccountStructureForm = z.infer<typeof AccountStructureFormSchema>

