import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { StyledEditItem } from "./EditItem.styled";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { deleteEmptyFieldsFromObject, groupBy } from "../../../../helpers/helpers";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setItemsStructure } from "../../../../redux/features/structure";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { errorHandling } from "../../../../services/errorHandling";
import AlertDialog from "../../../../components/alert-dialog/AlertDialog";
import { ItemContext } from "../../../../utils/ItemContext";
import { Item, ItemSchema } from "../../../../models/item";
import { deleteItem, saveItem } from "../../../../services/itemsService";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';

interface customData {
  field_name: string,
  field_value: string
}

interface FormData {
  [key: string]: string; // Index signature allowing any string key with string values
}


function EditItem() {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const itemStructure = useSelector((state: RootState) => state.structure.items);
  const structureSortedByTitle = groupBy(itemStructure, "account_title")
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure => !!structure.show_form === true);

  const { item, setItem } = useContext(ItemContext);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const form = useForm<Item>({
    resolver: zodResolver(ItemSchema),
    defaultValues: {
      ...deleteEmptyFieldsFromObject(item)
    }
  });


  useEffect(() => {
    if (!itemStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);

  useEffect(() => {
    if (item) {
      setFormValues(item);
    }
  }, [item]);


  function setFormValues(item: Item) {
    Object.keys(item).forEach(key => {
      form.setValue(key as keyof Item, item[key as keyof Item]);
    });
  }


  async function getStructureAndSaveAtStore() {
    try {

      const structure = await getAccountsStructure({
        account_type: "פריט"
      });

      dispatch(setItemsStructure(structure || []));

    } catch (error) {
      console.error(error);
    }
  }

  const saveTicket = async () => {

    try {

      const customDataObj = filterCustomKeys(form.getValues());
      const customDataArray = returnArrayOfCustomData(customDataObj as FormData).filter(obj => obj.field_value !== "");
      form.setValue("custom_data", customDataArray);

      form.handleSubmit(onSubmit)();

    } catch (error) {
      console.error("Form submission failed:", error);
    }

  }

  function filterCustomKeys<T extends object>(inputObject: T): Partial<T> {
    const result: Partial<T> = {};

    for (const key in inputObject) {
      if (Object.prototype.hasOwnProperty.call(inputObject, key) && key.includes("custom") && key !== "custom_data") {
        result[key] = inputObject[key];
      }
    }

    return result;
  }

  function returnArrayOfCustomData(formData: FormData): customData[] {

    const array: customData[] = Object.keys(formData).map(key => ({
      field_name: key,
      field_value: formData[key]
    }));

    return array;
  }


  const onSubmit: SubmitHandler<Item> = (data) => {

    // setItem(data);

    const dataToSend = deleteEmptyFieldsFromObject(data);

    const savePromise = async () => {
      const savedData = await saveItem(dataToSend.id!, dataToSend);

      setItem(savedData);

      if (location.state?.from === "/documents/add") {
        navigate(location.state.from, {
          replace: true,
          state: {
            ...location.state,
            from: `/documents/add`,
            document_settings_id: location.state.document_settings_id,

            customer: location.state.customer,
            car: location.state.car,

            works: location.state.works,
            items: location.state.items,

            chosenItem: savedData,
            itemRowIndex: location.state.itemRowIndex,
          }
        });
      }
    }


    toast.promise(
      savePromise,
      {
        pending: 'מעדכן כרטיס...',
        success: 'הכרטיס עודכן בהצלחה',
        error: {
          render({ data }) {
            return errorHandling(data)
          }
        }
      }
    )
  };

  function returnFunc() {
    if (location.state?.from === "/documents/add") {
      navigate(location.state.from, {
        replace: true,
        state: {
          ...location.state,
          // from: `/documents/add`,
          // document_settings_id: location.state.document_settings_id,

          // works: location.state.works,
          // items: location.state.items,

          // customer: location.state.customer,
          // car: location.state.car,
        }
      });
    }

    else {
      window.history.back();
    }

  }

  const onDelete = async () => {

    const deletePromise = async () => {
      if (item) {
        await deleteItem(item.id!);
        window.history.back();
      }
    }

    toast.promise(
      deletePromise,
      {
        pending: 'מוחק כרטיס...',
        success: 'הכרטיס נמחק בהצלחה',
        error: {
          render({ data }) {
            // When the promise reject, data will contains the error
            return errorHandling(data)
          }
        }
      }
    )
  };

  function setStructure() {
    navigate('/structure', {
      replace: true, state: {
        from: location.pathname,
        fromType: 'form',
        structureName: "פריט",
        structureType: 'account',
        accountType: "items"
      }
    });
  }

  return (
    <StyledEditItem>
      {!itemStructure ? <Skeleton variant="rectangular" height={500} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} /> :
        <>

          <div className="form">
            <div><div className="left">
              {/* <button onClick={setStructure}>הגדרת שדות</button> */}
              <Tooltip title="הגדרת שדות">
                <IconButton onClick={setStructure} sx={{ color: 'white' }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </div></div>

            <div>
              <Typography variant="body1">פרטים:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["פרטים"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">יצרן ודגם:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["יצרן ודגם"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              <Typography variant="body1">הכנסות והוצאות:</Typography>
              <div className="fields">
                {structureSortedByTitle?.["הכנסות והוצאות"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

            <div>
              {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
              <div className="fields">
                {structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                  return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={form} showRequired blockNotEditable /> : null
                })}
              </div>
            </div>

          </div>

          <div className="buttons">
            <div className="right">
              <Button variant="contained" onClick={saveTicket} disabled={form.formState.isSubmitting} >שמירה</Button>
              <Button variant="outlined" onClick={returnFunc}>חזור</Button>
              <Button variant="outlined" color="error" onClick={() => setDialogIsOpen(true)} disabled={item?.entries?.length ? true : false} >מחיקה</Button>
            </div>
            <div className="left">
            </div>
          </div>

        </>
      }
      <AlertDialog
        open={dialogIsOpen}
        handleClose={() => { setDialogIsOpen(false) }}
        handleConfirm={onDelete}
        title="אישור מחיקה"
        message="אתה בטוח שאתה רוצה למחוק כרטיס זה?"
        confirm="מחיקה"
      />
    </StyledEditItem>
  )
}


export default EditItem