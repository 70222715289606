import { Tab, TabProps, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Car as CarModel, CarSchema } from "../../../models/car";
import { getCarById } from "../../../services/carsService";
import { CarProvider } from "../../../utils/CarContext";

interface LinkTabProps extends TabProps {
  path: string;
  selected?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component={RouterLink}
      to={props.path}
      label={props.label}
      aria-current={props.selected ? 'page' : undefined}
      {...props}
    />
  );
}

// Define an array of tab hrefs
const tabsData = [
  {
    label: "פרטי פריט",
    path: "",
  },
  {
    label: "תנועות",
    path: "journal-entries",
  },
];


function Car() {

  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = tabsData.findIndex(tab => tab.path !== "" && location.pathname.includes(tab.path));
  const value = currentIndex !== -1 ? currentIndex : 0;

 
  const [car, setCar] = useState<CarModel | undefined>();
  const { id } = useParams();


  useEffect(() => {
    if(location.state?.selectedTicket){
      setCar(location.state.selectedTicket);
      navigate(location.pathname, { replace: true, state: null });
    }

    else{
      getCar();
    }
  }, []);

  const getCar = async () => {
    try {
      if (id) {
        const car = await getCarById(id);
        const carParse = CarSchema.parse(car);
        setCar(carParse);
      }
    } catch (error) {
      console.error(error);
    }
  }

  {
    return car && 
    <>
      <div>
        <Typography variant="h5" component="span">
          כרטיס רכב
        </Typography>

        {/* <span>מס כרטיס: {item?.card_number}</span> */}
        <span>מס כרטיס: {car?.id}</span>
      </div>

      <Tabs
        dir='ltr'
        value={value}
        role="navigation"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        style={{ direction: "rtl", flexDirection: "row-reverse", backgroundColor: "rgb(35, 48, 68)", padding: 5, marginTop: 20 }}

      >
        {tabsData.map((tab, index) => (
          <LinkTab key={index} label={tab.label} path={tab.path} />
        ))}

      </Tabs>
      <CarProvider car={car}>
        <Outlet />
      </CarProvider>
    </>
  }
}

export default Car