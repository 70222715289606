import { ReactNode, createContext, useState } from "react";
import { Car } from "../models/car";

// Define the AccountContextType interface
interface CarContextType {
    car: Car | null;
    setCar: (car: Car) => void;
}

// Create the AccountContext with an initial value
export const CarContext = createContext<CarContextType>({
    car: null,
    setCar: () => {},
});

// Define the AccountProvider component
export const CarProvider: React.FC<{ children: ReactNode; car: Car }> = ({ children, car }) => {
    const [currentCar, setCurrentCar] = useState<Car>(car);

    return (
        <CarContext.Provider value={{ car: currentCar, setCar: setCurrentCar }}>
            {children}
        </CarContext.Provider>
    );
};