import styled from '@emotion/styled'

export const StyledAddTicket = styled.div`


    display: flex;
    flex-direction: column;
    gap: 20px;

    .choose-ticket-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${({theme})=> theme.colors.section_color};
        padding: 15px;
        border-bottom: 1px solid rgba(138, 140, 144, 0.1);
    }

    .form{
        padding: 15px;
        background-color: ${({theme})=> theme.colors.section_color};
    }

    .buttons{
        display: flex;
        gap: 20px;
    }

`