import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";
import { DocumentWorkCustomDataSchema } from "./document-work-custom-data";

z.setErrorMap(customErrorMap);

export const DocumentWorkSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    work_id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    document_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()).optional(),

    //inputs required
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    hours: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    //inputs optional
    work_code: z.string().optional().nullish(),
    work_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),

    custom_data: z.preprocess((val) => myPreProcess(val), z.array(DocumentWorkCustomDataSchema).optional()),

});

export type DocumentWork = z.infer<typeof DocumentWorkSchema>