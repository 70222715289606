import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentSettingsSchema = z.object({
     
     //from server (optional)
     id: z.number().optional(),
     business_id: z.number().optional(),
     created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
     updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

     //client && server
     type_number: z.number(),


    //inputs optional
     name: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
     short_name: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
     display_car: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_works: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_items: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_payments: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     circle: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     accounting: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),

});

export const DocumentSettingsFormSchema = z.object({
        
    //inputs optional
     name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     short_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     display_works: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_payment: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     circle: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     accounting: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),

});

export const DocumentSettingsSearchSchema = z.object({
     

     //client && server
     type_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),


    //inputs optional
     name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     short_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
     display_works: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     display_payment: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     circle: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     accounting: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),
     editable: z.preprocess((val) => myPreProcess(val, !!val), z.boolean().nullish()),

});

export type DocumentSettings = z.infer<typeof DocumentSettingsSchema>
export type DocumentSettingsForm = z.infer<typeof DocumentSettingsFormSchema>
export type DocumentSettingsSearch = z.infer<typeof DocumentSettingsSchema>