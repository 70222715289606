import axiosClient from "../axios-client";
import { DocumentStructure, DocumentStructureForm } from "../models/document-structure";

export const getDocumentsStructure = async (body = {}): Promise<DocumentStructure[] | undefined> => {
    try {
        const response = await axiosClient.post(`/api/documents-structure/search`, body);
        return response.data as DocumentStructure[];
    } catch (error) {
        console.error(error);
    }

}


export const editDocumentStructure = async (documentStructureToUpdate: DocumentStructure): Promise<DocumentStructure> => {
    const response = await axiosClient.patch(`api/documents-structure/${documentStructureToUpdate.id}`, documentStructureToUpdate);
    return response.data;
}

export const addCustomDocumentStructure = async (body: DocumentStructureForm): Promise<DocumentStructure> => {
    const response = await axiosClient.post(`api/documents-structure`, body);
    return response.data;
}