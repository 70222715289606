import { useLocation, useNavigate } from "react-router-dom";
import { StyledFieldsStructure } from "./FieldsStructure.styled"
import { useEffect, useState } from "react";
import { AccountStructure, AccountStructureSchema } from "../../models/accounts-structure";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getSystemTables } from "../../services/tablesService";
import { Button, Checkbox, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";
import { compareObjects, updateArrayById } from "../../helpers/helpers";
import { addCustomAccountStructure, editAccountStructure, getAccountsStructure } from "../../services/accountsStructureService";
import { toast } from 'react-toastify';
import { setBanksStructure, setCarsStructure, setCashBoxesStructure, setCustomersStructure, setDocumentsStructure, setIncomesStructure, setItemsStructure, setOutcomesStructure, setSystemStructure, setWorksStructure } from "../../redux/features/structure";
import { DocumentStructure, DocumentStructureSchema } from "../../models/document-structure";
import { addCustomDocumentStructure, editDocumentStructure, getDocumentsStructure } from "../../services/documentsStructureService";

type SelectTable = {
    value: number,
    name: string
}

const inputTypes: SelectTable[] = [
    {
        value: 1,
        name: 'עברי'
    },
    {
        value: 2,
        name: 'אנגלי'
    },
    {
        value: 3,
        name: 'תאריך'
    },
    {
        value: 4,
        name: 'טבלה נומרי'
    },
    {
        value: 5,
        name: 'טבלה'
    },
    {
        value: 6,
        name: 'דגם'
    },
    {
        value: 7,
        name: 'נומרי'
    },
    {
        value: 20,
        name: 'טבלה פנימית'
    },
    {
        value: 21,
        name: 'בוליאני'
    },
]


const accountsTables = [
    {
        value: 12,
        name: 'כרטיסי הכנסות'
    },
    {
        value: 13,
        name: 'כרטיסי קניות/הוצאות'
    },
    {
        value: 14,
        name: 'כרטיסי בנק'
    },
    {
        value: 15,
        name: 'כרטיסי קופה'
    },
    {
        value: 16,
        name: 'כרטיסי מערכת'
    },
    {
        value: 18,
        name: 'שם מסמך'
    },
    {
        value: 19,
        name: 'מצב מסמך'
    },
];

const documentsInputCategories = [
    "לקוח-ספק",
    "רכב",
    "מסמך",
    "עבודה",
    "פריט"
];

function FieldsStructure() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log("FieldsStructure location.state ", location.state);

    const customersStructure = useSelector((state: RootState) => state.structure.customers);
    const itemsStructure = useSelector((state: RootState) => state.structure.items);
    const worksStructure = useSelector((state: RootState) => state.structure.works);
    const carsStructure = useSelector((state: RootState) => state.structure.cars);
    const incomesStructure = useSelector((state: RootState) => state.structure.incomes);
    const outcomesStructure = useSelector((state: RootState) => state.structure.outcomes);
    const banksStructure = useSelector((state: RootState) => state.structure.banks);
    const cashBoxesStructure = useSelector((state: RootState) => state.structure.cashBoxes);
    const systemStructure = useSelector((state: RootState) => state.structure.system);

    const documentStructure = useSelector((state: RootState) => state.structure.document);


    const [tables, setTables] = useState<SelectTable[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [accountStructureCopy, setAccountStructureCopy] = useState<AccountStructure[]>();
    const [documentStructureCopy, setDocumentStructureCopy] = useState<DocumentStructure[]>();

    const accountStructureForm = useForm<{ structure: AccountStructure[] }>({
        resolver: zodResolver(z.object({ structure: z.array(AccountStructureSchema) })),
        defaultValues: {
            structure: undefined
        }
    });

    const accountStructureFieldsArray = useFieldArray({
        name: 'structure',
        control: accountStructureForm.control
    });

    const documentStructureForm = useForm<{ structure: DocumentStructure[] }>({
        resolver: zodResolver(z.object({ structure: z.array(DocumentStructureSchema) })),
        defaultValues: {
            structure: documentStructureCopy
        }
    });

    const documentStructureFieldsArray = useFieldArray({
        name: 'structure',
        control: documentStructureForm.control
    });

    useEffect(() => {
        if (location.state.structureType === 'account') {
            getAccountStructureData();
        }
        else if (location.state.structureType === 'document') {
            getDocumentStructureData();
        }

        getSystemTablesData();
    }, []);



    function chooseAccountStructure(accountType: string | undefined): AccountStructure[] | undefined {

        let structure: AccountStructure[] | undefined;

        switch (accountType) {
            case 'customers':
                structure = customersStructure;
                break;

            case 'items':
                structure = itemsStructure;
                break;

            case 'works':
                structure = worksStructure;
                break;

            case 'cars':
                structure = carsStructure;
                break;

            case 'incomes':
                structure = incomesStructure;
                break;

            case 'outcomes':
                structure = outcomesStructure;
                break;

            case 'banks':
                structure = banksStructure;
                break;

            case 'cash-boxes':
                structure = cashBoxesStructure;
                break;

            case 'system':
                structure = systemStructure;
                break;

            default:
                break;
        }

        return structure;

    }


    function saveAccountStructureAtStore(accountType: string | undefined, structure: AccountStructure[]) {

        switch (accountType) {
            case 'customers':
                dispatch(setCustomersStructure(structure))
                break;

            case 'items':
                dispatch(setItemsStructure(structure))
                break;

            case 'works':
                dispatch(setWorksStructure(structure))
                break;

            case 'cars':
                dispatch(setCarsStructure(structure))
                break;

            case 'incomes':
                dispatch(setIncomesStructure(structure))
                break;

            case 'outcomes':
                dispatch(setOutcomesStructure(structure))
                break;

            case 'banks':
                dispatch(setBanksStructure(structure))
                break;

            case 'cash-boxes':
                dispatch(setCashBoxesStructure(structure))
                break;

            case 'system':
                dispatch(setSystemStructure(structure))
                break;

            default:
                break;
        }
    }

    const getAccountStructureData = async (): Promise<AccountStructure[] | undefined> => {

        let structureData = chooseAccountStructure(location.state.accountType);

        if (!structureData) {
            structureData = await getAccountsStructure({
                "account_type": location.state.structureName
            });
        }

        if (structureData) {
            saveAccountStructureAtStore(location.state.accountType, structureData);
            setAccountStructureCopy(z.array(AccountStructureSchema).parse(structureData));
            accountStructureForm.reset({ structure: structureData });

            return structureData;
        }
    }

    const getDocumentStructureData = async (): Promise<DocumentStructure[] | undefined> => {

        let structureData;

        if (documentStructure) {
            structureData = [...documentStructure]
        }

        else {
            structureData = await getDocumentsStructure();
        }

        console.log('structureData before filter', structureData);

        dispatch(setDocumentsStructure(structureData));

        if (structureData) {

            if (location.state.documentType === "מסמך") {
                structureData = structureData.filter(structure => (structure.type === "לקוח-ספק") || (structure.type === "רכב") || (structure.type === "מסמך"))
            }

            if (location.state.documentType === "לקוח-ספק") {
                structureData = structureData.filter(structure => structure.type === "לקוח-ספק")
            }

            if (location.state.documentType === "רכב") {
                structureData = structureData.filter(structure => structure.type === "רכב")
            }

            if (location.state.documentType === "עבודה") {
                structureData = structureData.filter(structure => structure.type === "עבודה")
            }

            if (location.state.documentType === "פריט") {
                structureData = structureData.filter(structure => structure.type === "פריט")
            }

            console.log('structureData after filter', structureData);

            structureData = setDocumentStructureShowForm(structureData, location.state.document_settings_id);

            console.log('structureData after show form', structureData);

            setDocumentStructureCopy(z.array(DocumentStructureSchema).parse(structureData));
            documentStructureForm.reset({ structure: structureData });

            return structureData;
        }
    }

    function setDocumentStructureShowForm(documentStructure: DocumentStructure[], documentSettingsId: number) {



        const documentStructureCopy = documentStructure.map(structure => ({
            ...structure,
            show_form_array: structure.show_form_array.map(showForm => ({ ...showForm }))
        }));

        documentStructureCopy.forEach(structure => {
            console.log('structure: ', structure);

            const showFormObj = structure.show_form_array.find(showForm => showForm.document_settings_id === documentSettingsId);
            structure.show_form = showFormObj ? showFormObj.show_form : false;
        });

        return documentStructureCopy;
    }

    function updateStructureShowFormArray(structure: DocumentStructure, documentSettingsId: number) {

        const showFormObj = structure.show_form_array.find(showForm => showForm.document_settings_id === documentSettingsId);

        if (showFormObj) {
            showFormObj.show_form = structure.show_form;
        }

        else if (structure.show_form) {
            structure.show_form_array = [{
                document_settings_id: documentSettingsId,
                document_structure_id: structure.id,
                show_form: true
            }];
        }

    }




    const getSystemTablesData = async () => {
        try {
            setIsLoading(true);
            const SystemTables = await getSystemTables();
            const selectSystemTables: SelectTable[] = SystemTables.map(table => ({
                value: table.index,
                name: table.name || ""
            }));
            setTables([...selectSystemTables, ...accountsTables]);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    function handleReturn() {
        navigate(location.state.from, {
            replace: true,
            state: {
              ...location.state,
            }
        });
    }

    const saveTable = async () => {
        if (location.state.structureType === 'account') {
            accountStructureForm.handleSubmit(onSubmitAccountStructure)();
        }
        else if (location.state.structureType === 'document') {
            documentStructureForm.handleSubmit(onSubmitDocumentStructure)();
        }
    }

    const onSubmitAccountStructure: SubmitHandler<{ structure: AccountStructure[] }> = async (data) => {

        if (!accountStructureCopy) {
            return;
        }

        try {
            setIsSaving(true);

            for (const [index, structure] of data.structure.entries()) {
                if (!compareObjects(structure, accountStructureCopy[index])) {
                    await editAccountStructure(structure);
                }
            };

            toast.success("השינויים נשמרו");
            setAccountStructureCopy(data.structure);
            saveAccountStructureAtStore(location.state.accountType, accountStructureForm.getValues().structure);
            accountStructureForm.reset({ structure: accountStructureForm.getValues().structure });
            setIsSaving(false);
        } catch (error) {
            console.error(error);
        }

    }

    const onSubmitDocumentStructure: SubmitHandler<{ structure: DocumentStructure[] }> = async (data) => {

        if (!documentStructureCopy) {
            return;
        }


        try {
            setIsSaving(true);



            for (const [index, structure] of data.structure.entries()) {
                if (!compareObjects(structure, documentStructureCopy[index])) {
                    
                    updateStructureShowFormArray(structure, location.state.document_settings_id);

                    await editDocumentStructure(structure);
                }
            };

            const structureData = [...documentStructureForm.getValues().structure];

            for (const [_, structure] of structureData.entries()) {                    
                updateStructureShowFormArray(structure, location.state.document_settings_id);
            };

            toast.success("השינויים נשמרו");
            setDocumentStructureCopy(data.structure);
            
            dispatch(setDocumentsStructure(updateArrayById(documentStructure, structureData)));
            // documentStructureForm.reset({ structure: documentStructureForm.getValues().structure });
            setIsSaving(false);
        } catch (error) {
            console.error(error);
        }

    }




    const addCustomField = async () => {
        console.log("addCustomField");

        if (location.state.structureType === 'account') {


            const lastObjectIndex = (accountStructureCopy?.length || 0) - 1;
            const customFieldNumber = (accountStructureCopy?.[lastObjectIndex].custom_field || 0) + 1;

            const customFieldAdded = await addCustomAccountStructure({
                account_type: location.state.structureName,
                account_title: "מותאם אישית",
                required: false,
                show_form: true,
                show_search: false,
                show_browse: false,
                field_name: `custom_data.${customFieldNumber - 1}.field_value`,
                field_heb_name: `מותאם אישית ${customFieldNumber}`,
                field_display_name: `מותאם אישית ${customFieldNumber}`,
                field_length: 5,
                field_type: 1,
                field_table: 0,
                custom_field: customFieldNumber,
                editable: true,
                location: 0
            });

            saveAccountStructureAtStore(location.state.accountType, [...accountStructureForm.getValues().structure, customFieldAdded]);
            setAccountStructureCopy([...accountStructureCopy || [], customFieldAdded]);
            documentStructureForm.reset({ structure: [...documentStructureForm.getValues().structure, customFieldAdded] });

        }
        else if (location.state.structureType === 'document') {

            const lastObjectIndex = (documentStructureCopy?.length || 0) - 1;
            const documentFieldNumber = (documentStructureCopy?.[lastObjectIndex].custom_field || 0) + 1;
            const enType = location.state.documntEnType ? `${location.state.documntEnType}.` : undefined;

            const documentFieldAdded = await addCustomDocumentStructure({

                // document_settings_id: 2,
                // document_type_number: 1,
                type: location.state.documentType || "לקוח-ספק",
                required: false,
                show_form: true,
                show_search: false,
                show_browse: false,
                editable: true,
                field_name: `${enType}custom_data.${documentFieldNumber - 1}.field_value`,
                field_heb_name: `מותאם אישית ${documentFieldNumber}`,
                field_display_name: `מותאם אישית ${documentFieldNumber}`,
                field_length: 5,
                field_type: 1,
                field_table: 0,
                custom_field: documentFieldNumber,
                location: 0,
                to_print: false,
                show_form_array: []

            });

            dispatch(setDocumentsStructure([...documentStructureForm.getValues().structure, documentFieldAdded]));
            setDocumentStructureCopy([...documentStructureCopy || [], documentFieldAdded]);
            documentStructureForm.reset({ structure: [...documentStructureForm.getValues().structure, documentFieldAdded] });

        }
    }


    return (
        <StyledFieldsStructure>
            {isLoading && <CircularProgress color="inherit" sx={{ display: "block", margin: "auto", padding: 4 }} />}
            {!isLoading && (<>
                <div className="head">
                    הגדרת מבנה {location.state.structureName} {location.state.documentName}
                </div>
                <div className="table">
                    <table>
                        <thead>
                            {
                                location.state.structureType === 'account' && (
                                    <tr>
                                        <th>הצגה {location.state.fromType === 'form' ? "בטופס" : location.state.fromType === 'search' ? "בחיפוש" : "בטבלה"}</th>
                                        <th>שם שדה</th>
                                        <th>שם תצוגה</th>
                                        <th>אורך</th>
                                        <th>סוג</th>
                                        <th>טבלה</th>
                                        <th></th>
                                    </tr>
                                )
                            }

                            {
                                location.state.structureType === 'document' && (
                                    <tr>
                                        <th>הצגה {location.state.fromType === 'form' ? "במסמך" : location.state.fromType === 'search' ? "בחיפוש" : "בטבלה"}</th>
                                        <th>שם שדה</th>
                                        <th>שם תצוגה</th>
                                        <th>קטגוריה</th>
                                        <th>אורך</th>
                                        <th>סוג</th>
                                        <th>טבלה</th>
                                        <th>מיקום</th>
                                        <th></th>
                                    </tr>
                                )
                            }

                        </thead>
                        <tbody>
                            {
                                tables && accountStructureFieldsArray.fields?.map((field, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Controller
                                                control={accountStructureForm.control}
                                                name={location.state.fromType === 'form' ? `structure.${index}.show_form` : location.state.fromType === 'search' ? `structure.${index}.show_search` : `structure.${index}.show_browse`}

                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox
                                                        disabled={location.state.fromType === 'form' && !!field.required}
                                                        onChange={onChange}
                                                        checked={!!value} />
                                                )}
                                            />
                                        </td>
                                        <td>{field.field_heb_name}</td>
                                        <td>
                                            <Controller
                                                control={accountStructureForm.control}
                                                name={`structure.${index}.field_display_name`}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        error={accountStructureForm?.formState?.errors?.structure?.[index]?.field_display_name ? true : false}
                                                        helperText={<ErrorMessage errors={accountStructureForm.formState.errors} name={`structure.${index}.field_display_name`} />}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={accountStructureForm.control}
                                                name={`structure.${index}.field_length`}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        error={accountStructureForm?.formState?.errors?.structure?.[index]?.field_length ? true : false}
                                                        helperText={<ErrorMessage errors={accountStructureForm.formState.errors} name={`structure.${index}.field_length`} />}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={accountStructureForm.control}
                                                name={`structure.${index}.field_type`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        sx={{ minWidth: 150 }}
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                    >
                                                        {inputTypes.map((obj, index) => (
                                                            <MenuItem key={index} value={obj.value}>{obj.value}. {obj.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={accountStructureForm.control}
                                                name={`structure.${index}.field_table`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        sx={{ minWidth: 150 }}
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                    >
                                                        <MenuItem value={0}></MenuItem>
                                                        {tables?.map((obj, index) => (
                                                            <MenuItem key={index} value={obj.value}>{obj.value}. {obj.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td></td>
                                    </tr>
                                ))
                            }

                            {
                                tables && documentStructureFieldsArray.fields?.map((field, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={location.state.fromType === 'form' ? `structure.${index}.show_form` : location.state.fromType === 'search' ? `structure.${index}.show_search` : `structure.${index}.show_browse`}

                                                render={({ field: { onChange, value } }) => (
                                                    <Checkbox
                                                        disabled={location.state.fromType === 'form' && !!field.required}
                                                        onChange={onChange}
                                                        checked={!!value} />
                                                )}
                                            />
                                        </td>
                                        <td>{field.field_heb_name}</td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.field_display_name`}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        error={documentStructureForm?.formState?.errors?.structure?.[index]?.field_display_name ? true : false}
                                                        helperText={<ErrorMessage errors={documentStructureForm.formState.errors} name={`structure.${index}.field_display_name`} />}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.type`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        sx={{ minWidth: 150 }}
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        // disabled={!field.custom_field}
                                                        disabled={true}
                                                    >
                                                        {documentsInputCategories.map((obj, index) => (
                                                            <MenuItem key={index} value={obj}>{obj}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.field_length`}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        error={documentStructureForm?.formState?.errors?.structure?.[index]?.field_length ? true : false}
                                                        helperText={<ErrorMessage errors={documentStructureForm.formState.errors} name={`structure.${index}.field_length`} />}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.field_type`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        sx={{ minWidth: 150 }}
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                    >
                                                        {inputTypes.map((obj, index) => (
                                                            <MenuItem key={index} value={obj.value}>{obj.value}. {obj.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.field_table`}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        sx={{ minWidth: 150 }}
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                    >
                                                        <MenuItem value={0}></MenuItem>
                                                        {tables?.map((obj, index) => (
                                                            <MenuItem key={index} value={obj.value}>{obj.value}. {obj.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            />
                                        </td>
                                        <td>
                                            <Controller
                                                control={documentStructureForm.control}
                                                name={`structure.${index}.location`}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        onChange={onChange} // send value to hook form
                                                        value={value}
                                                        size="small"
                                                        error={documentStructureForm?.formState?.errors?.structure?.[index]?.location ? true : false}
                                                        helperText={<ErrorMessage errors={documentStructureForm.formState.errors} name={`structure.${index}.location`} />}
                                                    />
                                                )}
                                            />
                                        </td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="buttons">
                    <Button variant='contained' onClick={saveTable} disabled={isSaving}>שמירה</Button>
                    <Button variant="outlined" onClick={handleReturn}>חזור</Button>
                    {location.state.documentType !== "מסמך" && (<Button variant="outlined" onClick={addCustomField}>הוספת שורה</Button>)}

                </div>


            </>)}


            {/* <DevTool control={accountStructureForm.control} /> */}

        </StyledFieldsStructure>
    )
}

export default FieldsStructure