import styled from '@emotion/styled'

export const StyledApp = styled.div`

    background-color: ${({theme})=> theme.colors.background_color};
    color: ${({theme})=> theme.colors.main_color};
    
    a:visited{
       color: ${({theme})=> theme.colors.main_color};
    }
  
`   