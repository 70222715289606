import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';


z.setErrorMap(customErrorMap);


export const AccountCustomDataSchema = z.object({

    //from server (optional)
    id: z.number(),
    business_id: z.number(),
    account_id: z.number(),
    field_name: z.string(),
    field_value: z.string().nullable(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

});

export const AccountCustomDataFormSchema = z.object({
    
    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    // account_id: z.number(),

    //inputs required
    field_name: z.preprocess((val) => myPreProcess(val), z.string()),

    //inputs
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

});

export const AccountCustomDataSearchSchema = z.object({

    //inputs required
    field_name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

    //inputs
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
});

export type AccountCustomData = z.infer<typeof AccountCustomDataSchema>
export type AccountCustomDataForm = z.infer<typeof AccountCustomDataFormSchema>
export type AccountCustomDataSearch = z.infer<typeof AccountCustomDataSearchSchema>
