import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);


export const ItemEntrySchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),

    //client && server
    item_id: z.number(),
    sku: z.string().min(1),
    out: z.number(),
    in: z.number(),
    balance: z.number(),
    price: z.preprocess((val) => val === "" ? undefined : Number(val), z.number().optional()),
    account_name: z.string(),
    account_number: z.number(),

});

export type ItemEntry = z.infer<typeof ItemEntrySchema>
