import styled from '@emotion/styled'

export const StyledEditCar = styled.div`

    display: flex;
    flex-direction: column;
    gap: 15px;


    .fields{
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        align-items: baseline;
    }

    .form{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;
        background-color: ${({theme})=> theme.colors.section_color};

        > div{
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }

    .buttons{
        display: flex;
        justify-content: space-between;

        div{
            display: flex;
            gap: 20px;
        }
    }

    .left{
        display: flex;
        justify-content: end;
    }

`