import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);

export const EntrySchema = z.object({
    
    //from server (optional)
    id: z.number().optional(),  
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    //client && server
    account_id: z.number(),
    document_id: z.number(),
    entry_name: z.string(),
    debit: z.number().optional().nullish(),
    credit: z.number().optional().nullish(),
    balance: z.number(),
    counter_account: z.string().optional().nullish(),
    doc_name_2: z.string().optional().nullish(),
    doc_num_2: z.number().optional().nullish(),
    ref_2: z.string().optional().nullish(),
    value: z.string().optional().nullish(),
    info: z.string(),

    

});


export type Entry = z.infer<typeof EntrySchema>
