import { z } from "zod";
import { CarCustomDataSchema } from "./car-custom-data";
import { customErrorMap, myPreProcess } from './../services/zodService';
import { AccountSchema } from "./account";

z.setErrorMap(customErrorMap);


export const CarSchema = z.object({


    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    //inputs required
    license_number: z.string().min(7,"השדה חייב להכיל לפחות 7 תווים"),
    

    //inputs optional
    
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.string().nullish(),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    

    custom_data: z.array(CarCustomDataSchema).optional(),
    customer: AccountSchema.optional(),
});

export const CarFormSchema = z.object({


    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    //inputs required
    license_number: z.string().min(7,"השדה חייב להכיל לפחות 7 תווים"),
    

    //inputs optional
    
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.string().nullish(),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    

    custom_data: z.array(CarCustomDataSchema).optional(),
});

export const CarDocumentFormSchema = CarFormSchema.extend({
    document_id: z.number(),
    car_id: z.number().optional(),
    license_number: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
});

export const CarSearchSchema = CarSchema.extend({

    
    //inputs required
    license_number: z.string().nullish(),
    
    //inputs optional
    account_number: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    info: z.string().nullish(),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    production_year: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    

    custom_data: z.array(CarCustomDataSchema).optional(),
});

const PartialCarSchema = CarSchema.partial();


export type Car = z.infer<typeof CarSchema>
export type PartialCar = z.infer<typeof PartialCarSchema>
export type CarForm = z.infer<typeof CarFormSchema>
export type CarDocumentForm = z.infer<typeof CarDocumentFormSchema>
export type CarSearch = z.infer<typeof CarSearchSchema>

