import axiosClient from "../axios-client";
import { User } from "../models/user";


export const login = async (email: string, password: string) : Promise<User> => {
    const response = await axiosClient.post(`/login`, 
    {
        email: email,
        password: password
    });

    const token = response?.data?.token;
    console.log(token);

    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return response.data.user;
}

export const getUser = async () : Promise<User> => {
    const response = await axiosClient.get('/api/user');
    return response.data;
}