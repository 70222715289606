import { Typography } from "@mui/material"
import { StyledEntries } from "./entries.styled"
import { AccountContext } from "../../../utils/AccountContext";
import { useContext } from "react";
import { DataGrid, GridColDef, GridLocaleText } from '@mui/x-data-grid';
// import { demoEntriesDataTable } from "../../../local-data/localData";


function Entries() {

    const { account } = useContext(AccountContext);

    const rows = account?.entries || [];

    // const demo = demoEntriesDataTable;

    const customLocaleText: Partial<GridLocaleText> = {

        // Table Content
        noRowsLabel: 'אין רשומות.',

        // Labels
        columnMenuLabel: 'תפריט',
        columnHeaderSortIconLabel: 'מיון',

        // Columns text
        columnMenuUnsort: "ביטול מיון",
        columnMenuSortAsc: "מיון א-ת 9-1",
        columnMenuSortDesc: "מיון ת-א 1-9",
        columnMenuFilter: "סינון",
        columnMenuHideColumn: "הסתר עמודה",
        columnMenuShowColumns: "הצג עמודות",
        columnMenuManageColumns: "ניהול עמודות",

        // Columns Management
        columnsManagementShowHideAllText: "הצג/הסתר הכל",
        columnsManagementReset: "איפוס",
        columnsManagementSearchTitle: "חיפוש",

        // Filter Panel text
        filterPanelOperator: 'פעולה',
        filterPanelOperatorAnd: 'ו-',
        filterPanelOperatorOr: 'או',
        filterPanelColumns: 'עמודה',
        filterPanelInputLabel: 'ערך',
        filterPanelInputPlaceholder: 'ערך לסינון',

        // Filter operators text
        filterOperatorContains: 'מכיל',
        filterOperatorEquals: 'שווה',
        filterOperatorStartsWith: 'מתחיל עם',
        filterOperatorEndsWith: 'נגמר עם',
        filterOperatorIsEmpty: 'הוא ריק',
        filterOperatorIsNotEmpty: 'הוא לא ריק',
        filterOperatorIsAnyOf: 'כל אחד מ',

        filterOperatorIs: 'הוא',
        filterOperatorNot: 'הוא לא',
        filterOperatorAfter: 'הוא אחרי',
        filterOperatorOnOrAfter: 'הוא מ',
        filterOperatorBefore: 'הוא לפני',
        filterOperatorOnOrBefore: 'הוא עד',

        noResultsOverlayLabel: 'לא נמצאו תוצאות.',

        // Pagination
        MuiTablePagination: {
            labelRowsPerPage: 'מס שורות מקסימלי לעמוד:',
            labelDisplayedRows: ({ from, to, count }) =>
                `${from}–${to} מתוך ${count !== -1 ? count : `יותר מ- ${to}`}`,
            backIconButtonProps: {
                sx: { transform: "rotate(180deg)" }
            },
            nextIconButtonProps: {
                sx: { transform: "rotate(180deg)" }
            }
        },
    };


    const columns: GridColDef[] = [
        { field: 'entry_name', headerName: 'תנועה', width: 130 },
        { field: 'created_at', headerName: 'תאריך', type: 'date', valueGetter: (value: string) => value && new Date(value), width: 130 },
        { field: '', headerName: 'אסמכתא', width: 130 },
        { field: 'debit', headerName: 'חובה', width: 130, valueGetter: (value: number) => value?.toFixed(2)},
        { field: 'credit', headerName: 'זכות', width: 130 , valueGetter: (value: number) => value?.toFixed(2)},
        { field: 'balance', headerName: 'יתרה', width: 130 , valueGetter: (value: number) => value.toFixed(2)},
        { field: 'counter_account', headerName: 'חן נגדי', width: 130 },
        { field: 'doc_name_2', headerName: 'אסמכתא-2', width: 130 },
        { field: 'value', headerName: 'ערך', width: 200 },
        { field: 'info', headerName: 'פרטים', width: 200 },
    ];

    return (
        <StyledEntries>
            <div className="container">
                <div className="top">
                    <div>
                        <Typography variant="body1" component={"span"}>מספר חשבון:</Typography>
                        <Typography variant="body2" component={"span"}>{account?.account_number}</Typography>
                    </div>
                    <div>
                        <Typography variant="body1" component={"span"}>שם חשבון:</Typography>
                        <Typography variant="body2" component={"span"}>{account?.account_name}</Typography>
                    </div>
                </div>
                <DataGrid
                    disableRowSelectionOnClick
                    columns={columns}
                    rows={rows}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    // rowSelection={false}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    localeText={customLocaleText}
                    sx={{
                        '& .MuiDataGrid-topContainer svg': {
                            color: "rgba(0,0,0,0.57)",
                        },
                        '& .MuiDataGrid-virtualScrollerContent, .MuiTablePagination-root, .MuiSvgIcon-root': {
                            color: 'rgba(255,255,255,0.7)',

                        }
                    }}
                />
            </div>
        </StyledEntries>
    )

}

export default Entries