import styled from '@emotion/styled'

export const StyledLayout = styled.div`


    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* direction: ltr; */

    .menu-header{
        display: 'flex';
        align-items: center;
        justify-content: 'flex-start';
    }
`