import { ReactNode, createContext, useState } from "react";
import { Account } from "../models/account";

// Define the AccountContextType interface
interface AccountContextType {
    account: Account | null;
    setAccount: (account: Account) => void;
}

// Create the AccountContext with an initial value
export const AccountContext = createContext<AccountContextType>({
    account: null,
    setAccount: () => {},
});

// Define the AccountProvider component
export const AccountProvider: React.FC<{ children: ReactNode; account: Account }> = ({ children, account }) => {
    const [currentAccount, setCurrentAccount] = useState<Account>(account);

    console.log("Initial account in provider:", account);
    
    return (
        <AccountContext.Provider value={{ account: currentAccount, setAccount: setCurrentAccount }}>
            {children}
        </AccountContext.Provider>
    );
};