import { Button, Typography } from "@mui/material"
// import styled from '@emotion/styled'
// import { styled } from '@mui/material/styles';
import { StyledSettings } from "./Settings.styled"
import { MuiColorInput, matchIsValidColor } from 'mui-color-input'
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { DesignSettings, DesignSettingsSchema } from "../../models/design-settings"
import { useEffect, useRef, useState } from "react";
import { getDesignSettings, saveDesignSettings } from "../../services/designSettingsService";
import { useAuth } from "../../utils/AuthContext";
import { toast } from "react-toastify";
import {setDesignSettings as setDesignSettingsAtStore} from "../../redux/features/settings";
import { useDispatch } from "react-redux"

function Settings() {

  const dispatch = useDispatch()

  const { user } = useAuth();

  const [designSettings, setDesignSettings] = useState<DesignSettings>();


  const abortControllerRef = useRef<AbortController | null>(null);

  const designForm = useForm<DesignSettings>({
    resolver: zodResolver(DesignSettingsSchema)
  });

  useEffect(() => {
    getDesignSettingsData();
  }, []);


  //get the design settings
  const getDesignSettingsData = async () => {

    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    try {
      const data = await getDesignSettings("id", "0", "ASC", { user_id: user?.id }, abortControllerRef.current.signal);
      
      if (data.length > 0) {
        setDesignSettings(data[0]);
        designForm.reset(data[0]);
      }

    } catch (error) {
      console.error(error)
    }
  };
  // const MuiColorInputStyled = styled(MuiColorInput)`
  //   & .muirtl-18uun7i-MuiInputBase-root-MuiOutlinedInput-root {
  //     color: pink;
  //   }
  //   & .MuiColorInput-AlphaSlider {
  //     margin-top: 500px;
  //   }
  //   & .MuiColorInput-HueSlider {
  //     margin-top: 500px !important;
  //     height: 500px;
  //   }
  // `;


  const handleSaveDesignSettings = async () => {
    designForm.handleSubmit(onSubmitDesignSettings)();
  }


  const onSubmitDesignSettings: SubmitHandler<DesignSettings> = async (data) => {

    try {
      const response = await saveDesignSettings(Number(data.id), data);
      console.log(response);
      dispatch(setDesignSettingsAtStore(response));
      toast.success("הגדרות העיצוב נשמרו");
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <StyledSettings>
      <div className="title">
        <Typography variant="h5" component={'span'} >
          הגדרות
        </Typography>
      </div>
      <div className="colors">
        <Typography variant="body1" component="span">
          עיצוב:
        </Typography>
        {designSettings && (<>
          <table>
            <tbody>
              <tr>
                <td>צבע ראשי</td>
                <td>
                  <Controller
                    name="main_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע טקסט תיבות טקסט</td>
                <td>
                  <Controller
                    name="input_text_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע שמות תיבות טקסט</td>
                <td>
                  <Controller
                    name="input_title_text_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע הדר</td>
                <td>
                  <Controller
                    name="header_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע רקע</td>
                <td>
                  <Controller
                    name="background_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע סקשנים</td>
                <td>
                  <Controller
                    name="section_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>צבע מודגש</td>
                <td>
                  <Controller
                    name="highlight_color"
                    control={designForm.control}
                    rules={{ validate: matchIsValidColor }}
                    render={({ field, fieldState }) => (
                      <MuiColorInput
                        {...field}
                        // format="hex"
                        helperText={fieldState.invalid ? "Color is invalid" : ""}
                        error={fieldState.invalid}
                      />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="buttons">
            <Button variant="contained" onClick={handleSaveDesignSettings} disabled={designForm.formState.isSubmitting} >שמירה</Button>
            
          </div>
        </>)}

      </div>
    </StyledSettings>
  )
}

export default Settings