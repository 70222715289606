import { Typography } from "@mui/material"
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { groupBy } from "../../../../helpers/helpers";
import { useEffect } from "react";
import MuiInput from "../../../../components/mui-input/MuiInput";
import { StyledAddCar } from "./AddCar.styled";
import { getAccountsStructure } from "../../../../services/accountsStructureService";
import { setCarsStructure } from "../../../../redux/features/structure";
import { UseFormReturn } from "react-hook-form";
import { Car } from "../../../../models/car";


interface AddCarProps {
  setStructureIsLoading: (isLoading: boolean) => void;
  useForm: UseFormReturn<Car>
}

function AddCar({ setStructureIsLoading, useForm }: AddCarProps) {

  console.log("AddCar");

  const carStructure = useSelector((state: RootState) => state.structure.cars);
  const structureSortedByTitle = groupBy(carStructure, "account_title")
  const dispatch = useDispatch()
  const customFieldsExist = structureSortedByTitle?.["מותאם אישית"]?.find(structure=> !!structure.show_form === true);


  useEffect(() => {
    if (!carStructure) {
      getStructureAndSaveAtStore();
    }
  }, []);


  async function getStructureAndSaveAtStore() {
    try {
      setStructureIsLoading(true);

      const structure = await getAccountsStructure({
        account_type: "רכב"
      });

      dispatch(setCarsStructure(structure || []));

    } catch (error) {
      console.error(error);
    }
    setStructureIsLoading(false);
  }



  return (
    <StyledAddCar>
      {carStructure &&
        <>
          <div>
            <Typography variant="body1">פרטי רכב</Typography>
            <div className="fields">
              {structureSortedByTitle?.["פרטים"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          <div>
            <Typography variant="body1">יצרן ודגם</Typography>
            <div className="fields">
              { structureSortedByTitle?.["יצרן ודגם"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

          {/* <div>
            <Typography variant="body1">פרטי לקוח</Typography>
            <div className="fields">
              { structureSortedByTitle?.["פרטים"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} /> : null
              })}
            </div>
          </div> */}

          <div>
            {customFieldsExist && (<Typography variant="body1">שדות מותאמים אישית:</Typography>)}
            <div className="fields">
              { structureSortedByTitle?.["מותאם אישית"]?.map(field => {
                return field.show_form ? <MuiInput key={field.field_name} field={field} variant='outlined' useForm={useForm} showRequired/> : null
              })}
            </div>
          </div>

        </>}

    </StyledAddCar>
  )
}

export default AddCar