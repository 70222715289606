import { CircularProgress } from "@mui/material"
import { StyledLoading } from "./Loading.styled"

function Loading() {
  return (
    <StyledLoading>
      <CircularProgress color="inherit" sx={{ display: "block", margin: "auto", padding: 4 }} />
    </StyledLoading>
  )
}

export default Loading