import { z } from "zod";
import { customErrorMap, myPreProcess } from './../services/zodService';
import { ItemCustomDataSchema } from "./item-custom-data";
import { ItemEntrySchema } from "./item-entry";
// import { ItemCustomData } from "./item-custom-data";

z.setErrorMap(customErrorMap);


export const ItemSchema = z.object({

    
    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),

    //inputs required
    sku: z.string().min(1),
        
    //inputs optional
    name: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().optional().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().optional().nullish()),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    entries: z.preprocess((val) => myPreProcess(val), z.array(ItemEntrySchema).optional()),

    custom_data: z.preprocess((val) => myPreProcess(val), z.array(ItemCustomDataSchema).optional()),
});

export const ItemSearchSchema = z.object({

    //inputs required
    sku: z.string().nullish(),
        
    //inputs optional
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    info: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    group: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    manufacturer: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    model: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    cost: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    entries: z.preprocess((val) => myPreProcess(val), z.array(ItemEntrySchema).nullish()),

    custom_data: z.preprocess((val) => myPreProcess(val), z.array(ItemCustomDataSchema).optional()),
});

export type Item = z.infer<typeof ItemSchema>
export type ItemSearch = z.infer<typeof ItemSearchSchema>