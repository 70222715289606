import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentItemSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    item_id: z.number().optional(),
    business_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    document_id: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()).optional(),

    //inputs required
    price: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    amount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(1)),
    total: z.preprocess((val) => myPreProcess(val, Number(val)), z.number()),
    
    //inputs optional
    sku: z.string().optional().nullish(),
    name: z.preprocess((val) => myPreProcess(val), z.string().nullish()),
    discount: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().min(0).max(100).nullish()),
    net:  z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    income_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    outcome_card: z.preprocess((val) => myPreProcess(val, Number(val)), z.number().nullish()),
    
});

export type DocumentItem = z.infer<typeof DocumentItemSchema>