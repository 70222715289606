import axiosClient from "../axios-client";
import { AccountStructure, AccountStructureForm } from "../models/accounts-structure";

export const getAccountsStructure = async (body = {}): Promise<AccountStructure[] | undefined> => {
    try {
        const response = await axiosClient.post(`/api/accounts-structure/search`, body);
        return response.data;
    } catch (error) {
        console.error(error);
    }

}


export const editAccountStructure = async (accountStructureToUpdate: AccountStructure): Promise<AccountStructure> => {
    const response = await axiosClient.patch(`api/accounts-structure/${accountStructureToUpdate.id}`, accountStructureToUpdate);
    return response.data;
}

export const addCustomAccountStructure = async (body: AccountStructureForm): Promise<AccountStructure> => {
    const response = await axiosClient.post(`api/accounts-structure`, body);
    return response.data;
}