import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";


z.setErrorMap(customErrorMap);

export const CarCustomDataSchema = z.object({

    
        
    //from server (optional)
    id: z.number().optional(),
    business_id: z.number().optional(),
    car_id: z.number().optional(),
    created_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),
    updated_at: z.preprocess((val) => myPreProcess(val, new Date(val as string)), z.date().optional()),


    //inputs required
    field_value: z.preprocess((val) => myPreProcess(val), z.string().nullish()),

    //inputs optional
    field_name: z.string().optional(),

});

export type CarCustomData = z.infer<typeof CarCustomDataSchema>


