import { Paper, List } from "@mui/material"
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
// import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { StyledMainMenu } from "./main-menu.styled";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';

interface ListItemLinkProps {
    icon: ReactNode;
    text: string;
    to: string;
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, text, to } = props;

    return (

        <Link to={to}>
            <ListItem>
                {icon ? <ListItemIcon >{icon}</ListItemIcon> : null}
                <ListItemText primary={text} style={{ textAlign: "right" }} />
            </ListItem>
        </Link>

    );
}

function MainMenu() {

    // const classes = useStyles();

    return (
        <StyledMainMenu>
            <nav className="menu">
                <Paper elevation={0}>

                    <List className="long-menu">
                        <ListItemLink to="/" text="ראשי" icon={<HomeIcon color="primary" />} />
                        <ListItemLink to="/customers" text="כרטסות" icon={<BookmarksIcon color="primary" />} />
                        <ListItemLink to="/documents" text="מסמכים" icon={<DescriptionIcon color="primary" />} />
                        <ListItemLink to="/tables" text="טבלאות" icon={<TableChartIcon color="primary" />} />
                        <ListItemLink to="/reports" text="דוחות" icon={<AssessmentIcon color="primary" />} />
                        <ListItemLink to="/settings" text="הגדרות" icon={<SettingsIcon color="primary" />} />
                    </List>

                    <List className="short-menu">
                        <ListItemLink to="/" text="" icon={<HomeIcon color="primary" />} />
                        <ListItemLink to="/customers" text="" icon={<BookmarksIcon color="primary" />} />
                        <ListItemLink to="/documents" text="" icon={<DescriptionIcon color="primary" />} />
                        <ListItemLink to="/tables" text="" icon={<TableChartIcon color="primary" />} />
                        <ListItemLink to="/reports" text="" icon={<AssessmentIcon color="primary" />} />
                        <ListItemLink to="/settings" text="" icon={<SettingsIcon color="primary" />} />
                    </List>

                </Paper>
            </nav>
        </StyledMainMenu >
    );
}

export default MainMenu;