import styled from '@emotion/styled'

export const StyledTable = styled.table`

    
    width: 100%;
    background-color: ${({theme})=> theme.colors.section_color};
    border-radius: 5px;
    border-collapse: collapse;

    tr{
        display: flex;
        flex-direction: row;
    }

    th, td{
        display: flex;
        align-items: center;
        padding: 8px;
        border-bottom: 1px solid rgb(21, 35, 43);
        flex: 1;
        align-content: center;
        /* text-align: right; */
    }

    

    th div{
        align-items: center;
        display: flex;
        text-align: center;
    }

    th span{
        cursor: pointer;
    }

    th:last-child, td:last-child {
        justify-content: end;
        flex-grow: 1; /* Allocate twice as much space to the last cell */
    }

    .arrow{
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .arrow-down{
        transform: rotate(180deg);
    }

    th:last-child, td:last-child {
        flex-grow: 1; /* Make the last column grow to take up remaining space */
        white-space: nowrap; /* Prevent line breaks in last column */
    }

    .clickable-row{
        cursor: pointer;
    }

`