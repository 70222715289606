import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DesignSettings } from '../../models/design-settings';


export interface InitialState {
    designSettings: DesignSettings | undefined; //table 1
}

const initialState: InitialState = {
    designSettings: undefined,
}

export const tables = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setDesignSettings: (state, action: PayloadAction<DesignSettings | undefined>) => {
            state.designSettings = action.payload
        },
    },
})


// Action creators are generated for each case reducer function
export const {

    setDesignSettings,

} = tables.actions

export default tables.reducer