import { useDispatch, useSelector } from "react-redux";
import { AccountStructure } from "../../models/accounts-structure"
import { RootState } from "../../redux/store";
import { getSystemTableByNumber, getSystemTableNameFromStore } from "../../services/tablesService";
import { useEffect } from "react";
import { setBanksTable, setItemGroupsTable, setManufacturersTable, setSortCodesTable, setTagsTable, setWarehousesTable } from "../../redux/features/tables";
import { Table } from "../../models/table";
import { Account } from "../../models/account";
import { DocumentStructure } from "../../models/document-structure";
import { Document } from "../../models/document";
import { DocumentSettings } from "../../models/document-settings";
import { dateToString } from "../../helpers/helpers";
import { Item } from "../../models/item";
import { Work } from "../../models/work";
import { Car } from "../../models/car";


interface PrintFieldValueProps {
    field: AccountStructure | DocumentStructure;
    //value: string | number | boolean | Date | AccountCustomData[] | undefined;
    accountData: Account | Document | Item | Work | Car;
}



function PrintFieldValue({ field, accountData }: PrintFieldValueProps) {

    const dispatch = useDispatch();

    const tables = useSelector((state: RootState) => state.tables);
    const value = accountData[field.field_name as keyof (Account | Document | Item | Work | Car)]
        || (accountData as Account).custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        || (accountData as Document).customer?.custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        || (accountData as Document).car?.custom_data?.find(customField => customField.field_name === field.field_name)?.field_value
        || (accountData as Document).document_settings?.[field.field_name.split(".")[1] as keyof DocumentSettings]
        || (accountData as Document).account?.[field.field_name.split(".")[1] as keyof Account];

    console.log("PrintFieldValue accountData", accountData);
    console.log("PrintFieldValue field.field_name", field.field_name);
    console.log("PrintFieldValue value", value);
    useEffect(() => {
        if (field.field_type === 4) { //select
            const key = getSystemTableNameFromStore(field.field_table);

            if (key) {
                const table = tables[key];
                if (!table) {
                    getTableAndSaveAtStore(field.field_table);
                }
            }
        }

    }, []);

    const getTableAndSaveAtStore = async (tableNumber: number) => {
        try {
            const table = await getSystemTableByNumber(tableNumber);
            dispatchSystemTable(tableNumber, table);
        } catch (error) {
            console.error(error);
        }
    }

    const dispatchSystemTable = (tableNumber: number, table: Table[] = []) => {
        switch (tableNumber) {
            case 1:
                dispatch(setManufacturersTable(table));
                break;

            case 2:
                dispatch(setWarehousesTable(table));
                break;

            case 3:
                dispatch(setTagsTable(table));
                break;

            case 4:
                dispatch(setSortCodesTable(table));
                break;

            case 5:
                dispatch(setItemGroupsTable(table));
                break;

            case 6:
                dispatch(setBanksTable(table));
                break;

            default:
                break;
        }
    }


    if (field.field_type === 1 || field.field_type === 2) { // en/heb
        return <>{value}</>
    }

    if (field.field_type === 3) { //date
        const date = new Date(value as string);
        return <>{dateToString(date)}</>
    }

    if (field.field_type === 4) { //select
        const key = getSystemTableNameFromStore(field.field_table);

        if (key) {
            const table = tables[key];
            const textValue = table?.find(item => value === item.index)?.name;
            return textValue ? <>{textValue}</> : <></>;
        }

        else {
            return <></>;
        }

    }

    if (field.field_type === 5) { //select
        return <>{value}</>
    }


    if (field.field_type === 7) { //number
        return <>{value}</>
    }


    if (field.field_type === 8) { //date
        console.log("type 8 value: ", value);
        return <>{(value as number)?.toFixed(2)}</>
    }

    if (field.field_type === 21) { //number
        return <>{value ? "כן" : "לא"}</>
    }

    return (
        <div>PrintInputValue</div>
    )
}

export default PrintFieldValue