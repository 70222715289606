import { useEffect, useState } from "react";
import { getDocumentsSettings, saveDocumentSettings } from "../../../services/documentsSettingsService";
import { StyledDocumentSettings } from "./DocumentSettings.style"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DocumentSettingsFormSchema, DocumentSettings as DocumentSettingsModel } from "../../../models/document-settings";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
// import { DevTool } from '@hookform/devtools';
import { toast } from "react-toastify";

function DocumentSettings() {

    const location = useLocation();
    const navigate = useNavigate();

    // const [documentsSettings, setDocumentsSettings] = useState<DocumentSettingsModel[]>();
    const [settings, setSettings] = useState<DocumentSettingsModel>();
    const [isSaving, setIsSaving] = useState<boolean>();

    const settingsForm = useForm<DocumentSettingsModel>({
        resolver: zodResolver(DocumentSettingsFormSchema),
        defaultValues: settings
    });

    const { id } = useParams();

    useEffect(() => {
        getDocumentsSettingsData();
    }, []);


    //get documents settings and set the chosen document settings
    const getDocumentsSettingsData = async () => {
        try {
            const data = await getDocumentsSettings();
            // setDocumentsSettings(data);
            const settings = setDocumentSettings(data, Number(id));
            settingsForm.reset(settings);
        } catch (error) {
            console.error(error)
        }
    };

    //find and set the settings of the current document type
    function setDocumentSettings(documentsSettings: DocumentSettingsModel[] | undefined, documentSettingsId: number | undefined): DocumentSettingsModel | undefined {

        if (!documentsSettings || !documentSettingsId) {
            return undefined;
        }

        const documentSettings = documentsSettings.find(documentsSetting => documentsSetting.id === documentSettingsId);
        // const documentSettingsParse = DocumentSettingsSchema.parse(documentSettings);
        setSettings(documentSettings);
        // return documentSettingsParse;

        return documentSettings;
    }

    const saveSettings = async () => {
        settingsForm.handleSubmit(onSubmitDocumentSettings)();
    }


    const onSubmitDocumentSettings: SubmitHandler<DocumentSettingsModel> = async (data) => {

        try {
            console.log("onSubmitDocumentSettings data: ", data);

            setIsSaving(true);
            const response = await saveDocumentSettings(data, Number(id));
            console.log(response);
            setSettings(response);
            toast.success("ההגדרות נשמרו");
            setIsSaving(false);
        } catch (error) {
            console.error(error);
        }


    }

    function handleReturn() {
        navigate(location.state.from, {
            replace: true,
            state: {
              ...location.state,
            }
        });
    }

    return (
        <StyledDocumentSettings>
            {settings && <>
                <div className="title">
                    {`הגדרות מסמך ${settings?.name}`}
                </div>
                <div className="settings">
                    <div className="row">
                        <Controller
                            control={settingsForm.control}
                            name="display_car"

                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel control={<Checkbox onChange={onChange} checked={!!value} />} label="הצגת רכב" />
                            )}
                        />
                    </div>
                    <div className="row">
                        <Controller
                            control={settingsForm.control}
                            name="display_works"

                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel control={<Checkbox onChange={onChange} checked={!!value} />} label="הצגת עבודות" />
                            )}
                        />
                    </div>
                    <div className="row">
                        <Controller
                            control={settingsForm.control}
                            name="display_items"

                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel control={<Checkbox onChange={onChange} checked={!!value} />} label="הצגת פריטים" />
                            )}
                        />
                    </div>
                    <div className="row">
                        <Controller
                            control={settingsForm.control}
                            name="display_payments"

                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel control={<Checkbox onChange={onChange} checked={!!value} />} label="הצגת תשלום" />
                            )}
                        />
                    </div>


                    <table>
                        <tbody>
                            <tr>
                                <td>שם</td>
                                <td>
                                    <Controller
                                        control={settingsForm.control}
                                        name="name"
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                onChange={onChange} // send value to hook form
                                                value={value}
                                                size="small"
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>שם מקוצר</td>
                                <td>
                                    <Controller
                                        control={settingsForm.control}
                                        name="short_name"
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                onChange={onChange} // send value to hook form
                                                value={value}
                                                size="small"
                                            />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>עיגול אגורות</td>
                                <td>
                                    <Controller
                                        control={settingsForm.control}
                                        name="circle"

                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                sx={{ minWidth: 150 }}
                                                onChange={onChange} // send value to hook form
                                                value={value}
                                                size="small"
                                            >

                                                <MenuItem value={0}>לא</MenuItem>
                                                <MenuItem value={1}>כן</MenuItem>

                                            </Select>
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>מסמך חשבונאי</td>
                                <td>
                                    <Controller
                                        control={settingsForm.control}
                                        name="accounting"

                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                sx={{ minWidth: 150 }}
                                                onChange={onChange} // send value to hook form
                                                value={value}
                                                size="small"
                                            >

                                                <MenuItem value={0}>לא</MenuItem>
                                                <MenuItem value={1}>כן</MenuItem>

                                            </Select>
                                        )}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="buttons">
                    <Button variant='contained' onClick={saveSettings} disabled={isSaving}>שמירה</Button>
                    <Button variant='contained' onClick={handleReturn} disabled={isSaving}>חזור</Button>
                </div>
                {/* <DevTool control={settingsForm.control} /> */}
            </>}


        </StyledDocumentSettings>
    )
}

export default DocumentSettings