import { StyledTable } from './dataTable.styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { AccountStructure } from '../../models/accounts-structure';
import { Account } from '../../models/account';
import PrintFieldValue from '../print-field-value/PrintFieldValue';
import { CircularProgress, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DocumentStructure } from '../../models/document-structure';
import { Document } from '../../models/document';
import { Item } from '../../models/item';
import { Work } from '../../models/work';
import { Car } from '../../models/car';
import SettingsIcon from '@mui/icons-material/Settings';

interface Options {
    label: string;
    icon: JSX.Element;
    href: string
}

interface DataTableProps {
    structure: AccountStructure[] | DocumentStructure[];
    data: Account[] | Document[] | Item[] | Work[] | Car[];
    sortKey: string;
    sortMethod: string;
    isLoading: boolean;
    options: Options[];

    setSortKey: (sortKey: string) => void;
    setSortMethod: (method: string) => void;
    setStructure: () => void;

    selectRow?: boolean;
    onSelectRow?: (row: Account | Document | Item | Work | Car) => void;


}

function DataTable({ structure, data, sortKey, setSortKey, sortMethod, setSortMethod, isLoading, options, setStructure, selectRow = false, onSelectRow }: DataTableProps) {

    const location = useLocation();
    const [anchorElMap, setAnchorElMap] = useState<{ [key: number]: Element | null }>({});

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, customerId: number) => {
        setAnchorElMap({ ...anchorElMap, [customerId]: event.currentTarget });
    };

    const handleClose = (customerId: number) => {
        setAnchorElMap({ ...anchorElMap, [customerId]: null });
    };

    function thClicked(field: AccountStructure | DocumentStructure) {

        if (sortKey !== field.field_name) {
            setSortKey(field.field_name);
        }

        else {
            if (sortMethod === 'ASC') {
                setSortMethod('DESC');
            }
            else {
                setSortMethod('ASC');
            }
        }
    }

    function onSelect(row: Account | Document | Item | Work | Car) {
        onSelectRow?.(row);
    }

    const ITEM_HEIGHT = 48;

    return (
        <StyledTable>
            {/* Table header */}
            <thead>
                {/* Header row */}
                <tr>
                    {/* Iterate over structure to render table headers */}
                    {structure.map((field, index) => (
                        <th key={index}>
                            <div>
                                <span onClick={() => { thClicked(field) }}>{field.field_display_name}</span>
                                {/* Arrow icon based on sortMethod */}
                                {sortKey === field.field_name || sortKey === `documents_settings.${field.field_name}` ? <ArrowUpwardIcon className={sortMethod === 'DESC' ? 'arrow arrow-up' : ' arrow arrow-down'} /> : null}
                            </div>
                        </th>
                    ))}
                    {/* Empty cell for actions */}
                    <th>
                        {/* <button onClick={setStructure}>הגדרת שדות</button> */}
                        <Tooltip title="הגדרת שדות">
                            <IconButton onClick={setStructure} sx={{ color: 'white' }}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    </th>

                </tr>
            </thead>

            {/* Table body */}
            <tbody>
                {isLoading ? <tr><td colSpan={structure.length}><CircularProgress color="inherit" sx={{ display: "block", margin: "auto", padding: 4 }} /></td></tr> :
                    data.map((obj, objIndex) => (
                        <tr key={objIndex} className={selectRow ? "clickable-row" : ""} onClick={() => { onSelect(obj) }}>
                            {/* Iterate over structure to render table cells */}
                            {structure.map((field) => (
                                // <td key={field.field_name}>{FieldDataAsString(field, customer[field.field_name as keyof Account] || customer.custom_data?.find(customField => customField.field_name === field.field_name)?.field_value)}</td>
                                <td key={field.field_name}><PrintFieldValue field={field} accountData={obj} /></td>
                            ))}
                            {/* Actions column */}
                            <td>
                                {options && <>
                                    <IconButton
                                        aria-label="more"
                                        aria-controls={`long-menu-${obj.id}`} // Unique menu ID for each customer
                                        aria-haspopup="true"
                                        onClick={(event) => handleClick(event, obj.id!)}
                                        style={{ color: 'white' }}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id={`long-menu-${obj.id}`} // Unique menu ID for each customer
                                        anchorEl={anchorElMap[obj.id!]} // Use anchorElMap to set anchorEl
                                        open={Boolean(anchorElMap[obj.id!])}
                                        onClose={() => handleClose(obj.id!)}
                                        PaperProps={{
                                            style: {
                                                background: 'rgb(35, 48, 68)',
                                                color: 'white',
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20ch',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem
                                                component={Link}
                                                to={`${location.pathname}/${obj.id}/${option.href}`}
                                                state={{ selectedTicket: obj }}
                                                key={option.label}
                                            //selected={option === 'Pyxis'}
                                            >
                                                {option.icon}
                                                <ListItemText>{option.label}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>}

                            </td>
                        </tr>
                    ))}
            </tbody>
        </StyledTable>

    );
}

export default DataTable