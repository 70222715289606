import axios from "axios";

export const errorHandling = (error: unknown): string => {
    if (axios.isAxiosError(error)) {
        if (error.response?.data.message === "These credentials do not match our records.") {
            return "פרטי ההתחברות אינם נכונים"
        }
        if (error.response?.data.error === "The account number does not exist.") {
            return "מספר חשבון לקוח אינו קיים."
        }
        // if (error.response?.data.error === "Unique constraint violation.") {
        //     return "Unique constraint violation."
        // }
        console.log("errorHandling error", error)
        console.log("errorHandling status", error.status)
        console.log("errorHandling response", error.response);
        console.log("errorHandling response data m", error.response?.data.message);
        // Do something with this error...
        return error.message;

    } else {
        console.error(error);
    }

    return "התרחשה שגיאה"
}