import { useEffect, useState } from "react"
import { StyledTable } from "../Table.styled"
import { addModel, addSystemTableItem, editModel, editSystemTableItemByItemId, editSystemTableName, getModelsTableByManufacturerID, getSystemTableByNumber, getSystemTables } from "../../../services/tablesService";
import { Table } from "../../../models/table";
import { CircularProgress, Typography } from "@mui/material";

type selectedTableDetails = {
  systemTable: Table | null,
  items: Table[] | null
}

type manufacturerSelected = {
  manufacturer: Table | null,
  models: Table[] | null
}

function SystemTables() {

  // states

  const [systemTables, setSystemTables] = useState<Table[]>();

  const [selectedTable, setSelectedTable] = useState<selectedTableDetails>({
    systemTable: null,
    items: null
  });

  const [selectedManufacturer, setSelectedManufacturer] = useState<manufacturerSelected>({
    manufacturer: null,
    models: null
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [action, setAction] = useState<string>("browse")


  //use effects

  useEffect(() => {
    getSystemTablesData();
  }, []);


  // get data functions

  const getSystemTablesData = async () => {
    const data = await getSystemTables();
    setSystemTables(data);
    setIsLoading(false);
  }

  const getSystemTableData = async (systemTable: Table) => {

    setIsLoading(true);
    const data = await getSystemTableByNumber(systemTable.index);

    setSelectedTable({
      ...selectedTable,
      systemTable: systemTable,
      items: data || null
    });

    setIsLoading(false);
  }


  const getModelsTable = async (manufacturer: Table) => {

    if (selectedTable.systemTable?.index !== 1) { // if not manufacturer table, exit.
      return;
    }

    setIsLoading(true);
    const data = await getModelsTableByManufacturerID(manufacturer.id);

    setSelectedManufacturer({
      ...selectedManufacturer,
      manufacturer: manufacturer,
      models: data || null
    });

    setIsLoading(false);
  }

  // add data functions

  const addNewSystemTableItem = async () => {

    //if not custom table chosen, exit.
    if (!selectedTable.systemTable?.index || !selectedTable.items) {
      return;
    }

    try {
      const addedTable = await addSystemTableItem(selectedTable.systemTable.index, selectedTable.items.length + 1);
      setSelectedTable({ ...selectedTable, items: [...selectedTable.items, addedTable] });
    } catch (error) {
      console.error(error);
    }
  }

  const addNewModel = async () => {

    //if not custom table chosen, exit.
    if (!selectedManufacturer.manufacturer || !selectedManufacturer.models) {
      return;
    }

    try {
      const addedModel = await addModel(selectedManufacturer.manufacturer.id, selectedManufacturer.models.length + 1);
      setSelectedManufacturer({ ...selectedManufacturer, models: [...selectedManufacturer.models, addedModel] });
    } catch (error) {
      console.error(error);
    }
  }


  // action functions


  function returnBack() {
    if (selectedManufacturer.manufacturer) {
      setSelectedManufacturer({
        manufacturer: null,
        models: null
      });

      return;
    }

    if (selectedTable.systemTable) {
      setSelectedTable({
        systemTable: null,
        items: null
      });
    }
  }

  // save data

  const apdateState = (event: React.ChangeEvent<HTMLInputElement>, tableChanged: Table) => {

    if (selectedManufacturer.manufacturer) {
      const modelsToUpdate = selectedManufacturer?.models?.map(model => model.id === tableChanged.id ? { ...tableChanged, name: event.target.value } : model);
      setSelectedManufacturer({ ...selectedManufacturer, models: modelsToUpdate || null });

      return;
    }

    if (selectedTable.systemTable?.index) {
      const systemTablesToUpdate = selectedTable?.items?.map(item => item.id === tableChanged.id ? { ...tableChanged, name: event.target.value } : item);
      setSelectedTable({ ...selectedTable, items: systemTablesToUpdate || null });
      return;
    }

    if (systemTables) {
      const customTablesToUpdate = systemTables.map(table => table.id === tableChanged.id ? { ...tableChanged, name: event.target.value } : table);
      setSystemTables(customTablesToUpdate);
    }

  }

  const saveInput = async (table: Table) => {

    if (selectedManufacturer.manufacturer) {
      const tableUpdated = await editModel(table);
      const modelsToUpdate = selectedManufacturer?.models?.map(model => model.id === tableUpdated.id ? tableUpdated : model);
      setSelectedManufacturer({ ...selectedManufacturer, models: modelsToUpdate || null });

      return;
    }

    if (selectedTable.systemTable?.index) {
      //toDo: edit system table item
      const tableItemUpdated = await editSystemTableItemByItemId(selectedTable.systemTable.index, table);
      const systemTablesToUpdate = selectedTable?.items?.map(item => item.id === tableItemUpdated.id ? tableItemUpdated : item);
      setSelectedTable({ ...selectedTable, items: systemTablesToUpdate || null });
      return;
    }

    if (systemTables) {
      const tableUpdated = await editSystemTableName(table);
      const systemTablesToUpdate = systemTables.map(table => table.id === tableUpdated.id ? tableUpdated : table);
      setSystemTables(systemTablesToUpdate);
    }

  }
  

  return (
    <StyledTable>

      {isLoading && <CircularProgress color="inherit" sx={{ display: "block", margin: "auto", padding: 4 }} />}

      {!isLoading && systemTables && !selectedTable.systemTable?.name && (
        <>
          <Typography variant="h3" className="title">
            טבלאות מערכת
          </Typography>
          <table>
            <tbody>
              {
                systemTables.map((table, index) => (
                  <tr key={index}>
                    <td>{table.index}.</td>
                    <td className="expand">
                      {action === 'edit' ?
                        <input value={table.name || ""} onChange={(e) => { apdateState(e, table) }} onBlur={() => { saveInput(table) }} /> :
                        <a className="clickable" onClick={() => { getSystemTableData(table) }}>{table.name}</a>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttons">
            {action === 'browse' ? <button onClick={() => { setAction('edit') }}>עריכה</button> : <button onClick={() => { setAction('browse') }}>סיום עריכה</button>}
          </div>
        </>
      )}

      {!isLoading && selectedTable.systemTable?.name && !selectedManufacturer.manufacturer && (
        <>
          <Typography variant="h3" className="title">
            טבלת {selectedTable.systemTable.name}
          </Typography>
          <table>
            <tbody>
              {
                selectedTable.items?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.index}.</td>
                    <td className={selectedTable.systemTable?.index === 1 ? "expand clickable" : "expand"} >
                      {action === 'edit' ?
                        <input value={item.name || ""} onChange={(e) => { apdateState(e, item) }} onBlur={() => { saveInput(item) }} /> :
                        <a className="clickable" onClick={() => { getModelsTable(item) }}>{item.name}</a>
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttons">
            <button onClick={returnBack}>חזור</button>
            {action === 'browse' ? <button onClick={() => { setAction('edit') }}>עריכה</button> : <button onClick={() => { setAction('browse') }}>סיום עריכה</button>}
            <button onClick={addNewSystemTableItem}>הוספת שורה</button>
          </div>
        </>
      )}

      {!isLoading && selectedManufacturer.manufacturer !== null && (
        <>
          <Typography variant="h3" className="title">
            טבלת דגמי {selectedManufacturer.manufacturer.name}
          </Typography>
          <table>
            <tbody>
              {
                selectedManufacturer.models?.map((model, index) => (
                  <tr key={index}>
                    <td>{model.index}.</td>
                    <td className="expand">
                      {action === 'edit' ?
                        <input value={model.name || ""} onChange={(e) => { apdateState(e, model) }} onBlur={() => { saveInput(model) }} /> :
                        model.name
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttons">
            <button onClick={returnBack}>חזור</button>
            {action === 'browse' ? <button onClick={() => { setAction('edit') }}>עריכה</button> : <button onClick={() => { setAction('browse') }}>סיום עריכה</button>}
            <button onClick={addNewModel}>הוספת שורה</button>
          </div>
        </>
      )}
    </StyledTable>
  )
}

export default SystemTables