import styled from '@emotion/styled'

export const StyledMainMenu = styled.div`

    height: 100%;
    
    ul{
        background-color: ${({theme})=> theme.colors.section_color};
    }
    
    .short-menu{
        display: none;
    }

    @media only screen and (max-width: 900px) {
        /* CSS styles for mobile devices */
        .long-menu{
            display: none; 
        }

        .short-menu{
            display: block;
        }
    }


`