import { ReactNode, createContext, useState } from "react";
import { Work } from "../models/work";

// Define the AccountContextType interface
interface WorkContextType {
    work: Work | null;
    setWork: (work: Work) => void;
}

// Create the AccountContext with an initial value
export const WorkContext = createContext<WorkContextType>({
    work: null,
    setWork: () => {},
});

// Define the AccountProvider component
export const WorkProvider: React.FC<{ children: ReactNode; work: Work }> = ({ children, work }) => {
    const [currentWork, setCurrentWork] = useState<Work>(work);

    return (
        <WorkContext.Provider value={{ work: currentWork, setWork: setCurrentWork }}>
            {children}
        </WorkContext.Provider>
    );
};