import { Outlet } from "react-router-dom";
import { Grid, Container } from "@mui/material"
import MainMenu from "../components/main-menu/main-menu";
import Header from "../components/header/Header";
import { StyledLayout } from "./layout.styled";
import { useState } from "react";






function Layout() {

    const [isOpen, setIsOpen] = useState(true);


    return (

        <StyledLayout>
            <Header isMenuOpen={isOpen} openMenu={() => { setIsOpen(true) }} closeMenu={() => { setIsOpen(false) }} />
            <Grid container sx={{ flexGrow: 1, display: "flex", flexWrap: "nowrap" }}>
                {isOpen && (
                    <>
                        <Grid item xs={2} md={3} lg={2} style={{ flexGrow: 1 }}>
                            <MainMenu />
                        </Grid>
                        <Grid item xs={11} md={9} lg={10} style={{ flexGrow: 1 }} className="page">
                            <Container maxWidth="xl">
                                <Outlet />
                            </Container>
                        </Grid>
                    </>


                )}
                {!isOpen && (
                    <>
                        <Grid item style={{ flexGrow: 1 }} className="page">
                            <Container maxWidth="xl">
                                <Outlet />
                            </Container>
                        </Grid>
                    </>

                )}


            </Grid>
        </StyledLayout>


    );
}

export default Layout;