import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";

function Register() {

    const { register, handleSubmit } = useForm({
        mode: "onChange",
      });

    return (

        <>
            <form onSubmit={handleSubmit(d => console.log(d))}>
                <h1>register</h1>

                <label>Test</label>
                <input {...register("test")} />

                <input type="submit" />
            </form>

            
            {/* <DevTool control={control} /> */}
        </>
        
    );
}

export default Register;