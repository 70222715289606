import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountSearch } from '../../models/account';
import { DocumentSearch } from '../../models/document';
import { ItemSearch } from '../../models/item';
import { WorkSearch } from '../../models/work';
import { CarSearch } from '../../models/car';


export interface InitialState {
  customersSearch: AccountSearch | undefined;
  itemsSearch: ItemSearch | undefined;
  worksSearch: WorkSearch | undefined;
  carsSearch: CarSearch | undefined;
  incomesSearch: AccountSearch | undefined;
  outcomesSearch: AccountSearch | undefined;
  banksSearch: AccountSearch | undefined;
  cashBoxesSearch: AccountSearch | undefined;
  systemSearch: AccountSearch | undefined;
  documentsSearch: DocumentSearch | undefined;
}

const initialState: InitialState = {
  customersSearch: undefined,
  itemsSearch: undefined,
  worksSearch: undefined,
  carsSearch: undefined,
  incomesSearch: undefined,
  outcomesSearch: undefined,
  banksSearch: undefined,
  cashBoxesSearch: undefined,
  systemSearch: undefined,
  documentsSearch: undefined,
}

export const browse = createSlice({
  name: 'browse',
  initialState,
  reducers: {
    setCustomersSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      console.log("action.payload",action.payload);
      state.customersSearch = action.payload
    },
    setItemsSearch: (state, action: PayloadAction<ItemSearch | undefined>) => {
      state.itemsSearch = action.payload
    },
    setWorksSearch: (state, action: PayloadAction<WorkSearch | undefined>) => {
      state.worksSearch = action.payload
    },
    setCarsSearch: (state, action: PayloadAction<CarSearch | undefined>) => {
      state.carsSearch = action.payload
    },
    setIncomesSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      state.incomesSearch = action.payload
    },
    setOutcomesSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      state.outcomesSearch = action.payload
    },
    setBanksSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      state.banksSearch = action.payload
    },
    setCashBoxesSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      state.cashBoxesSearch = action.payload
    },
    setSystemSearch: (state, action: PayloadAction<AccountSearch | undefined>) => {
      state.systemSearch = action.payload
    },
    setDocumentsSearch: (state, action: PayloadAction<DocumentSearch | undefined>) => {
      state.documentsSearch = action.payload
    },
  },
})


// Action creators are generated for each case reducer function
export const {

  setCustomersSearch,
  setItemsSearch,
  setWorksSearch,
  setCarsSearch,
  setIncomesSearch,
  setOutcomesSearch,
  setBanksSearch,
  setCashBoxesSearch,
  setSystemSearch,
  setDocumentsSearch,

} = browse.actions

export default browse.reducer