import { Tab, TabProps, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { WorkProvider } from "../../../utils/WorkContext";
import { Work as WorkModel, WorkSchema } from "../../../models/work";
import { getWorkById } from "../../../services/worksService";

interface LinkTabProps extends TabProps {
  path: string;
  selected?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component={RouterLink}
      to={props.path}
      label={props.label}
      aria-current={props.selected ? 'page' : undefined}
      {...props}
    />
  );
}

// Define an array of tab hrefs
const tabsData = [
  {
    label: "פרטי פריט",
    path: "",
  },
  {
    label: "תנועות",
    path: "journal-entries",
  },
];


function Work() {

  const location = useLocation();
  const navigate = useNavigate();

  const currentIndex = tabsData.findIndex(tab => tab.path !== "" && location.pathname.includes(tab.path));
  const value = currentIndex !== -1 ? currentIndex : 0;

 
  const [work, setWork] = useState<WorkModel | undefined>();
  const { id } = useParams();


  useEffect(() => {
    if(location.state?.selectedTicket){
      setWork(location.state.selectedTicket);
      navigate(location.pathname, { replace: true, state: null });
    }

    else{
      getWork();
    }
  }, []);

  const getWork = async () => {
    try {
      if (id) {
        const work = await getWorkById(id);
        const workParse = WorkSchema.parse(work);
        setWork(workParse);
      }
    } catch (error) {
      console.error(error);
    }
  }

  {
    return work && 
    <>
      <div>
        <Typography variant="h5" component="span">
          כרטיס עבודה
        </Typography>

        {/* <span>מס כרטיס: {item?.card_number}</span> */}
        <span>מס כרטיס: {work?.id}</span>
      </div>

      <Tabs
        dir='ltr'
        value={value}
        role="navigation"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        style={{ direction: "rtl", flexDirection: "row-reverse", backgroundColor: "rgb(35, 48, 68)", padding: 5, marginTop: 20 }}

      >
        {tabsData.map((tab, index) => (
          <LinkTab key={index} label={tab.label} path={tab.path} />
        ))}

      </Tabs>
      <WorkProvider work={work}>
        <Outlet />
      </WorkProvider>
    </>
  }
}

export default Work