import { useLocation, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { StyledAddTicket } from "./AddTicket.styled";
import { Button, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import AddCustomer from "./customers/add-customer/AddCustomer";
import { useEffect, useState } from "react";
import { addAccount, getNextAccountNumber } from "../../services/accountsService";
import { addItem, getNextItemNumber } from "../../services/itemsService";
import { addWork, getNextWorkNumber } from "../../services/worksService";
import { addCar, getNextCarNumber } from "../../services/carsService";
import { deleteEmptyFieldsFromObject } from "../../helpers/helpers";
import { Account, AccountForm, AccountFormSchema } from "../../models/account";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Item, ItemSchema } from "../../models/item";
import { errorHandling } from "../../services/errorHandling";
import { toast } from 'react-toastify';
import AddItem from "./items/add-item/AddItem";
import AddCar from "./cars/add-car/AddCar";
import { Car, CarSchema } from "../../models/car";
import AddWork from "./works/add-work/AddWork";
import { Work, WorkSchema } from "../../models/work";
import AddIncome from "./incomes/add-income/AddIncome";
import AddOutcome from "./outcomes/add-outcome/AddOutcome";
import AddSystem from "./systems/add-system/AddSystem";
import AddBank from "./banks/add-bank/AddBank";
import AddCashBox from "./cash-boxes/add-cashBox/AddCashBox";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { setIncomesTable, setOutcomesTable } from "../../redux/features/tables";
import { SelectTable } from "../../models/table";
import { setCustomData } from "../../services/customDataService";
import SettingsIcon from '@mui/icons-material/Settings';

const ticketTypeList = [
    {
        url: "customers",
        name: "לקוחות/ספקים",
        type: "לקוח-ספק"
    },
    {
        url: "items",
        name: "פריטים/מלאי",
        type: "פריט"
    },
    {
        url: "works",
        name: "עבודות",
        type: "עבודה"
    },
    {
        url: "cars",
        name: "רכבים",
        type: "רכב"
    },
    {
        url: "incomes",
        name: "הכנסות",
        type: "הכנסה"
    },
    {
        url: "outcomes",
        name: "הוצאות",
        type: "הוצאה"
    },
    {
        url: "banks",
        name: "בנקים",
        type: "בנק"
    },
    {
        url: "cash-boxes",
        name: "קופה",
        type: "קופה"
    },
    {
        url: "system",
        name: "מערכת",
        type: "מערכת"
    },
];


function AddTicket() {

    const incomesTable = useSelector((state: RootState) => state.tables.incomesTable);
    const outcomesTable = useSelector((state: RootState) => state.tables.outcomesTable);

    const location = useLocation();
    const navigate = useNavigate();
    const ticketType = location.pathname.split("/")[1];

    const [structureIsLoading, setStructureIsLoading] = useState<boolean>(false);
    const [ticketNumber, setTicketNumber] = useState<number>();


    const customerForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            vat_include: true,
            ...location.state?.customer
        }
    });

    const itemForm = useForm<Item>({
        resolver: zodResolver(ItemSchema),
        defaultValues: {
            ...location.state?.item
        }
    });

    const carForm = useForm<Car>({
        resolver: zodResolver(CarSchema),
        defaultValues: {
            ...location.state?.car
        }
    });

    const workForm = useForm<Work>({
        resolver: zodResolver(WorkSchema),
        defaultValues: {
            ...location.state?.work
        }
    });

    const incomeForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            vat_include: true,
            ...location.state?.income
        }
    });

    const outcomeForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            vat_include: true,
            ...location.state?.outcome
        }
    });

    const bankForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            ...location.state?.bank
        }
    });

    const cashBoxForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            ...location.state?.cashBox
        }
    });

    const systemForm = useForm<AccountForm>({
        resolver: zodResolver(AccountFormSchema),
        defaultValues: {
            ...location.state?.system
        }
    });


    useEffect(() => {

        if (ticketType === "items") {
            getNextItemTicketNumber();
        }

        else if (ticketType === "works") {
            getNextWorkTicketNumber();
        }

        else if (ticketType === "cars") {
            getNextCarTicketNumber();
        }

        else {
            getNextTicketNumber();
        }
    }, [ticketType]);


    const getNextTicketNumber = async () => {
        try {
            setTicketNumber(undefined);
            const number = await getNextAccountNumber(ticketTypeList.find(type => type.url === ticketType)?.type || "");
            setTicketNumber(number);
        } catch (error) {
            console.error(error);
        }
    }

    const getNextItemTicketNumber = async () => {
        try {
            setTicketNumber(undefined);
            const number = await getNextItemNumber();
            setTicketNumber(number);
        } catch (error) {
            console.error(error);
        }
    }

    const getNextWorkTicketNumber = async () => {
        try {
            setTicketNumber(undefined);
            const number = await getNextWorkNumber();
            setTicketNumber(number);
        } catch (error) {
            console.error(error);
        }
    }

    const getNextCarTicketNumber = async () => {
        try {
            setTicketNumber(undefined);
            const number = await getNextCarNumber();
            setTicketNumber(number);
        } catch (error) {
            console.error(error);
        }
    }

    function handleSelectChange(e: SelectChangeEvent<string>) {
        const value = e.target.value;

        navigate(`/${value}/add`);

    }

    function returnFunc() {
        if (location.state?.from === "/documents/add") {
            navigate(location.state.from, {
                replace: true,
                state: {
                    ...location.state,
                    // from: `/documents/add`,
                    // document_settings_id: location.state.document_settings_id,

                    // works: location.state.works,

                    // items: location.state.items,

                    // customer: location.state.customer
                }
            });
        }

        else {
            window.history.back();
        }

    }


    const addNewTicket = async () => {

        if (!ticketNumber) {
            return;
        }


        if (ticketType === "customers") {
            try {

                customerForm.setValue("card_number", ticketNumber);
                customerForm.setValue("account_type", "לקוח-ספק");
                const customDataArray = setCustomData(customerForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    customerForm.setValue("custom_data", customDataArray);
                }

                customerForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "items") {

            try {

                const customDataArray = setCustomData(itemForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    itemForm.setValue("custom_data", customDataArray);
                }

                itemForm.handleSubmit(onSubmitItem)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "works") {

            try {

                const customDataArray = setCustomData(workForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    workForm.setValue("custom_data", customDataArray);
                }

                workForm.handleSubmit(onSubmitWork)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "cars") {

            try {

                const customDataArray = setCustomData(carForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    carForm.setValue("custom_data", customDataArray);
                }

                carForm.handleSubmit(onSubmitCar)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "incomes") {
            try {

                incomeForm.setValue("card_number", ticketNumber);
                incomeForm.setValue("account_type", "הכנסה");

                const customDataArray = setCustomData(incomeForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    incomeForm.setValue("custom_data", customDataArray);
                }

                incomeForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "outcomes") {
            try {

                outcomeForm.setValue("card_number", ticketNumber);
                outcomeForm.setValue("account_type", "הוצאה");

                const customDataArray = setCustomData(outcomeForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    outcomeForm.setValue("custom_data", customDataArray);
                }

                outcomeForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "banks") {
            try {

                bankForm.setValue("card_number", ticketNumber);
                bankForm.setValue("account_type", "בנק");

                const customDataArray = setCustomData(bankForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    bankForm.setValue("custom_data", customDataArray);
                }

                bankForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "cash-boxes") {
            try {

                cashBoxForm.setValue("card_number", ticketNumber);
                cashBoxForm.setValue("account_type", "קופה");

                const customDataArray = setCustomData(cashBoxForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    cashBoxForm.setValue("custom_data", customDataArray);
                }

                cashBoxForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }

        if (ticketType === "system") {
            try {

                systemForm.setValue("card_number", ticketNumber);
                systemForm.setValue("account_type", "מערכת");

                const customDataArray = setCustomData(systemForm.getValues("custom_data"));

                if (customDataArray.length > 0) {
                    systemForm.setValue("custom_data", customDataArray);
                }


                systemForm.handleSubmit(onSubmitAccount)();

            } catch (error) {
                console.error("Form submission failed:", error);
            }
        }


    }

    const onSubmitAccount: SubmitHandler<AccountForm> = (data) => {

        // const dataToSend = deleteEmptyFieldsFromObject(data);
        const dataToSend = data;

        const addPromise = async () => {
            const accountAdded = await addAccount(dataToSend);
            addIncomeOrOutcomeToStore(accountAdded);

            if (location.state?.from === "/documents/add") {
                navigate(location.state.from, {
                    replace: true,
                    state: {
                        // ...location.state,
                        from: `/documents/add`,
                        document_settings_id: location.state.document_settings_id,

                        customer: data,
                        car: location.state.car,

                        works: location.state.works,
                        items: location.state.items,

                    }
                });

                return;
            }

            setTicketNumber(prev => (prev || 0) + 1);
            customerForm.reset();
        }


        toast.promise(
            addPromise,
            {
                pending: 'מוסיף כרטיס...',
                success: 'הכרטיס נוסף בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return errorHandling(data)
                    }
                }
            }
        )
    };

    const onSubmitItem: SubmitHandler<Item> = (data) => {

        const dataToSend = deleteEmptyFieldsFromObject(data);

        const addPromise = async () => {
            await addItem(dataToSend);

            if (location.state?.from === "/documents/add") {
                navigate(location.state.from, {
                    replace: true,
                    state: {
                        // ...location.state,
                        from: `/documents/add`,
                        document_settings_id: location.state.document_settings_id,

                        customer: location.state.customer,
                        car: location.state.car,

                        works: location.state.works,
                        items: location.state.items,

                        chosenItem: data,
                        itemRowIndex: location.state.itemRowIndex
                    }
                });

                return;
            }

            setTicketNumber(prev => (prev || 0) + 1);
            itemForm.reset();
        }


        toast.promise(
            addPromise,
            {
                pending: 'מוסיף כרטיס...',
                success: 'הכרטיס נוסף בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return errorHandling(data)
                    }
                }
            }
        )
    };

    const onSubmitCar: SubmitHandler<Car> = (data) => {

        const dataToSend = deleteEmptyFieldsFromObject(data);

        const addPromise = async () => {
            await addCar(dataToSend);

            if (location.state?.from === "/documents/add") {
                navigate(location.state.from, {
                    replace: true,
                    state: {
                        // ...location.state,
                        from: `/documents/add`,
                        document_settings_id: location.state.document_settings_id,

                        customer: location.state.customer,
                        car: data,

                        works: location.state.works,
                        items: location.state.items,

                    }
                });

                return;
            }

            setTicketNumber(prev => (prev || 0) + 1);
            carForm.reset();
        }


        toast.promise(
            addPromise,
            {
                pending: 'מוסיף כרטיס...',
                success: 'הכרטיס נוסף בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return errorHandling(data)
                    }
                }
            }
        )
    };

    const onSubmitWork: SubmitHandler<Work> = (data) => {

        const dataToSend = deleteEmptyFieldsFromObject(data);

        const addPromise = async () => {
            await addWork(dataToSend);

            if (location.state?.from === "/documents/add") {
                navigate(location.state.from, {
                    replace: true,
                    state: {
                        // ...location.state,
                        from: `/documents/add`,
                        document_settings_id: location.state.document_settings_id,

                        customer: location.state.customer,
                        car: location.state.car,

                        works: location.state.works,
                        items: location.state.items,

                        chosenWork: data,
                        workRowIndex: location.state.workRowIndex
                    }
                });

                return;
            }

            setTicketNumber(prev => (prev || 0) + 1);
            workForm.reset();

        }


        toast.promise(
            addPromise,
            {
                pending: 'מוסיף כרטיס...',
                success: 'הכרטיס נוסף בהצלחה',
                error: {
                    render({ data }) {
                        // When the promise reject, data will contains the error
                        return errorHandling(data)
                    }
                }
            }
        )
    };


    function addIncomeOrOutcomeToStore(account: Account): void {

        if (account.account_type === "הכנסה") {
            if (incomesTable) {
                const incomeTableToAdd: SelectTable = {
                    "index": account.card_number,
                    "name": account.account_name
                }
                setIncomesTable([...incomesTable, incomeTableToAdd]);
            }
        }
        if (account.account_type === "הוצאה") {
            if (outcomesTable) {
                const outcomeTableToAdd: SelectTable = {
                    "index": account.card_number,
                    "name": account.account_name
                }
                setOutcomesTable([...outcomesTable, outcomeTableToAdd]);
            }
        }
    }

    function setStructure() {
        navigate('/structure', {
            replace: true, state: {
                from: location.pathname,
                fromType: 'form',
                structureName: ticketTypeList.find(type => type.url === ticketType)?.type || "",
                structureType: 'account',
                accountType: ticketType,

                customer: customerForm.getValues(),
                item: itemForm.getValues(),
                car: carForm.getValues(),
                work: workForm.getValues(),
                income: incomeForm.getValues(),
                outcome: outcomeForm.getValues(),
                bank: bankForm.getValues(),
                cashBox: cashBoxForm.getValues(),
                system: systemForm.getValues(),

            }
        });
    }


    return (
        <StyledAddTicket>
            <Typography variant="h5" component="span">
                הוספת כרטסת
            </Typography>
            {
                structureIsLoading || !ticketNumber ? <Skeleton variant="rectangular" height={500} animation="wave" sx={{ bgcolor: 'rgb(35, 48, 68)' }} /> :
                    <div>
                        <div className="choose-ticket-div">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="ticket-add-select-label">כרטסת</InputLabel>
                                    <Select
                                        labelId="ticket-add-select-label"
                                        id="ticket-add-select"
                                        value={ticketType}
                                        label="ticket"
                                        onChange={handleSelectChange}
                                    >
                                        {ticketTypeList.map((type, index) => (
                                            <MenuItem key={index} value={type.url}>{type.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <span>מספר כרטיס: {ticketNumber}</span>
                            <Tooltip title="הגדרת שדות">
                                <IconButton onClick={setStructure} sx={{ color: 'white' }}>
                                    <SettingsIcon />
                                </IconButton>
                            </Tooltip>

                        </div>
                        <div className="form">
                            {ticketType === "customers" &&
                                // <AddCustomer formData={formData} setFormData={setFormData} setStructureIsLoading={setStructureIsLoading} />
                                <AddCustomer setStructureIsLoading={setStructureIsLoading} useForm={customerForm} />
                            }
                            {ticketType === "items" &&
                                <AddItem setStructureIsLoading={setStructureIsLoading} useForm={itemForm} />
                            }
                            {ticketType === "works" &&
                                <AddWork setStructureIsLoading={setStructureIsLoading} useForm={workForm} />
                            }
                            {ticketType === "cars" &&
                                <AddCar setStructureIsLoading={setStructureIsLoading} useForm={carForm} />
                            }
                            {ticketType === "incomes" &&
                                <AddIncome setStructureIsLoading={setStructureIsLoading} useForm={incomeForm} />
                            }
                            {ticketType === "outcomes" &&
                                <AddOutcome setStructureIsLoading={setStructureIsLoading} useForm={outcomeForm} />
                            }
                            {ticketType === "banks" &&
                                <AddBank setStructureIsLoading={setStructureIsLoading} useForm={bankForm} />
                            }
                            {ticketType === "cash-boxes" &&
                                <AddCashBox setStructureIsLoading={setStructureIsLoading} useForm={cashBoxForm} />
                            }
                            {ticketType === "system" &&
                                <AddSystem setStructureIsLoading={setStructureIsLoading} useForm={systemForm} />
                            }
                        </div>
                    </div>
            }
            <div className="buttons">
                <Button variant="contained" onClick={addNewTicket}>שמירה</Button>
                <Button variant="outlined" onClick={returnFunc}>חזור</Button>
            </div>


        </StyledAddTicket>

    )
}

export default AddTicket