import { z } from "zod";
import { customErrorMap, myPreProcess } from "../services/zodService";

z.setErrorMap(customErrorMap);

export const DocumentStructureShowSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    // document_settings_id: z.number(),
    document_settings_id: z.number(),
    document_structure_id: z.number(),
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

});

export const DocumentStructureShowFormSchema = z.object({

    //from server (optional)
    id: z.number().optional(),
    // document_settings_id: z.number(),
    document_settings_id: z.number(),
    document_structure_id: z.number(),
    show_form: z.preprocess((val) => myPreProcess(val, !!val), z.boolean()),

});

export type DocumentStructureShow = z.infer<typeof DocumentStructureShowSchema>
